import "./AuthButton.css";

import {useRef, useState} from "react";

import {IconUser} from "@consta/icons/IconUser";
import {Button} from "@consta/uikit/Button";
import {ContextMenu} from "@consta/uikit/ContextMenu";

function AuthButton(props: IAuthButtonProps) {
    const restoreTitle = "Восстановление аккаунта";
    const enterTitle = "Вход";
    const leaveTitle = "Выход";
    const registerTitle = "Регистрация";
    const tariffsTitle = "Тарифы";
    const paymentsTitle = "Платежи";
    var items: string[]
    if (props.isAuth) {
        items = [leaveTitle, tariffsTitle]
        if (props.onPayments) {
            items.push(paymentsTitle)
        }
    } else {
        items = [enterTitle, registerTitle, restoreTitle]
    }

    const [popoverIsOpen, setPopoverIsOpen] = useState<boolean>(false);
    const ref = useRef(null);
    
    const onItemClick = (item: string) => {
        setPopoverIsOpen(false)
        if (props.onEnter && item === enterTitle) {
            props.onEnter()
        }
        if (props.onLeave && item === leaveTitle) {
            props.onLeave()
        }
        if (props.onTariffs && item === tariffsTitle) {
            props.onTariffs()
        }
        if (props.onRegister && item === registerTitle) {
            props.onRegister()
        }
        if (props.onRestore && item === restoreTitle) {
            props.onRestore()
        }
        if (props.onPayments && item === paymentsTitle) {
            props.onPayments()
        }
    };

    return (
        <div className="AuthButton">
            {props.isAuth && (
                <Button
                    ref={ref}
                    view="ghost"
                    iconRight={IconUser}
                    onlyIcon
                    onClick={() => setPopoverIsOpen(true)}
                />
            )}
            {!props.isAuth && (
                <Button
                    ref={ref}
                    label="Личный кабинет"
                    onClick={() => setPopoverIsOpen(true)}
                />
            )}
            <ContextMenu
                anchorRef={ref}
                isOpen={popoverIsOpen}
                items={items}
                getItemLabel={(item) => item}
                onClickOutside={() => setPopoverIsOpen(false)}
                onEsc={() => setPopoverIsOpen(false)}
                onItemClick={onItemClick}
            />
        </div>
    );
}

type IAuthButtonProps = {
    isAuth: boolean;
    onEnter?: VoidFunction;
    onLeave?: VoidFunction;
    onPayments?: VoidFunction;
    onRegister?: VoidFunction;
    onRestore?: VoidFunction;
    onTariffs?: VoidFunction;
};

export default AuthButton;
