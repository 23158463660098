import "./StepButtons.css";

import {IconForward} from "@consta/icons/IconForward";
import {IconBackward} from "@consta/icons/IconBackward";

import {Button} from "@consta/uikit/Button";

function StepButtons(props: IStepButtonsProps) {
    const isLastPage = props.stepIndex === props.stepCount - 1;
    return (
        <div className="StepButtons">
            {props.stepIndex > 0 && (
                <Button
                    label="Назад"
                    iconLeft={IconBackward}
                    onClick={props.handlePrev}
                    disabled={!props.isPrevActive}
                    view="ghost"
                />
            )}
            <div></div> {/* обеспечивает привязку кнопок к краям формы  */}
            <Button
                label={isLastPage ? props.doneText : "Дальше"}
                iconRight={isLastPage ? undefined : IconForward}
                onClick={props.handleNext}
                disabled={!props.isNextActive}
            />
        </div>
    );
}

type IStepButtonsProps = {
    stepIndex: number;
    stepCount: number;
    handleNext: VoidFunction;
    handlePrev: VoidFunction;
    isNextActive: boolean;
    isPrevActive: boolean;
    doneText: string;
};

export default StepButtons;
