import "./EmptySphere.css"

import {Text} from "@consta/uikit/Text";

function EmptySphere() {
    return (
        <Text className="EmptySphere" view="secondary" size="m">
            Здесь пока ничего нет. Нажмите плюсик в правом верхнем углу, чтобы
            начать.
        </Text>
    );
}
export default EmptySphere;
