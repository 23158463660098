import ISphereService from "./ISphereService";
import IAuthService from "../auth/IAuthService";
import {CommonResponse} from "../utils/network/common";
import ISphereApiProvider from "../api/sphere/provider/ISphereApiProvider";
import {
    SphereAddResponse,
    SphereExploreResponse,
    SphereListResponse,
} from "../api/sphere/entities/entities";


class SphereService implements ISphereService {

    // ISphereService

    addSphere(
        title: string,
        content: string,
        onResult: (response: SphereAddResponse) => void
    ) {
        this._addSphere(title, content, onResult)
    }

    deleteSphere(
        sphereId: number,
        onResult: (response: CommonResponse) => void
    ) {
        this._deleteSphere(sphereId, onResult);
    }

    getSphere(
        sphereId: number,
        onResult: (sphereListResponse: SphereExploreResponse) => void
    ) {
        this._getSphere(sphereId, onResult);
    }

    getSphereList(onResult: (sphereListResponse: SphereListResponse) => void) {
        this._getSphereList(onResult);
    }

    renameSphere(
        sphereId: number,
        title: string,
        onResult: (response: CommonResponse) => void
    ) {
        this._renameSphere(sphereId, title, onResult);
    }

    updateSphere(
        sphereId: number,
        content: string,
        onResult: (response: CommonResponse) => void
    ) {
        this._updateSphere(sphereId, content, onResult);
    }

    // Setup

    private apiProvider: ISphereApiProvider;

    constructor(apiProvider: ISphereApiProvider) {
        this.apiProvider = apiProvider;
    }

    // Internal

    private _addSphere = (
        title: string,
        content: string,
        onResult: (response: SphereAddResponse) => void
    ) => {
        this.apiProvider.addSphere(title, content, onResult);
    };

    private _deleteSphere = (
        sphereId: number,
        onResult: (response: CommonResponse) => void
    ) => {
        this.apiProvider.deleteSphere(sphereId, onResult);
    };

    private _getSphere = (
        sphereId: number,
        onResult: (sphereListResponse: SphereExploreResponse) => void
    ) => {
        this.apiProvider.getSphere(sphereId, onResult);
    };

    private _getSphereList = (
        onResult: (sphereListResponse: SphereListResponse) => void
    ) => {
        this.apiProvider.getSphereList(onResult);
    };

    private _renameSphere = (
        sphereId: number,
        title: string,
        onResult: (response: CommonResponse) => void
    ) => {
        this.apiProvider.renameSphere(sphereId, title, onResult);
    };

    private _updateSphere = (
        sphereId: number,
        content: string,
        onResult: (response: CommonResponse) => void
    ) => {
        this.apiProvider.updateSphere(sphereId, content, onResult);
    };
}
export default SphereService;
