import {CommonError} from "../../../utils/errors/error";
import {connectionError} from "../../../utils/network/errors";

export enum TariffActionType {
    Auth = "auth",
    Prolong = "prolong",
    Switch = "switch"
}

export enum TariffActionOption {
    Migration = "migration",
    Payment = "payment",
}

export type TariffAction = {
    type: TariffActionType;
    options: TariffActionOption[];
};

export type TariffItem = {
    tariff_id: number;
    name: string;
    description: string;
    min_month_price: number;
    is_current: boolean;
    action: TariffAction;
};

export type TariffListResponse = {
    success: boolean;
    error?: CommonError;
    list: TariffItem[];
};

export const tariffListResponseError: TariffListResponse = {
    success: false,
    error: connectionError,
    list: [],
};
