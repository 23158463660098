import "./Sphere.css";

import {useEffect, useRef, useState} from "react";

import {Button} from "@consta/uikit/Button";
import {IconAdd} from "@consta/icons/IconAdd";
import {IconSave} from "@consta/icons/IconSave";
import {IconShare} from "@consta/icons/IconShare";
import {Tabs} from "@consta/uikit/Tabs";
import {Text} from "@consta/uikit/Text";
import {Tooltip} from "@consta/uikit/Tooltip";

import Page from "../../controls/page/Page";
import Renderer from "../../controls/renderer/Renderer";

import {NewNode, Node} from "../../../entities/Node";
import {BrokenLink} from "../../../entities/BrokenLink";
import EditNodeForm from "../../forms/editNodeForm/EditNodeForm";
import IStorage from "../../../services/storage/IStorage";
import ICore from "../../../services/core/ICore";
import {NodeResult} from "../../../services/api/sphere/sphere";
import ProjectionRenderer from "../../controls/renderer/ProjectionRenderer";
import IRouter from "../../router/IRouter";
import {Loader} from "@consta/uikit/Loader";
import ISphereService from "../../../services/sphere/ISphereService";
import PageHeader from "../../controls/pageHeader/PageHeader";
import AuthButton from "../../controls/authButton/AuthButton";
import IAuthService from "../../../services/auth/IAuthService";
import BackButton from "../../controls/backButton/BackButton";
import { Route } from "../../router/routes";

enum SphereView {
    // NodesList = 1,
    Projection = 1,
    Sphere,
}

type TabItem = {
    label: string;
    sphereView: SphereView;
};

const tabItems: TabItem[] = [
    {
        label: "[ Сфера ]",
        sphereView: SphereView.Sphere,
    },
    // {
    //     label: "[ Таблица ]",
    //     sphereView: SphereView.NodesList,
    // },
    {
        label: "[ Проекция ]",
        sphereView: SphereView.Projection,
    },
];

const renderTab = (
    sphereView: SphereView,
    nodes: Node[],
    nodeResults: NodeResult[],
    brokenLinks: BrokenLink[],
    onDeleteNode: (nodeId: string) => void,
    onEditNode: (nodeId: string) => void,
    openNewNodeForm: (nodeId: string | undefined) => void,
    onUpdateNode: (nodeId: string) => void,
    onMoveNodeAfter: (capturedNodeId: string, afterNodeId: string) => void,
    onMoveNodeBefore: (capturedNodeId: string, beforeNodeId: string) => void
) => {
    switch (sphereView) {
        // case SphereView.NodesList:
        //     return (
        //         <NodeTable
        //             nodes={nodes}
        //             onDeleteNode={onDeleteNode}
        //             onEditNode={onEditNode}
        //         />
        //     );
        case SphereView.Projection:
            return (
                <ProjectionRenderer
                    nodes={nodes}
                    onEditNode={onEditNode}
                    onAddNode={openNewNodeForm}
                    onUpdateNode={onUpdateNode}
                ></ProjectionRenderer>
            );
        case SphereView.Sphere:
            return (
                <Renderer
                    nodes={nodes}
                    brokenLinks={brokenLinks}
                    onEditNode={onEditNode}
                    onAddNode={openNewNodeForm}
                    onMoveNodeAfter={onMoveNodeAfter}
                    onMoveNodeBefore={onMoveNodeBefore}
                ></Renderer>
            );
    }
};

function SphereScreen(props: ISphereProps) {
    const addBtnRef = useRef<HTMLButtonElement>(null);
    const [isAddBtnTooltipVisible, setIsAddBtnTooltipVisible] = useState(false);

    const saveBtnRef = useRef<HTMLButtonElement>(null);
    const [isSaveBtnTooltipVisible, setIsSaveBtnTooltipVisible] =
        useState(false);

    const saveProjBtnRef = useRef<HTMLButtonElement>(null);
    const [isSaveProjBtnTooltipVisible, setIsSaveProjBtnTooltipVisible] =
        useState(false);

    const [tab, setTab] = useState<TabItem>(tabItems[0]);

    const [nodes, setNodes] = useState(props.storage.nodes);
    const [nodeResults, setNodeResults] = useState(props.storage.nodeResults);
    // TODO: не храню на диске сломанные ссылки, прояснить, зачем вообще надо было хранить
    const [brokenLinks, setBrokenLinks] = useState<BrokenLink[]>([]);
    const addNode = (newNode: NewNode) => {
        if (editingNodeId) {
            props.storage.editNode(editingNodeId, newNode);
        } else {
            props.storage.addNode(newNode);
        }
        setNodes([...props.storage.nodes]);
        reloadWeights();
    };

    const deleteNode = (nodeId: string) => {
        props.storage.deleteNode(nodeId);
        setNodes([...props.storage.nodes]);
        reloadWeights();
    };

    const editNode = (nodeId: string) => {
        setEditingNodeId(nodeId);
        openNewNodeForm();
        reloadWeights();
    };

    const reloadWeights = () => {
        props.core.calculateSphere(
            props.storage.nodes,
            (nodeResults, brokenLinks) => {
                // TODO: выяснить, зачем это здесь
                //setNodes([...props.storage.nodes]);

                setBrokenLinks(brokenLinks)
                
                // сохраняем рассчитанные влияния и лейаут на диск
                props.storage.applyNodeResults(nodeResults);
                // выполняем отрисовку влияний и лейаута
                setNodeResults(props.storage.nodeResults);
                
                // сохраняем на сервере изменения в исходных узлах
                // TODO: это сайд-эффект, необходимо устранить
                const content = JSON.stringify(props.storage.nodes);
                props.sphereService.updateSphere(
                    props.storage.sphereId,
                    content,
                    (response) => {
                        //console.log(response);
                    }
                );
            }
        );
    };

    const save = () => {
        props.storage.save();
    };

    const saveAsProjection = () => {
        props.storage.saveAsProjection();
    };

    const [isNewNodeFormOpen, setIsNewNodeFormOpen] = useState<boolean>(false);
    const closeNewNodeForm = () => {
        setIsNewNodeFormOpen(false);
        setEditingNodeId(undefined);
    };
    const openNewNodeForm = (nodeId?: string) => {
        setLinkNodeId(nodeId);
        setIsNewNodeFormOpen(true);
    };

    const hideWithChildren = (initialNodeId: string) => {
        let initialNode = props.storage.getNode(initialNodeId); // по ссылке значение
        if (initialNode) {
            initialNode.hidden = true;

            let childrenIds: string[] = [initialNodeId];
            do {
                let currentId = childrenIds[0];
                for (let node of props.storage.nodes) {
                    const isChildOfInitialNode = node.links.find(
                        (link) => link.targetNodeId === currentId
                    );
                    const hasVisibleParent = node.links.find(
                        (link) =>
                            !props.storage.getNode(link.targetNodeId)?.hidden
                    );
                    if (isChildOfInitialNode && !hasVisibleParent) {
                        childrenIds.push(node.id);
                        node.hidden = true;
                    }
                    childrenIds = childrenIds.filter(
                        (item) => item !== currentId
                    );
                }
            } while (childrenIds.length > 0);
        }
    };

    const showWithParents = (initialNodeId: string) => {
        let initialNode = props.storage.getNode(initialNodeId); // по ссылке значение
        if (initialNode) {
            initialNode.hidden = false;

            let parentIds: string[] = [
                ...initialNode.links.map((link) => link.targetNodeId),
            ];
            while (parentIds.length > 0) {
                let currentId = parentIds[0];
                const parentNode = props.storage.getNode(currentId);
                if (parentNode) {
                    parentNode.hidden = false;
                    parentIds = parentIds.filter((id) => id !== currentId);
                    parentIds = [
                        ...parentIds,
                        ...parentNode.links.map((link) => link.targetNodeId),
                    ];
                }
            }
        }
    };

    const onUpdateNode = (initialNodeId: string) => {
        const hidden = props.storage.getNode(initialNodeId)?.hidden;
        if (hidden) {
            showWithParents(initialNodeId);
        } else {
            hideWithChildren(initialNodeId);
        }
        setNodes([...props.storage.nodes]);
    };

    const onMoveNodeAfter = (capturedNodeId: string, afterNodeId: string) => {
        props.storage.moveNodeAfter(capturedNodeId, afterNodeId);
        setNodes([...props.storage.nodes]);
        reloadWeights();
    }

    const onMoveNodeBefore = (capturedNodeId: string, beforeNodeId: string) => {
        props.storage.moveNodeBefore(capturedNodeId, beforeNodeId);
        setNodes([...props.storage.nodes]);
        reloadWeights();
    }

    const [editingNodeId, setEditingNodeId] = useState<string | undefined>();
    const [linkNodeId, setLinkNodeId] = useState<string | undefined>();

    useEffect(() => {
        reloadWeights();
    }, []);

    const defaultNodeType = (() => {
        const links = linkNodeId ? [{targetNodeId: linkNodeId}] : [];
        const types = props.core.getAvailableNodeTypes(links);
        return types.length > 0 ? types[0].type : undefined;
    })();

    const makeLeave = () => {
        props.authService.leave();
        props.router.reload();
    };
    const showTariffs = () => {
        props.router.goToRoute(Route.ContentTariffs);
    };

    return (
        <Page>
            <div className="Sphere-container">
                <PageHeader>
                    <BackButton router={props.router}/>
                    <AuthButton
                        isAuth={props.authService.isSignedIn()}
                        onLeave={makeLeave}
                        onTariffs={showTariffs}
                    />
                </PageHeader>
                <div className="Sphere-header">
                    <Tabs
                        value={tab}
                        onChange={setTab}
                        items={tabItems}
                        size="m"
                        getItemAs={() => "div"}
                    />
                    {tab.sphereView === SphereView.Sphere && (
                        <Button
                            className="Sphere-header-add"
                            size="s"
                            view="primary"
                            width="default"
                            onlyIcon
                            iconRight={IconAdd}
                            ref={addBtnRef}
                            onMouseEnter={() => setIsAddBtnTooltipVisible(true)}
                            onMouseLeave={() =>
                                setIsAddBtnTooltipVisible(false)
                            }
                            onClick={() => openNewNodeForm(undefined)}
                        />
                    )}
                    <Tooltip
                        isOpen={isAddBtnTooltipVisible}
                        anchorRef={addBtnRef}
                        placeholder={""}
                    >
                        <Text view="primary" lineHeight="m" size="xs">
                            Добавить узел
                        </Text>
                    </Tooltip>
                    {/* <Button
                        className="Sphere-header-reload"
                        size="s"
                        view="primary"
                        width="default"
                        onlyIcon
                        iconRight={IconRestart}
                        onClick={reloadWeights}
                    /> */}
                    {tab.sphereView === SphereView.Projection && (
                        <Button
                            className="Sphere-header-projection"
                            size="s"
                            view="primary"
                            width="default"
                            onlyIcon
                            iconRight={IconShare}
                            ref={saveProjBtnRef}
                            onMouseEnter={() =>
                                setIsSaveProjBtnTooltipVisible(true)
                            }
                            onMouseLeave={() =>
                                setIsSaveProjBtnTooltipVisible(false)
                            }
                            onClick={saveAsProjection}
                        />
                    )}
                    <Tooltip
                        isOpen={isSaveProjBtnTooltipVisible}
                        anchorRef={saveProjBtnRef}
                        placeholder={""}
                    >
                        <Text view="primary" lineHeight="m" size="xs">
                            Сохранить проекцию в файл
                        </Text>
                    </Tooltip>
                    <Button
                        className="Sphere-header-save"
                        size="s"
                        view="primary"
                        width="default"
                        onlyIcon
                        iconRight={IconSave}
                        ref={saveBtnRef}
                        onMouseEnter={() => setIsSaveBtnTooltipVisible(true)}
                        onMouseLeave={() => setIsSaveBtnTooltipVisible(false)}
                        onClick={save}
                    />
                    <Tooltip
                        isOpen={isSaveBtnTooltipVisible}
                        anchorRef={saveBtnRef}
                        placeholder={""}
                    >
                        <Text view="primary" lineHeight="m" size="xs">
                            Сохранить сферу в файл
                        </Text>
                    </Tooltip>
                </div>
                {props.router.getIsLoading() && <Loader size="m" />}
                {!props.router.getIsLoading() &&
                    renderTab(
                        tab.sphereView,
                        props.core.getVisibleNodes(), // фильтрованная копия storage.nodes
                        nodeResults,
                        brokenLinks,
                        deleteNode,
                        editNode,
                        openNewNodeForm,
                        onUpdateNode,
                        onMoveNodeAfter,
                        onMoveNodeBefore,
                    )}
                {defaultNodeType && (
                    <EditNodeForm
                        isModalOpen={isNewNodeFormOpen}
                        onAddNode={addNode}
                        onDeleteNode={deleteNode}
                        onClose={closeNewNodeForm}
                        storage={props.storage}
                        core={props.core}
                        editingNodeId={editingNodeId}
                        linkNodeId={linkNodeId}
                        defaultType={defaultNodeType}
                    ></EditNodeForm>
                )}
            </div>
        </Page>
    );
}

interface ISphereProps {
    authService: IAuthService;
    core: ICore;
    storage: IStorage;
    router: IRouter;
    sphereService: ISphereService;
}

export default SphereScreen;
