import { CommonError } from "../errors/error";

export const errorMessageUnknown = "Что-то пошло не так..."

export const errorAuthFailed = "auth_failed"
export const errorDecodingFailed = "json_decoding_failed"

export const errorContextNetwork = "network"

export const connectionError: CommonError = {code: 100, context: errorContextNetwork, message: "Проблемы с подключением"}
export const authError: CommonError = {code: 101, context: errorContextNetwork, message: "Проблемы с авторизацией"}