import "./MainFooter.css";

import { Text } from '@consta/uikit/Text';
import EmptySpace, { VSpace } from "../emptySpace/EmptySpace";

function MainFooter(props: IMainFooter) {
    const aboutText = "ИП Дубовая Анна Александровна, ОГРНИП: 315253600004690"
    const copyRight = "Все права защищены © 2024"
    return (
        <div className="MainFooter">
            <EmptySpace space={VSpace.Space24} />
            <div className="MainFooter-container">
                <Text view="linkMinor" size="xs">{aboutText}</Text>
                <Text view="linkMinor" size="xs">{copyRight}</Text>
            </div>
            <EmptySpace space={VSpace.Space4} />
        </div>
    );
}

type IMainFooter = {};
export default MainFooter;
