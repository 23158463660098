import EmptySpace, {VSpace} from "../../controls/emptySpace/EmptySpace";
import PageH1 from "../../controls/pageH1/PageH1";
import Paragraph from "../../controls/paragraph/Paragraph";

// Система приоритизации Афины оснащена встроенным планировщиком, работающим в связке с модулем контроля ресурсов. 

function ManualPage() {
    return (
        <div>
            <Paragraph>
                <>
                Афина — это универсальная аналитическая система, предназначенная для поиска и принятия значимых решений. Где провести отпуск?  Куда пойти учиться? В какую сферу инвестировать? Когда заводить семью? С кем строить бизнес? Чего я хочу на самом деле? <em>Что мне делать?</em> Поиск ответа на подобные вопросы значительно упрощается, если воспользоваться современными инструментами.
                </>
            </Paragraph>
            <Paragraph
                content={`Расчеты выполняются под управлением логического ядра, основанном на теории графов и методе анализа иерархий. Таким образом, Афина является системой многокритериального анализа.`}
            />
            <Paragraph>
                <>
                Метод анализа иерархий позволяет наглядным образом представить сложную проблему в виде иерархической структуры, а затем дать <em>количественную</em> оценку альтернативных вариантов решения. Афина полностью автоматизирует расчет оценки, что позволяет выполнять анализ ежедневно. Однозначный ответ на вопрос «А туда ли я иду?» устраняет сомнения и тревожность, вселяет веру в собственные силы и планы. Таким образом, Афина — это ультимативный ключ к личной эффективности.
                </>
            </Paragraph>
            <PageH1 text="Сущности" />
            <Paragraph>
                <>
                    <strong>Сфера</strong> — это предметная область, для которой требуется вычислить приоритет задач, т.е. очередность их выполнения. Сферой может быть что угодно: путешествие, рабочий проект, персона, и т.п. Например, в сфере «Поездка в Москву» у вас может быть 2 задачи: экскурсия по городу и посещение Третьяковской галереи. Непросто определить, чем заняться в первую очередь, особенно если у вас в запасе всего один день.
                </>
            </Paragraph>
            <Paragraph>
                <>
                    Каждая сфера в Афине представлена в виде иерархической структуры, состоящей из узлов и связей. Для каждого узла Афина рассчитывает его <strong>влияние</strong> (или <strong>влиятельность</strong>), используя структуру связей между узлами и поправочные коэффициенты. <strong>Влиятельность</strong> — это степень воздействия конкретного узла на всю сферу в целом, рассчитанная относительно других узлов. Влияние не всегда совпадает с субъективным восприятием значимости узла. Более того, именно эти расхождения формируют большинство «инсайтов», которые рождаются при общении с Афиной. Благодаря наглядной иерархической структуре всегда легко понять, <i>почему</i> было получено то или иное значение влияния, что позволяет легко «помирить» сознательное «надо» с подсознательным «хочу». Синхронизация «надо» и «хочу» является необходимым условием долговременной личной эффективности.
                </>
            </Paragraph>
            <Paragraph>
                <>
                    При создании новой сферы корневой узел создается автоматически, причем название корневого узла совпадает с названием сферы (при желании можно переименовать). Этот узел называется <strong>ключом</strong> сферы. Ключ является источником влияния для всех остальных узлов сферы, т.е. влияние ключа распределяется между ними согласно иерархии наследования. В конечном счете суммарное влияние листовых узлов оказывается всегда равным исходному значению, указанному в ключе.
                </>
            </Paragraph>
            <Paragraph>
                <>
                    На первом уровне иерархии влияние ключа распределяется между <strong>доменами</strong> — узлами, которые являются неотъемлемыми составляющими ключа. Например, для ключа/сферы «Моя жизнь» доменами могут быть «Семья», «Здоровье», «Дело», «Деньги», «Друзья», «Хобби» — произвольные составляющие, которые мы считаем значимыми в контексте собственной жизни. Домен, как и ключ, может в свою очередь разделять свое влияние между <strong>субдоменами</strong>. Например, домен «Здоровье» может разделяться на «Физическое здоровье» и «Ментальное здоровье». Субдомен может быть привязан только к одному домену-родителю. Таким образом, значимость домена в основном определяется его положением в иерархии доменов. Влияние домена отражает его фактическую значимость, а не ожидаемую/субъективную.
                </>
            </Paragraph>
            <Paragraph>
                <>
                    Все домены на одном уровне иерархии исходно имеют одинаковую влиятельность, поскольку они одинаково важны. Если без какого-либо домена можно обойтись, то такой узел не является доменом. Напротив, если какой-либо узел влияет на большую часть доменов, то такой узел, скорее всего, сам является доменом. Влияние ключа исходно распределяется между доменами равномерно, но на это распределение можно повлиять, изменяя <strong>показатель удовлетворенности состоянием домена</strong>. Показатель удовлетворенности есть только у доменов.
                </>
            </Paragraph>
            <Paragraph>
                <>
                    В общем случае задача приоритизации действий сводится к тому, чтобы в результате выполнения действий добиться равномерного распределения влияния между доменами одного уровня. Гармония — это «срединный путь», отсутствие значительного разброса. Например, если вы находитесь в реанимации, вряд ли ваши мысли занимает бизнес-план нового проекта. Любые действия, направленные на улучшение здоровья, логично оказывают наибольшее <strong>влияние</strong> на вашу жизнь. Афина учитывает, что показатель удовлетворенности доменом «Здоровье» сейчас находится на минимальной отметке, поэтому и домен «Здоровье» заимствует влиятельность всех прочих доменов. Действия, направленные на изменение состояния доменов, распределяются по <strong>направлениям</strong>. Например, к домену «Физическое здоровье» можно привязать направления «Терапия», «Физкультура», «Диета» и т.п. 
                </>
            </Paragraph>
            <Paragraph>
                <>
                    Направления можно привязывать к произвольному количеству доменов-листьев (т.е. к доменам, у которых нет субдоменов) и к произвольному количеству других направлений. Циклические связи не допускаются. Направления, как и домены, наследуют влияние своих родителей. Основное различие между доменом и направлением в том, что направления можно привязывать к произвольному количеству доменов-листьев, в то время как каждый домен связан только с одним родителем. Направления наследуют <i>суммарное</i> влияние всех доменов, к которым они привязаны, но при этом поровну разделяют влияние своих доменов между собой. Например, направление «Терапия» логично привязать к одному домену «Физическое здоровье», в то время как направление «Физкультура» вполне можно дополнительно привязать к доменам «Друзья» и «Семья», если вы выполняете упражнения в компании. При такой структуре сферы направление «Физкультура» получит большее влияние, нежели «Терапия». 
                </>
            </Paragraph>
            <Paragraph>
                <>
                    Направления конкурируют за влияние своих доменов, поэтому чем больше к домену подключено направлений, тем меньше влияния этого домена достанется каждому из направлений в отдельности.  
                </>
            </Paragraph>
            <Paragraph>
                <>
                    Ключ, домены и направления в совокупности формируют сферу, с помощью которой выполняется оценка <em>потенциального эффекта</em> от выполнения <b>задач</b>. Задачи — это конечные узлы сферы, т.е. задачи всегда являются листьями, не могут иметь потомков. Задачи могут привязываться к произвольному количеству направлений-листьев. Задачи наследуют суммарную влиятельность своих направлений, но, в отличие от направлений, не конкурируют за ресурсы, т.е. не делят влиятельность с другими задачами. Задача — это всего лишь <em>возможный вариант</em>, идея, которая потенциально может быть воплощена в жизнь в рамках одного или нескольких направлений. Направление же расходует ресурсы уже самим фактом своего существования, т.е. каждое направление можно рассматривать как проект, взятый в работу. 
                </>
            </Paragraph>
            <Paragraph>
                <>
                    Таким образом, сфера – это инструмент оценки задач. Оценка определяет <em>потенциальный эффект</em> от выполнения задачи, оказанный на коэффициенты удовлетворенности в доменах сферы.
                </>
            </Paragraph>
            <PageH1 text="Начало работы" />
            <Paragraph>
                <>
                    Афина предлагает несколько уровней обслуживания. «Гостевой» уровень не требует регистрации, поэтому начать работу можно сразу. Для этого на <a href="/">Главной</a> странице нажмите кнопку «Начать» — появится диалог «Выберите сферу». В диалоге следует указать название новой сферы и нажать кнопку «Продолжить» либо дополнительно импортировать сферу из файла (расширение <i>.afina</i>).
                </>
            </Paragraph>
            <Paragraph>
                <>
                    Продолжить работу над сохраненной ранее сферой очень просто: нажмите кнопку «Начать» на главной, затем в диалоге выбора сферы нажмите кнопку «Выбрать файл». В диалоге выбора файла для выбора будут доступны файлы с расширением <i>.afina</i>. Файл также можно выбрать перетаскиванием. Укажите название импортируемой сферы, после чего по нажатию на кнопку «Продолжить» сфера откроется автоматически.
                </>
            </Paragraph>
            <Paragraph>
                <>
                    При «гостевом» уровне обслуживания данные хранятся только в вашем браузере, поэтому не забудьте по окончании работы сохранить их на диск. Для этого нажмите кнопку «Сохранить сферу в файл», расположенную в правом верхнем углу. Браузер сохранит сферу в файл <i>NAME.afina</i>, где <i>NAME</i> — это имя сферы, указанное при ее создании. При стандартных настройках браузера файл будет находиться в каталоге «Загрузки».
                </>
            </Paragraph>
            <PageH1 text="Регистрация" />
            <Paragraph>
                <>
                    Регистрация означает автоматический переход на уровень обслуживания «Базовый», который также предоставляется бесплатно, но позволяет сохранять сферу в облаке. Вам больше не придется заботиться о сохранении сферы на диске, все изменения сохраняются автоматически. Облачное хранилище предоставляет доступ к сфере с любого устройства, т.е. после регистрации для работы на любом компьютере вам потребуются только интернет и данные для входа (логин/email и пароль). 
                </>
            </Paragraph>
            <PageH1 text="Практический пример. Сфера «Моя жизнь»" />
            <Paragraph>
                <>
                    В контексте сферы «Моя жизнь» Афина позволяет найти ответы на вопросы «Кто я?», «Куда я иду?» и «Что мне делать?».
                </>
            </Paragraph>
            <Paragraph>
                <>
                    Совокупность доменов — это ответ на вопрос «Кто я?», поскольку она отражает наиболее значимые, неотъемлемые аспекты жизни. Субъективная оценка качества этих аспектов выражена показателями удовлетворенности.
                </>
            </Paragraph>
            <Paragraph>
                <>
                    Совокупность направлений — это ответ на вопрос «Куда я иду?», поскольку отражает направления деятельности, на которые постоянно расходуются ресурсы (время, деньги, «мыслетопливо» и т.п.).
                </>
            </Paragraph>
            <Paragraph>
                <>
                      Ответ на вопрос «Что мне делать?» становится очевиден, если применить сферу для оценки каждой задачи из списка дел. Задача, имеющее наибольшее влияние, — первый кандидат на выполнение. Вместе с тем при выборе задачи в каждый конкретный момент времени следует учитывать распределение ресурсов между направлениями. Ресурсы следует распределять между направлениями прямо пропорционально их влиянию на сферу. Распределение ресурсов выполняется в контексте <strong>горизонта планирования</strong> или <strong>расчетного периода</strong> – временного интервала, за который реально получить значимые изменения в коэффициентах удовлетворенности.
                </>
            </Paragraph>
            <Paragraph>
                <>
                    Допустим, в расчетном периоде (например, один месяц) у индивида есть 100 свободных часов, которыми он может распоряжаться по своему усмотрению. Это время, которое у него остается после выполнения базовых потребностей/обязанностей (сон, работа, прием пищи, помощь близким и т.п.) Допустим, направление «Спорт» имеет влияние 12 из 100. Это означает, что на спорт в месяц нужно выделять совокупно 12 часов – не больше и не меньше. Если заниматься спортом меньше, чем рассчитано, то направление останется «недофинансированным», не удастся улучшить показатель удовлетворенности в домене «Здоровье». Если заниматься больше, то «недофинансированными» останутся другие направления. Получается, что для занятий спортом есть варианты:
                    <ul>
                        <li>по 1 часу 3 раза в неделю (в месяце 4 недели);</li>
                        <li>по 30 минут каждый день (кроме воскресенья);</li>
                        <li>раз в неделю по 3 часа.</li>
                    </ul>
                    Таким образом, на основе анализа влияния направления и имеющихся в доступе ресурсов можно определить вид спорта, которым можно заняться: workout на свежем воздухе во дворе 3 раза в неделю, велотренажер на балконе каждый день или полноценное тренировка в спортзале раз в неделю (с учетом логистики). 
                </>
            </Paragraph>
            <Paragraph>
                <>
                    Что делать, если в расчетном периоде ресурс для некоторого направления уже выбран, но виду каких-либо внешних обязательств работу над ним необходимо продолжить? Очень просто: нужно найти задачу, которая будет обслуживать не только это направление, но и какое-либо другое, «недофинансированное»! Например, если есть обязательство принять участие в марафоне, то ежедневные пробежки можно удвоить, если дополнительно выполнять их в компании друзей или членов семьи. Тогда очередная тренировка может пройти в зачет направлениям «Общение с семьей» или «Общение с друзьями».
                </>
            </Paragraph>
            <Paragraph>
                <>
                    В пространстве доменов есть бесконечное количество направлений, движение по которым позволяет улучшить баланс в доменах. В пространстве направлений есть бесконечное количество задач, которыми можно эти направления наполнить. А еще каждую задачу можно выполнить бесконечным количеством способов. Хорошая новость в том, что наш мозг решает эту задачу каждый день. Афина — это инструмент, который этот процесс может облегчить и упростить.
                </>
            </Paragraph>
            

        </div>
    )
}
export default ManualPage;