import "./SphereRenameForm.css";

import {useEffect, useRef, useState} from "react";

import {Button} from "@consta/uikit/Button";
import {Modal} from "@consta/uikit/Modal";
import {TextField, TextFieldPropValue} from "@consta/uikit/TextField";

import FormHeader from "../../controls/formHeader/FormHeader";

function SphereRenameForm(props: ISphereRenameProps) {
    const [title, setTitle] = useState<string>("");
    const handleTitle = (value: TextFieldPropValue) => {
        setTitle(value || "");
    };

    const inputRef = useRef<HTMLInputElement>(null);
    useEffect(() => {
        inputRef.current?.focus();
    }, [props.isModalOpen]);

    const clean = () => {
        setTitle("");
    };

    const close = () => {
        props.onClose();
        clean();
    };
    const save = () => {
        props.onReady(title);
        close()
    };

    return (
        <Modal
            isOpen={props.isModalOpen}
            hasOverlay
            position="center"
            onClickOutside={close}
            onEsc={close}
        >
            <div className="SphereRenameForm">
                <FormHeader onClose={close} text="Переименовать сферу" />
                <div className="NewNodeForm-content">
                    <TextField
                        inputRef={inputRef}
                        value={title}
                        type="text"
                        placeholder="Укажите новое название сферы"
                        onChange={handleTitle}
                    />
                </div>

                <div className="SphereRenameForm-footer">
                    <Button
                        size="m"
                        view="primary"
                        label="Готово"
                        width="default"
                        onClick={save}
                        disabled={title.length === 0}
                    />
                </div>
            </div>
        </Modal>
    );
}

type ISphereRenameProps = {
    isModalOpen: boolean;
    onClose: VoidFunction;
    onReady: (title: string) => void;
};

export default SphereRenameForm;
