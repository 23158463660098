import IRouter from "./IRouter";
import IRouterSetup from "./IRouterSetup";
import {AppScreen, Route} from "./routes";

class Router implements IRouter, IRouterSetup {

    // IRouter

    onReload?: VoidFunction;

    reload() {
        this._reload()
        this.onReload && this.onReload()
    }

    getCurrentPage() {
        return this.currentPage;
    }

    getCurrentRoute() {
        return this.currentRoute;
    }

    goBack() {
        this._goBack();
    }

    goToRoute(route: Route) {
        this._goToRoute(route);
    }

    hideLoader() {
        return this._hideLoader();
    }

    showLoader() {
        return this._showLoader();
    }

    getIsLoading() {
        return this.isLoading;
    }

    showSphereEditor() {
        this._showSphereEditor();
    }

    showDashboard() {
        this._showDashboard();
    }

    // IRouterSetup
    
    isAuth?: BoolResult;

    // Setup

    private currentPage: AppScreen;
    private currentRoute: Route;
    private isLoading: boolean;
    private isOnEditing: boolean;

    private defaultPath = ""
    private routesMap = new Map<Route, string>([
        [Route.ContentContacts, "/contacts"],
        [Route.ContentManual, "/manual"],
        [Route.ContentPrivacy, "/privacy"],
        [Route.ContentTariffs, "/tariffs"],
        [Route.ContentTermOfUse, "/terms-of-use"],
        [Route.Main, this.defaultPath],
        [Route.PaymentHistory, "/payments"]
    ]);

    constructor() {
        this.currentPage = AppScreen.Start;
        this.currentRoute = Route.Main;
        this.isLoading = false;
        this.isOnEditing = false;
    }

    // Internal

    private _showSphereEditor = () => {
        this.currentPage = AppScreen.Sphere;
        this.isOnEditing = true
        this.reload();
    };

    private _showDashboard = () => {
        this.currentPage = AppScreen.Dashboard;
        this.isOnEditing = false
        this.reload();
    };

    private _hideLoader = () => {
        this.isLoading = false;
        this.reload();
    };

    private _showLoader = () => {
        this.isLoading = true;
        this.reload();
    };

    private _goBack = () => {
        switch (this.currentRoute) {
            case Route.ContentContacts:
                this._goToRoute(Route.Main);
                break;
            case Route.ContentManual:
                this._goToRoute(Route.Main);
                break;
            case Route.ContentPrivacy:
                this._goToRoute(Route.Main);
                break;
            case Route.ContentTariffs:
                this._goToRoute(Route.Main);
                break;
            case Route.ContentTermOfUse:
                this._goToRoute(Route.Main);
                break;
            case Route.Main:
                this._goToRoute(Route.Main);
                break;
            case Route.PaymentHistory:
                this._goToRoute(Route.Main);
                break;
            }
    };

    private _goToRoute = (route: Route) => {
        const host = process.env.REACT_APP_EXPLORER_HOST
        this.currentRoute = route;
        const url = host + (this.routesMap.get(route) || "")
        window.location.replace(url);
    };

    private _isAuth = () => {
        return (this.isAuth && this.isAuth()) || false
    }

    private _reload = () => {
        this.currentRoute = Route.Main
        for (const key of this.routesMap.keys()) {
            const path = this.routesMap.get(key) || this.defaultPath
            if (path && window.location.href.endsWith(path)) {
                this.currentRoute = key
                break
            }
        }

        if (this.currentRoute === Route.ContentContacts) {
            this.currentPage = AppScreen.Content
            return
        }

        if (this.currentRoute === Route.ContentManual) {
            this.currentPage = AppScreen.Content
            return
        }

        if (this.currentRoute === Route.ContentPrivacy) {
            this.currentPage = AppScreen.Content
            return
        }

        if (this.currentRoute === Route.ContentTariffs) {
            this.currentPage = AppScreen.Content
            return
        }

        if (this.currentRoute === Route.ContentTermOfUse) {
            this.currentPage = AppScreen.Content
            return
        }

        if (this.currentRoute === Route.PaymentHistory) {
            this.currentPage = AppScreen.PaymentHistory
            return
        }
        
        if (this._isAuth()) {
            if (this.isOnEditing) {
                this.currentPage = AppScreen.Sphere
            } else {
                this.currentPage = AppScreen.Dashboard
            }
            return
        }

        this.currentPage = AppScreen.Start

        return
    }
}

export default Router;
