import "./TariffPage.css";

import TariffCard from "./tariffCard/TariffCard";

import IAuthService from "../../../services/auth/IAuthService";
import ITariffService from "../../../services/tariff/ITariffService";
import { useEffect, useState } from "react";
import { TariffAction, TariffActionOption, TariffActionType, TariffItem } from "../../../services/api/tariff/entities/entities";
import AuthEnterForm from "../../forms/authEnterForm/AuthEnterForm";
import AuthRegisterForm from "../../forms/authRegisterForm/AuthRegisterForm";
import AuthRestoreForm from "../../forms/authRestoreForm/AuthRestoreForm";
import IRouter from "../../router/IRouter";
import IStorage from "../../../services/storage/IStorage";
import IProductService from "../../../services/product/IProductService";
import { ProductItem } from "../../../services/api/product/entities/entities";
import ProductForm from "../../forms/productForm/ProductForm";
import IPaymentService from "../../../services/payment/IPaymentService";

function TariffsPage(props: ITariffsPage) {
    
    const isSigned = props.authService.isSignedIn();

    const onProductSelected = (productId: number) => {
        closeProductForm()
        setIsLoadingNow(true);
        props.paymentService.addPayment(productId, (response) => {
            setIsLoadingNow(false);
            window.open(response.confirmation_url, '_blank');
        });
    };

    const onTariffSelected = (action: TariffAction, tariffId: number) => {
        switch (action.type) {
            case TariffActionType.Auth:
                showEnterForm()
                break;
            case TariffActionType.Prolong:
            case TariffActionType.Switch:
                if (action.options.includes(TariffActionOption.Payment)) {
                    showProducts(tariffId)
                } else {
                    alert("Обратитесь к администратору")
                }
                break;
        }
    };

    const [isLoadingNow, setIsLoadingNow] = useState<boolean>(false);
    const [tariffs, setTariffs] = useState<TariffItem[]>([]);

    const showProducts = (tariffId: number) => {
        setIsLoadingNow(true);
        props.productService.getProductList(tariffId, (response) => {
            setIsLoadingNow(false);
            setProducts(response.list)
            showProductForm()
        });
    };

    const [products, setProducts] = useState<ProductItem[]>([]);

    const [isProductFormOpen, setIsProductFormOpen] = useState<boolean>(false);
    const showProductForm = () => {
        setIsProductFormOpen(true);
    };
    const closeProductForm = () => {
        setIsProductFormOpen(false);
    };
    
    const updateTariffs = () => {
        setIsLoadingNow(true);
        props.tariffService.getTariffList((response) => {
            setIsLoadingNow(false);
            setTariffs(response.list || []);
        });
    };

    useEffect(() => {
        updateTariffs();
    }, [isSigned]);
    
    const [isEnterFormOpen, setIsEnterFormOpen] = useState<boolean>(false);
    const showEnterForm = () => {
        setIsEnterFormOpen(true);
    };
    const closeEnterForm = () => {
        setIsEnterFormOpen(false);
    };
    
    const [isRegisterFormOpen, setIsRegisterFormOpen] =
        useState<boolean>(false);
    const showRegisterForm = () => {
        setIsRegisterFormOpen(true);
    };
    const closeRegisterForm = () => {
        setIsRegisterFormOpen(false);
    };

    const [isRestoreFormOpen, setIsRestoreFormOpen] = useState<boolean>(false);
    const showRestoreForm = () => {
        setIsRestoreFormOpen(true);
    };
    const closeRestoreForm = () => {
        setIsRestoreFormOpen(false);
    };

    const updateAuth = () => {
        props.router.reload();
        updateTariffs();
    };

    return (
        <div className="TariffPage">
            <div className="TariffPage-container">
                <div className="TariffPage-card-container">
                    {tariffs.map(function(item) {
                       let price = `Цена уточняется`
                        if (item.min_month_price === undefined || item.min_month_price === null) {

                        } else if (item.min_month_price === 0) {
                            price = `Бесплатно`
                        } else if (item.min_month_price > 0) {
                            price = `От ${item.min_month_price} ₽/мес`
                        }
                       return (
                            <TariffCard
                                id={item.tariff_id}
                                title={item.name}
                                price={price}
                                items={item.description.split("\n")}
                                isCurrent={item.is_current}
                                action={item.action}
                                onAction={onTariffSelected}
                                key={item.tariff_id}
                            />
                        )
                    })}
                </div>
            </div>
            <AuthEnterForm
                isModalOpen={isEnterFormOpen}
                onClose={closeEnterForm}
                onRegister={showRegisterForm}
                onFinish={updateAuth}
                storage={props.storage}
            />
            <AuthRegisterForm
                isModalOpen={isRegisterFormOpen}
                onClose={closeRegisterForm}
                onFinish={updateAuth}
                storage={props.storage}
            />
            <AuthRestoreForm
                isModalOpen={isRestoreFormOpen}
                onClose={closeRestoreForm}
                onFinish={updateAuth}
                storage={props.storage}
            />
            <ProductForm
                isModalOpen={isProductFormOpen}
                onClose={closeProductForm}
                onSelect={onProductSelected}
                products={products}
            />
        </div>
    );
}
type ITariffsPage = {
    authService: IAuthService;
    router: IRouter;
    storage: IStorage;
    tariffService: ITariffService;
    productService: IProductService;
    paymentService: IPaymentService;
};
export default TariffsPage;
