import EmptySpace, { VSpace } from "../emptySpace/EmptySpace";
import "./PageH1.css";

import {Text} from "@consta/uikit/Text";

function PageH1(props: PageH1Props) {
    return (
        <div className="PageH1">
            <div className="PageH1-container">
                <EmptySpace space={VSpace.Space32}/>
                <Text size="l" view="brand" weight="semibold">
                    {props.text}
                </Text>
                <EmptySpace space={VSpace.Space8}/>
            </div>
        </div>
    );
}
type PageH1Props = {
    text: string;
};

export default PageH1;
