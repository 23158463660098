import "./ProductCard.css";

import {Button} from "@consta/uikit/Button";
import {Card} from "@consta/uikit/Card";
import {Text} from "@consta/uikit/Text";

import EmptySpace, {VSpace} from "../../../controls/emptySpace/EmptySpace";

function ProductCard(props: IProductCard) {
    const activate = () => {
        props.onActivate && props.onActivate(props.id);
    };

    return (
        <Card className="ProductCard" verticalSpace="l" horizontalSpace="l">
            <div className="ProductCard-container">
                <Text
                    className="ProductCard-header"
                    size="2xl"
                    view="brand"
                    weight="semibold"
                >
                    {props.title}
                </Text>
                <EmptySpace space={VSpace.Space32} />
                <Text
                    className="ProductCard-month-price"
                    size="2xl"
                    view="brand"
                    weight="light"
                >
                    {props.monthPrice}
                </Text>
                <EmptySpace space={VSpace.Space8} />
                <Text
                    className="ProductCard-price"
                    size="l"
                    view="secondary"
                    weight="light"
                >
                    {props.price}
                </Text>
                <EmptySpace space={VSpace.Space48} />
                <Button
                    label="Перейти к оплате"
                    view="primary"
                    onClick={activate}
                />
                <EmptySpace space={VSpace.Space16} />
            </div>
        </Card>
    );
}
type IProductCard = {
    id: number;
    title: string;
    price: string;
    monthPrice: string;
    onActivate?: IntCompletion;
};
export default ProductCard;
