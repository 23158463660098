import "./Content.css";

import IAuthService from "../../../services/auth/IAuthService";
import BackButton from "../../controls/backButton/BackButton";
import ContentHeader from "../../controls/contentHeader/ContentHeader";
import MainFooter from "../../controls/mainFooter/MainFooter";
import PageHeader from "../../controls/pageHeader/PageHeader";
import IRouter from "../../router/IRouter";
import Page from "../../controls/page/Page";

function ContentScreen(props: IContentScreenProps) {
    return (
        <Page>
            <div className="Content">
                <PageHeader>
                    <BackButton router={props.router}/>
                    <div></div>
                </PageHeader>
                <div className="Content-container">
                    <ContentHeader title={props.title}/>
                    {props.children}
                </div>
                <MainFooter />
            </div>
        </Page>
    );
}
type IContentScreenProps = {
    authService: IAuthService;
    router: IRouter;
    title: string;
    children: JSX.Element;
};
export default ContentScreen;
