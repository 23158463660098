import "./SphereAddForm.css";

import {useEffect, useRef, useState} from "react";

import {Button} from "@consta/uikit/Button";
import {DragNDropField} from "@consta/uikit/DragNDropField";
import {Modal} from "@consta/uikit/Modal";
import {TextField, TextFieldPropValue} from "@consta/uikit/TextField";

import {Node} from "../../../entities/Node";
import FormHeader from "../../controls/formHeader/FormHeader";

function SphereAddForm(props: ISphereAddFormProps) {
    const [title, setTitle] = useState<string>("");
    const handleTitle = (value: TextFieldPropValue) => {
        setTitle(value || "");
    };

    const inputRef = useRef<HTMLInputElement>(null);
    useEffect(() => {
        inputRef.current?.focus();
    }, [props.isModalOpen]);

    const clean = () => {
        setTitle("");
    };

    const close = () => {
        props.onClose();
        clean();
    };

    const save = () => {
        if (title) {
            props.onReady(title)
        }
        close()
    }

    return (
        <Modal
            isOpen={props.isModalOpen}
            hasOverlay
            position="center"
            onClickOutside={() => close()}
            onEsc={() => {
                close();
            }}
        >
            <div className="SphereImportForm">
                <FormHeader onClose={close} text="Импорт сферы" />
                <div className="SphereImportForm-content">
                    <TextField
                        inputRef={inputRef}
                        value={title}
                        type="text"
                        placeholder="Укажите название новой сферы"
                        onChange={handleTitle}
                    />
                </div>

                <div className="SphereAddForm-footer">
                    <Button
                        size="m"
                        view="primary"
                        label="Готово"
                        width="default"
                        onClick={save}
                        disabled={!(title)}
                    />
                </div>
            </div>
        </Modal>
    );
}

type ISphereAddFormProps = {
    isModalOpen: boolean;
    onClose: VoidFunction;
    onReady: (title: string) => void;
};

export default SphereAddForm;
