import {NodeType, nodeTypeToString} from "../../../entities/NodeType";

import {Select} from "@consta/uikit/Select";

type NodeTypeSelectItem = {
    id: number;
    label: string;
    type: NodeType;
};

function makeSelectItems(items: NodeType[]): NodeTypeSelectItem[] {
    return items.map((item) => {
        return {
            id: item,
            label: nodeTypeToString(item),
            type: item,
        };
    });
}

function makeSelectItem(item: NodeType): NodeTypeSelectItem {
    return {
        id: item,
        label: nodeTypeToString(item),
        type: item,
    }
};

function NodeTypeSelect({items, selected, onSelect}: INodeTypeSelectProps) {

    const handleTypeSelection = (value: NodeTypeSelectItem | null) => {
        value && onSelect(value.type);
    };

    return (
        <div>
            {items.length > 0 && (
                <Select
                    items={makeSelectItems(items)}
                    value={makeSelectItem(selected)}
                    onChange={handleTypeSelection}
                    caption="Тип узла"
                    disabled={items.length == 0}
                />
            )}
        </div>
    );
}

type INodeTypeSelectProps = {
    items: NodeType[];
    selected: NodeType;
    onSelect: (type: NodeType) => void;
};

export default NodeTypeSelect;
