import {request} from "../../utils/network/request";

import {Node} from "../../../entities/Node";
import {BrokenLink} from "../../../entities/BrokenLink";
import {Link} from "../../../entities/Link";
import {NodeType} from "../../../entities/NodeType";

export type WeightResult = {
    id: string;
    val: number;
    lvl: number;
};

export type LinkResult = {
    id: string;
};

export type NodeResult = {
    id: string;
    links: LinkResult[];
    level: number;
    draw_level: number;
    is_leaf: boolean;
    is_cycled: boolean;
    impact: number;
    complex_quality_factor: number;
};

export type SphereResponse = {
    nodes: NodeResult[];
    broken_links: BrokenLink[];
};

export type ApiLink = {
    id: string;
};

export function linkToApi(link: Link): ApiLink {
    return {id: link.targetNodeId};
}

export type ApiNode = {
    id: string;
    is_key: boolean;
    is_metric: boolean;
    type: NodeType;
    quality?: number;
    links: ApiLink[];
};

export function nodeToApi(node: Node): ApiNode {
    return {
        id: node.id,
        is_key: node.type === NodeType.Key,
        is_metric: node.type !== NodeType.Task,
        type: node.type,
        quality: node.quality,
        links: node.links.map(linkToApi),
    };
}

export type SphereRequestBody = {
    nodes: ApiNode[];
};

export function getNodeResults(
    nodes: Node[],
    onResult: (sphereResponse: SphereResponse) => void
) {
    let requestBody: SphereRequestBody = {nodes: nodes.map(nodeToApi)};
    let bodyString = JSON.stringify(requestBody);
    let config: RequestInit = {
        method: "post",
        body: bodyString,
    };
    request<SphereResponse>(process.env.REACT_APP_SPHERUM_API_HOST + "/sphere", config).then(
        (sphere) => {
            onResult(sphere);
        }
    );
}
