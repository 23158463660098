import "./SphereImportForm.css";

import {useEffect, useRef, useState} from "react";

import {Button} from "@consta/uikit/Button";
import {DragNDropField} from "@consta/uikit/DragNDropField";
import {Modal} from "@consta/uikit/Modal";
import {TextField, TextFieldPropValue} from "@consta/uikit/TextField";

import {Node} from "../../../entities/Node";
import FormHeader from "../../controls/formHeader/FormHeader";

function SphereImportForm(props: ISphereImportFormProps) {
    const [title, setTitle] = useState<string>("");
    const handleTitle = (value: TextFieldPropValue) => {
        setTitle(value || "");
    };

    const inputRef = useRef<HTMLInputElement>(null);
    useEffect(() => {
        inputRef.current?.focus();
    }, [props.isModalOpen]);

    const clean = () => {
        setTitle("");
        setContent(undefined)
    };

    const close = () => {
        props.onClose();
        clean();
    };

    const [content, setContent] = useState<string | undefined>(undefined);
    const upload = (files: File[]) => {
        let file = files[0];
        let reader = new FileReader();
        reader.onload = (readerEvent: ProgressEvent<FileReader>) => {
            let fileContent = readerEvent.target?.result?.toString();
            if (fileContent) {
                // валидация/очистка контента через преобразование в объект
                const nodes = (JSON.parse(fileContent) as Node[]) || [];
                setContent(JSON.stringify(nodes))
            }
        };
        reader.readAsText(file, "UTF-8");
    };

    const save = () => {
        if (title && content) {
            props.onReady(title, content)
        }
        close()
    }

    return (
        <Modal
            isOpen={props.isModalOpen}
            hasOverlay
            position="center"
            onClickOutside={() => close()}
            onEsc={() => {
                close();
            }}
        >
            <div className="SphereImportForm">
                <FormHeader onClose={close} text="Импорт сферы" />
                <div className="SphereImportForm-content">
                    <TextField
                        inputRef={inputRef}
                        value={title}
                        type="text"
                        placeholder="Укажите название для импортируемой сферы"
                        onChange={handleTitle}
                    />
                </div>

                <div className="SphereNameForm-upload">
                    <div>
                        <DragNDropField onDropFiles={upload} accept=".afina" />
                    </div>
                </div>

                <div className="SphereImportForm-footer">
                    <Button
                        size="m"
                        view="primary"
                        label="Готово"
                        width="default"
                        onClick={save}
                        disabled={!(title && content)}
                    />
                </div>
            </div>
        </Modal>
    );
}

type ISphereImportFormProps = {
    isModalOpen: boolean;
    onClose: VoidFunction;
    onReady: (title: string, content: string) => void;
};

export default SphereImportForm;
