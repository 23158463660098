import {memo, useEffect, useState} from "react";
import {ReactMaskOpts, useIMask} from "react-imask";

import {Text} from "@consta/uikit/Text";
import {TextField, TextFieldPropValue} from "@consta/uikit/TextField";

import EmptySpace, {VSpace} from "../../controls/emptySpace/EmptySpace";

const TextFieldMemo = memo(TextField);

function CodePage(props: ICodePageProps) {
    const codeCaptionText: string = `
        Отправили на почту ${props.email} код подтверждения. Скопируйте код из письма,
        вставьте его в поле ввода и нажмите кнопку «Зарегистрироваться».
    `;

    const codeNoteText: string = `
        Письмо обычно приходит мгновенно, тема письма: «Регистрация в afina.world».
        Если письма нет, проверьте папку «Спам». Код действует 24 часа.
    `;

    const {ref, unmaskedValue, setValue} = useIMask<
        HTMLInputElement,
        ReactMaskOpts
    >({
        mask: "000 000",
    });

    useEffect(() => {
        props.onCodeReceived(unmaskedValue);
    }, [unmaskedValue]);

    return (
        <div>
            <TextFieldMemo
                placeholder="Код: 000 000"
                inputRef={ref}
                autoFocus
            />
            <Text className="RegisterForm-caption" view="primary" size="s">
                {codeCaptionText}
            </Text>
            <EmptySpace space={VSpace.Space16} />
            <Text
                className="RegisterForm-note"
                view="brand"
                size="s"
                fontStyle="italic"
            >
                {codeNoteText}
            </Text>
            <EmptySpace space={VSpace.Space16} />
        </div>
    );
}

type ICodePageProps = {
    email: string;
    onCodeReceived: NullableStringCompletion;
};

export default CodePage;
