import "./AuthRestoreForm.css";

import {useEffect, useState} from "react";
import {useDebounce} from "use-debounce";

import {Text} from "@consta/uikit/Text";
import {TextField, TextFieldPropValue} from "@consta/uikit/TextField";

import {authErrorContext, checkRestoringEmail, checkName} from "../../../services/api/auth/auth";
import {errorMessageUnknown} from "../../../services/utils/network/errors";
import EmptySpace, { VSpace } from "../../controls/emptySpace/EmptySpace";

function EmailPage(props: IEmailPageProps) {

    const emailCaptionText: string = `
        Укажите e-mail, который использовался для создания аккаунта.
    `;

    const emailNoteText: string = `
        Если не помните e-mail, создайте новый аккаунт.
    `;

    const [email, setEmail] = useState<string | null>(props.initialEmail);
    const handleEmailChange = (value: TextFieldPropValue) => {
        props.onAvailableEmail(null);
        setEmailIsOk(null);
        if (email) {
            setEmailCaption("Проверяем " + email + "...");
        } else {
            setEmailCaption("");
        }
        setEmail(value);
    };
    const [emailCaption, setEmailCaption] = useState<string>("");
    const [emailIsOk, setEmailIsOk] = useState<boolean | null>(null);
    const [debouncedEmail] = useDebounce(email, 1000);

    useEffect(() => {
        if (email) {
            checkRestoringEmail(email, (response) => {
                if (response.success) {
                    setEmailIsOk(true);
                    setEmailCaption("");
                    props.onAvailableEmail(email);
                } else {
                    setEmailIsOk(false);
                    if (response.error?.context === authErrorContext) {
                        setEmailCaption(response.error?.message);
                    } else {
                        setEmailCaption(
                            response.error?.message || errorMessageUnknown
                        );
                    }
                }
            });
        }
    }, [debouncedEmail]);

    const getStatus = () => {
        if (emailIsOk) {
            return "success";
        } else if (emailIsOk === false) {
            return "alert";
        } else {
            return undefined;
        }
    };

    return (
        <div>
            <TextField
                onChange={handleEmailChange}
                value={email || props.initialEmail}
                type="text"
                placeholder="E-mail"
                labelPosition="top"
                caption={emailCaption}
                status={getStatus()}
                withClearButton
                autoFocus
            />
            <Text
                className="RegisterForm-caption"
                view="primary"
                size="s"
            >
                {emailCaptionText}
            </Text>
            <EmptySpace space={VSpace.Space16} />
            <Text
                className="RegisterForm-note"
                view="brand"
                size="s"
                fontStyle="italic"
            >
                {emailNoteText}
            </Text>
            <EmptySpace space={VSpace.Space16} />
        </div>
    );
}

type IEmailPageProps = {
    initialEmail: string;
    onAvailableEmail: NullableStringCompletion;
};

export default EmailPage;
