export enum NodeType {
    Key = 1,
    Domain,
    Group,
    Task
}

export function nodeTypeToString(type: NodeType): string {
    let result: string = "?";
    switch (type) {
        case NodeType.Task:
            result = "Задача";
            break;
        case NodeType.Group:
            result = "Направление";
            break;
        case NodeType.Domain:
            result = "Домен";
            break;
        case NodeType.Key:
            result = "Ключ";
            break;
        }
        return result
}
