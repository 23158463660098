import "./Start.css";
import "../../controls/page/Page.css";
import logo from "./logo.svg";

import {Button} from "@consta/uikit/Button";
import {Text} from "@consta/uikit/Text";
import Page from "../../controls/page/Page";
import SphereNameForm from "../../forms/sphereNameForm/SphereNameForm";
import IStorage from "../../../services/storage/IStorage";
import {useState} from "react";
import PageHeader from "../../controls/pageHeader/PageHeader";
import AuthButton from "../../controls/authButton/AuthButton";
import AuthRegisterForm from "../../forms/authRegisterForm/AuthRegisterForm";
import AuthRestoreForm from "../../forms/authRestoreForm/AuthRestoreForm";
import AuthEnterForm from "../../forms/authEnterForm/AuthEnterForm";
import IRouter from "../../router/IRouter";
import MainFooter from "../../controls/mainFooter/MainFooter";
import EmptySpace, {VSpace} from "../../controls/emptySpace/EmptySpace";
import {Route} from "../../router/routes";

function StartScreen(props: IStartProps) {
    const shortDesc: string = `Афина — это аналитическая система для управления приоритетом задач в произвольной сфере.`;

    const longDesc: string = `Афина анализирует совокупное влияние задач друг на друга, а затем определяет удельное влияние каждой задачи на исследуемую сферу. Вычисления выполняются с помощью логического ядра, основанного на теории графов.`;

    const [isNameFormOpen, setIsNameFormOpen] = useState<boolean>(false);
    const showNameForm = () => {
        setIsNameFormOpen(true);
    };
    const closeNameForm = () => {
        setIsNameFormOpen(false);
    };

    const begin = () => {
        if ((window as any).buba) {
            alert((window as any).buba);
        } else {
            showNameForm();
        }
    };

    const openSphere = () => {
        closeNameForm();
        props.onContinue();
    };

    const [isRegisterFormOpen, setIsRegisterFormOpen] =
        useState<boolean>(false);
    const showRegisterForm = () => {
        setIsRegisterFormOpen(true);
    };
    const closeRegisterForm = () => {
        setIsRegisterFormOpen(false);
    };

    const [isRestoreFormOpen, setIsRestoreFormOpen] = useState<boolean>(false);
    const showRestoreForm = () => {
        setIsRestoreFormOpen(true);
    };
    const closeRestoreForm = () => {
        setIsRestoreFormOpen(false);
    };

    const [isEnterFormOpen, setIsEnterFormOpen] = useState<boolean>(false);
    const showEnterForm = () => {
        setIsEnterFormOpen(true);
    };
    const closeEnterForm = () => {
        setIsEnterFormOpen(false);
    };

    const updateAuth = () => {
        props.router.reload();
    };

    const linkButton = (title: string, onClick: VoidFunction) => {
        return (
            <Button
                className="Start-link-btn"
                view="clear"
                label={title}
                onClick={onClick}
            />
        );
    };

    return (
        <Page>
            <div className="Start">
                <PageHeader>
                    {linkButton("Инструкция", () => {
                        props.router.goToRoute(Route.ContentManual);
                    })}
                    {linkButton("Тарифы", () => {
                        props.router.goToRoute(Route.ContentTariffs);
                    })}
                    {linkButton("Условия использования", () => {
                        props.router.goToRoute(Route.ContentTermOfUse);
                    })}
                    {linkButton("Политика конфиденциальности", () => {
                        props.router.goToRoute(Route.ContentPrivacy);
                    })}
                    {linkButton("Контакты", () => {
                        props.router.goToRoute(Route.ContentContacts);
                    })}
                    <AuthButton
                        isAuth={props.storage.isSignedIn()}
                        onEnter={showEnterForm}
                        onRegister={showRegisterForm}
                        onRestore={showRestoreForm}
                    />
                </PageHeader>
                <div className="Start-container">
                    <EmptySpace space={VSpace.Space24} />
                    <img className="Start-logo" src={logo} alt="logo" />
                    <Text
                        className="Start-short-desc"
                        view="brand"
                        align="center"
                        lineHeight="s"
                        size="2xl"
                        weight="semibold"
                    >
                        {shortDesc}
                    </Text>
                    <Text
                        className="Start-long-desc"
                        view="primary"
                        align="center"
                        lineHeight="s"
                        size="l"
                    >
                        {longDesc}
                    </Text>
                    <EmptySpace space={VSpace.Space24} />
                    <Button
                        className="Start-continue-btn"
                        label="Начать"
                        onClick={begin}
                    />
                    {/* <EmptySpace space={VSpace.Space16} />
                    <Button
                        className="Start-continue-btn"
                        label="Пройти обучение"
                        view="ghost"
                        onClick={begin}
                    /> */}
                    <EmptySpace space={VSpace.Space48} />
                </div>
                <SphereNameForm
                    isModalOpen={isNameFormOpen}
                    onClose={closeNameForm}
                    onReady={openSphere}
                    storage={props.storage}
                />
                <AuthEnterForm
                    isModalOpen={isEnterFormOpen}
                    onClose={closeEnterForm}
                    onRegister={showRegisterForm}
                    onFinish={updateAuth}
                    storage={props.storage}
                />
                <AuthRegisterForm
                    isModalOpen={isRegisterFormOpen}
                    onClose={closeRegisterForm}
                    onFinish={updateAuth}
                    storage={props.storage}
                />
                <AuthRestoreForm
                    isModalOpen={isRestoreFormOpen}
                    onClose={closeRestoreForm}
                    onFinish={updateAuth}
                    storage={props.storage}
                />
                <MainFooter />
            </div>
        </Page>
    );
}

interface IStartProps {
    router: IRouter;
    storage: IStorage;
    onContinue: VoidFunction;
}

export default StartScreen;
