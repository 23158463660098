import EmptySpace, { VSpace } from "../../controls/emptySpace/EmptySpace";
import Paragraph from "../../controls/paragraph/Paragraph";

function ContactsPage() {
    
    const name = `Наименование организации: Индивидуальный Предприниматель Дубовая Анна Александровна`;
    const address = `Юридический адрес: Приморский край, Владивосток г, Нейбута ул, д.77`;
    const inn = `ИНН: 253610575362`;
    const grn = `ОГРНИП: 315253600004690`;

    const phone = `Телефон: +7 (901) 048 35 64`;
    const email = `E-mail: annet.dubovaya@internet.ru`;
    
    return (
        <div>
            <Paragraph content={name}/>
            <Paragraph content={address}/>
            <Paragraph content={inn}/>
            <Paragraph content={grn}/>
            <EmptySpace space={VSpace.Space32} />
            <Paragraph content={phone}/>
            <Paragraph content={email}/>
        </div>
    )
}
export default ContactsPage;