import "./Page.css"

type IPageProps = {
    children: JSX.Element
}

function Page({ children }: IPageProps) {
    return <div className="App-page">{children}</div>
}

export default Page
