import "./TablePlaceholder.css";

import { Loader } from '@consta/uikit/Loader';
import {Text} from '@consta/uikit/Text';

function TablePlaceholder(props: ITablePlaceholderProps) {
    const loader = () => {
        return (
            <div className="Table-loader-container">
                <div className="Table-loader">
                    <Loader size="m" />
                </div>
            </div>
        );
    };

    const emptyContent = () => {
        return (
            <div className="Table-empty-container">
                <div className="Table-empty">
                    <Text view="secondary" size="m">
                        {props.placeholderText}
                    </Text>
                </div>
            </div>
        );
    };

    return (
        props.isLoading ? loader() : emptyContent()
    );
}
type ITablePlaceholderProps = {
    isLoading: boolean;
    placeholderText: string;
}
export default TablePlaceholder;