import {Text} from "@consta/uikit/Text";
import EmptySpace, { VSpace } from "../emptySpace/EmptySpace";

function Paragraph(props: IParagraphProps) {
    return (
        <div>
            <EmptySpace space={VSpace.Space16} />
            <Text size="m" view="primary" weight="regular" lineHeight="l">
                {props.content || props.children}
            </Text>
            
        </div>
    )
}
type IParagraphProps = {
    content?: string;
    children?: JSX.Element;
}
export default Paragraph;