export enum AppScreen {
    Dashboard,
    Content,
    PaymentHistory,
    Start,
    Sphere,
}

export enum Route {
    ContentContacts,
    ContentManual,
    ContentPrivacy,
    ContentTariffs,
    ContentTermOfUse,
    Main,
    PaymentHistory
}