import INetworkService from "../../../network/INetworkService";
import {
    CommonResponse,
    errorResponse,
    getApiHost,
} from "../../../utils/network/common";
import {
    SphereAddRequest,
    SphereAddResponse,
    SphereDeleteRequest,
    SphereExploreRequest,
    SphereExploreResponse,
    SphereListResponse,
    SphereRenameRequest,
    SphereUpdateRequest,
    sphereListResponseError,
} from "../entities/entities";
import ISphereApiProvider from "./ISphereApiProvider";

class SphereApiProvider implements ISphereApiProvider {

    // ISphereApiProvider

    addSphere(
        title: string,
        content: string,
        onResult: (response: SphereAddResponse) => void
    ) {
        this._addSphere(title, content, onResult)
    }

    deleteSphere(
        sphereId: number,
        onResult: (response: CommonResponse) => void
    ) {
        this._deleteSphere(sphereId, onResult);
    }

    getSphere(
        sphereId: number,
        onResult: (sphereListResponse: SphereExploreResponse) => void
    ) {
        this._getSphere(sphereId, onResult);
    }

    getSphereList(onResult: (sphereListResponse: SphereListResponse) => void) {
        this._getSphereList(onResult);
    }

    renameSphere(
        sphereId: number,
        title: string,
        onResult: (response: CommonResponse) => void
    ) {
        this._renameSphere(sphereId, title, onResult);
    }

    updateSphere(
        sphereId: number,
        content: string,
        onResult: (response: CommonResponse) => void
    ) {
        this._updateSphere(sphereId, content, onResult);
    }

    // Setup

    private networkService: INetworkService;

    constructor(networkService: INetworkService) {
        this.networkService = networkService;
    }

    // Internal

    private _deleteSphere = (
        sphereId: number,
        onResult: (response: CommonResponse) => void
    ) => {
        let requestBody: SphereDeleteRequest = {sphere_id: sphereId};
        let bodyString = JSON.stringify(requestBody);
        let config: RequestInit = {
            method: "post",
            body: bodyString,
        };
        this.networkService
            .signedRequest<SphereListResponse>(
                getApiHost() + "/v1/sphere/delete",
                config
            )
            .then((result) => {
                onResult(result);
            })
            .catch((err) => {
                onResult(errorResponse);
            });
    };

    private _getSphereList = (
        onResult: (sphereListResponse: SphereListResponse) => void
    ) => {
        let requestBody = {};
        let bodyString = JSON.stringify(requestBody);

        let config: RequestInit = {
            method: "post",
            body: bodyString,
        };

        this.networkService
            .signedRequest<SphereListResponse>(
                getApiHost() + "/v1/sphere/list",
                config
            )
            .then((result) => {
                onResult(result);
            })
            .catch((err) => {
                onResult(sphereListResponseError);
            });
    };

    private _getSphere = (
        sphereId: number,
        onResult: (response: SphereExploreResponse) => void
    ) => {
        let requestBody: SphereExploreRequest = {sphere_id: sphereId};
        let bodyString = JSON.stringify(requestBody);
        let config: RequestInit = {
            method: "post",
            body: bodyString,
        };

        this.networkService
            .signedRequest<SphereExploreResponse>(
                getApiHost() + "/v1/sphere/explore",
                config
            )
            .then((result) => {
                onResult(result);
            })
            .catch((err) => {
                onResult(errorResponse);
            });
    };

    private _renameSphere = (
        sphereId: number,
        title: string,
        onResult: (response: CommonResponse) => void
    ) => {
        let requestBody: SphereRenameRequest = {sphere_id: sphereId, title: title};
        let bodyString = JSON.stringify(requestBody);

        let config: RequestInit = {
            method: "post",
            body: bodyString,
        };
        this.networkService
            .signedRequest<CommonResponse>(
                getApiHost() + "/v1/sphere/rename",
                config
            )
            .then((result) => {
                onResult(result);
            })
            .catch((err) => {
                onResult(errorResponse);
            });
    };

    private _updateSphere = (
        sphereId: number,
        content: string,
        onResult: (response: CommonResponse) => void
    ) => {
        let requestBody: SphereUpdateRequest = {sphere_id: sphereId, content: content};
        let bodyString = JSON.stringify(requestBody);

        let config: RequestInit = {
            method: "post",
            body: bodyString,
        };
        this.networkService
            .signedRequest<CommonResponse>(
                getApiHost() + "/v1/sphere/update",
                config
            )
            .then((result) => {
                onResult(result);
            })
            .catch((err) => {
                onResult(errorResponse);
            });
    };

    private _addSphere = (
        title: string,
        content: string,
        onResult: (response: SphereAddResponse) => void
    ) => {
        let requestBody: SphereAddRequest = {title: title, content: content};
        let bodyString = JSON.stringify(requestBody);

        let config: RequestInit = {
            method: "post",
            body: bodyString,
        };
        this.networkService
            .signedRequest<SphereAddResponse>(
                getApiHost() + "/v1/sphere/add",
                config
            )
            .then((result) => {
                onResult(result);
            })
            .catch((err) => {
                onResult(errorResponse);
            });
    }


}
export default SphereApiProvider;
