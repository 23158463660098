import "./PaymentHistory.css";

import {useEffect, useState} from "react";

import IRouter from "../../router/IRouter";

import AuthButton from "../../controls/authButton/AuthButton";
import Page from "../../controls/page/Page";
import PageHeader from "../../controls/pageHeader/PageHeader";
import IAuthService from "../../../services/auth/IAuthService";
import {PaymentItem} from "../../../entities/Payment";

import { Route } from "../../router/routes";
import BackButton from "../../controls/backButton/BackButton";
import PaymentTable from "../../controls/paymentTable/PaymentTable";
import IPaymentService from "../../../services/payment/IPaymentService";

function PaymentHistoryScreen(props: IPaymentHistoryProps) {
    const makeLeave = () => {
        props.authService.leave();
        props.router.reload();
    };
    const showTariffs = () => {
        props.router.goToRoute(Route.ContentTariffs);
    };

    const [isLoadingNow, setIsLoadingNow] = useState<boolean>(false);

    const [payments, setPayments] = useState<PaymentItem[]>([]);

    const updateSpheres = () => {
        setIsLoadingNow(true);
        props.paymentsService.getPaymentList((response) => {
            setIsLoadingNow(false);
            setPayments(response.list || []);
        });
    };

    useEffect(() => {
        updateSpheres();
    }, []);

    return (
        <Page>
            <div className="PaymentHistory-container">
                <PageHeader>
                    <BackButton router={props.router}/>
                    <AuthButton
                        isAuth={props.authService.isSignedIn()}
                        onLeave={makeLeave}
                        onTariffs={showTariffs}
                    />
                </PageHeader>
                <PaymentTable
                    isLoadingNow={isLoadingNow}
                    payments={payments}
                />
            </div>
        </Page>
    );
}

interface IPaymentHistoryProps {
    authService: IAuthService;
    router: IRouter;
    paymentsService: IPaymentService;
}

export default PaymentHistoryScreen;
