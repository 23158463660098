import IAuthService from "./IAuthService";
import IStorage from "../storage/IStorage";
import {leave} from "../api/auth/auth";
import {Header} from "../utils/types/types";
import {CommonResponse, authErrorResponse, getApiHost, makePostConfig, successResponse} from "../utils/network/common";
import IAuthApiProvider from "../api/auth/provider/IAuthApiProvider";
import { RefreshRequest, RefreshResponse } from "../api/auth/entities/entities";
import { request } from "../utils/network/request";

class AuthService implements IAuthService {
    
    // IAuthService

    getAuthHeader(): Header | undefined {
        return this._getAuthHeader();
    }

    leave(): void {
        this._leave();
    }

    isSignedIn() {
        return this._isSignedIn();
    }

    makeHeaders() {
        return this._makeHeaders();
    }

    refreshTokens(): Promise<RefreshResponse> {
        return this._refreshTokens()
    }

    // Setup
    
    onReload?: VoidFunction;
    
    private authApiProvider: IAuthApiProvider;
    private storage: IStorage;

    private refreshQueue: ((response: RefreshResponse) => void)[]
    private isRefreshNow: boolean

    constructor(authApiProvider: IAuthApiProvider, storage: IStorage) {
        this.authApiProvider = authApiProvider
        this.storage = storage;
        
        this.refreshQueue = []
        this.isRefreshNow = false
    }

    // Internal

    private _getAuthHeader = () => {
        if (this.storage.isSignedIn()) {
            return {
                key: "Authorization",
                value: "Bearer " + this.storage.getAccessToken(),
            };
        }
        return undefined;
    };

    private _leave = () => {
        // серверный логаут
        const refreshToken = this.storage.getRefreshToken();
        if (refreshToken) {
            leave(refreshToken);
        }
        // локальный логаут
        this.storage.setAccessToken(undefined);
        this.storage.setRefreshToken(undefined);
        
        this._reload()
    };

    private _isSignedIn = () => {
        return this.storage.isSignedIn();
    };

    private _makeHeaders = () => {
        const authHeader = this._getAuthHeader();
        const requestHeaders: HeadersInit = new Headers();
        requestHeaders.set("Content-Type", "application/json");
        if (authHeader) {
            requestHeaders.set(authHeader.key, authHeader.value);
        }
        return requestHeaders;
    };
    
    private _refresh = async (refreshToken: string) => {
        const requestBody: RefreshRequest = {
            refresh_token: refreshToken,
        };
        const bodyString = JSON.stringify(requestBody);
        const config = makePostConfig(bodyString);
        return request<RefreshResponse>(getApiHost() + "/v1/auth/refresh", config)
    }

    private _refreshTokens() {
        let promise = new Promise<RefreshResponse>((resolve) => {
            this.refreshQueue.push(resolve)
            this._tryRefresh()
        });
        return promise
    }
    
    private _tryRefresh = () =>  {
        if (!this.isRefreshNow) {
            const refreshToken = this.storage.getRefreshToken();
            if (!refreshToken) {
                this._reload()
                this._handleRefreshQueue(false)
                return;
            }

            this.isRefreshNow = true

            this._refresh(refreshToken).then((response) => {
                if (response.success) {
                    this.storage.setAccessToken(response.access_token)
                    this.storage.setRefreshToken(response.refresh_token)
                    this._handleRefreshQueue(true)
                } else {
                    this._handleRefreshQueue(false)
                    this._reload()
                }
            }).catch((err) => {
                this._handleRefreshQueue(false)
                this._reload()
            })
        }
    }

    private _handleRefreshQueue = (success: boolean) => {
        const response: RefreshResponse = { success }
        this.refreshQueue.forEach((request) => {
            request(response)
        })
        this.refreshQueue = []
        this.isRefreshNow = false
    }

    private _reload = () => {
        this.onReload && this.onReload()
    }
}
export default AuthService;
