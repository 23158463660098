import "./FormHeader.css";

import {Button} from "@consta/uikit/Button";
import {IconClose} from "@consta/icons/IconClose";
import {Text} from "@consta/uikit/Text";

function FormHeader({onClose, text}: IFormHeaderProps) {
    return (
        <div className="FormHeader">
            <Text size="xl" view="brand" weight="semibold">
                {text}
            </Text>
            <Button
                className="FormHeader-button"
                size="s"
                view="ghost"
                width="default"
                onlyIcon
                iconRight={IconClose}
                onClick={onClose}
            />
        </div>
    );
}

type IFormHeaderProps = {
    onClose: VoidFunction;
    text: string,
};

export default FormHeader;
