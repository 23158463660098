import "./PageHeader.css";

import {ReactNode} from "react";
import Divider from "../divider/Divider";

function PageHeader({children}: PageHeaderProps) {
    return (
        <div className="PageHeader">
            <div className="PageHeader-container">
                {children}
            </div>
            <Divider />
        </div>
    );
}
type PageHeaderProps = {
    children: ReactNode;
};

export default PageHeader;
