import IPaymentService from "./IPaymentService";
import {
    PaymentAddResponse,
    PaymentListResponse,
} from "../api/payment/entities/entities";
import IPaymentApiProvider from "../api/payment/provider/IPaymentApiProvider";


class PaymentService implements IPaymentService {

    // IPaymentService

    addPayment(
        productId: number,
        onResult: (paymentAddResponse: PaymentAddResponse) => void
    ) {
        this._addPayment(productId, onResult);
    }

    getPaymentList(onResult: (paymentListResponse: PaymentListResponse) => void) {
        this._getPaymentList(onResult);
    }

    // Setup

    private apiProvider: IPaymentApiProvider;

    constructor(apiProvider: IPaymentApiProvider) {
        this.apiProvider = apiProvider;
    }

    // Internal

    private _getPaymentList = (
        onResult: (paymentListResponse: PaymentListResponse) => void
    ) => {
        this.apiProvider.getPaymentList(onResult);
    };

    private _addPayment = (
        productId: number,
        onResult: (paymentAddResponse: PaymentAddResponse) => void
    ) => {
        this.apiProvider.addPayment(productId, onResult);
    };
}

export default PaymentService;
