import "./EditLinkForm.css";

import {useEffect, useRef} from "react";

import {Node} from "../../../entities/Node";
import {NodeType} from "../../../entities/NodeType";
import {TempLink} from "../../../entities/Link";

import {Combobox} from "@consta/uikit/Combobox";
import {Modal} from "@consta/uikit/Modal";

type Item = {
    id: number;
    groupId: number;
    nodeId: string;
    label: string;
};

let counter = 1;
function nodeToItem(node: Node): Item {
    return {
        id: counter++,
        groupId: getGroupId(node.type),
        nodeId: node.id,
        label: node.title,
    };
}

function getGroupId(nodeType: NodeType): number {
    switch (nodeType) {
        case NodeType.Task:
            return 1;
        case NodeType.Group:
            return 2;
        case NodeType.Domain:
            return 3;
        case NodeType.Key:
            return 4;
    }
}

type Group = {
    label: string;
    id: number;
};

const groups: Group[] = [
    // задачи не линкуются
    // {
    //     id: 1,
    //     label: "Задачи",
    // },
    {
        id: 3,
        label: "Домены-листья",
    },
    {
        id: 2,
        label: "Направления-листья",
    },
    {
        id: 4,
        label: "Ключ",
    }
];

function NewLinkForm(props: INewLinkForm) {
    const inputRef = useRef<HTMLInputElement>(null);
    useEffect(() => {
        inputRef.current?.focus();
    }, [props.isModalOpen]);

    const close = () => {
        props.onClose();
    };

    const onSelect = (item: Item | null) => {
        item && props.onSelect({targetNodeId: item.nodeId});
        close();
    };

    return (
        <Modal
            className="NewLinkForm"
            isOpen={props.isModalOpen}
            hasOverlay
            position="center"
            onClickOutside={() => close()}
            onEsc={() => close()}
        >
            <div className="NewLinkForm">
                <Combobox
                    inputRef={inputRef}
                    items={props.nodes.map(nodeToItem)}
                    groups={groups}
                    value={null}
                    onChange={onSelect}
                    placeholder="На что узел влияет?"
                    labelForEmptyItems="Нет доступных узлов"
                    virtualScroll
                    dropdownOpen
                />
            </div>
        </Modal>
    );
}

type INewLinkForm = {
    isModalOpen: boolean;
    nodes: Node[];
    onClose: VoidFunction;
    onSelect: (link: TempLink) => void;
};

export default NewLinkForm;
