import "./ProductForm.css";

import FormHeader from "../../controls/formHeader/FormHeader";

import {Modal} from "@consta/uikit/Modal";
import EmptySpace, {VSpace} from "../../controls/emptySpace/EmptySpace";
import { ProductItem } from "../../../services/api/product/entities/entities";
import ProductCard from "../../content/tariffs/productCard/ProductCard";

function ProductForm(props: IProductFormProps) {

    const clean = () => {
    };

    const close = () => {
        clean();
        props.onClose();
    };

    let hasProducts = props.products && props.products.length

    return (
        <Modal
            isOpen={props.isModalOpen}
            hasOverlay
            position="top"
            onClickOutside={() => close()}
            onEsc={close}
        >
            <div className="ProductForm">
                <FormHeader onClose={close} text="Оплата тарифа" />
                <div className="ProductForm-container">
                    <div className="ProductForm-card-container">
                        {hasProducts && props.products.map(function(item) {
                            return (
                                <ProductCard
                                    id={item.product_id}
                                    title={item.name}
                                    monthPrice={`${item.month_price} ₽/мес`}
                                    price={`${item.price} ₽`}
                                    onActivate={props.onSelect}
                                    key={item.product_id}
                                />
                            )
                        })}
                        {!hasProducts && (
                            <EmptySpace space={VSpace.Space32} />
                        )}
                    </div>
                </div>
            </div>
        </Modal>
    );
}

type IProductFormProps = {
    isModalOpen: boolean;
    products: ProductItem[];
    onClose: VoidFunction;
    onSelect: IntCompletion;
};

export default ProductForm;
