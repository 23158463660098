import React from "react";

function EmptySpace(props: IEmptySpace) {

    const styles: React.CSSProperties = {
        height: props.space
    };

    return <div style={styles}></div>;
}

export enum VSpace {
    Space4 = 4,
    Space8 = 8,
    Space16 = 16,
    Space20 = 20,
    Space24 = 24,
    Space32 = 32,
    Space48 = 48,
}

type IEmptySpace = {
    space: VSpace;
};

export default EmptySpace;
