import "./ContentHeader.css";

import {Text} from "@consta/uikit/Text";

import EmptySpace, { VSpace } from "../emptySpace/EmptySpace";


function ContentHeader(props: IContentHeader) {
    return (
        <div className="ContentHeader">
            <EmptySpace space={VSpace.Space8} />
            <Text size="2xl" view="brand" weight="semibold">
                {props.title}
            </Text>
            <EmptySpace space={VSpace.Space24} />
        </div>
    );
}
type IContentHeader = {
    title: string;
};
export default ContentHeader;
