import ITariffService from "./ITariffService";
import {
    TariffListResponse,
} from "../api/tariff/entities/entities";
import ITariffApiProvider from "../api/tariff/provider/ITariffApiProvider";


class TariffService implements ITariffService {

    // ITariffService

    getTariffList(onResult: (tariffListResponse: TariffListResponse) => void) {
        this._getTariffList(onResult);
    }

    // Setup

    private apiProvider: ITariffApiProvider;

    constructor(apiProvider: ITariffApiProvider) {
        this.apiProvider = apiProvider;
    }

    // Internal

    private _getTariffList = (
        onResult: (tariffListResponse: TariffListResponse) => void
    ) => {
        this.apiProvider.getTariffList(onResult);
    };
}

export default TariffService;
