import "./BackButton.css"

import { Button } from "@consta/uikit/Button";
import {IconBackward} from "@consta/icons/IconBackward";

import IRouter from "../../router/IRouter";

function BackButton(props: IBackButton) {
    return(
        <div className="BackButton">
            <Button
                view="ghost"
                iconRight={IconBackward}
                onlyIcon
                onClick={() => props.router.goBack()}
            />
        </div>
    )
}
type IBackButton = {
    router: IRouter;
}
export default BackButton;