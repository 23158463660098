import "./AuthRegisterForm.css";

import {useEffect, useState} from "react";
import {useDebounce} from "use-debounce";

import {Text} from "@consta/uikit/Text";
import {TextField, TextFieldPropValue} from "@consta/uikit/TextField";

import {authErrorContext, checkName} from "../../../services/api/auth/auth";
import {errorMessageUnknown} from "../../../services/utils/network/errors";
import EmptySpace, { VSpace } from "../../controls/emptySpace/EmptySpace";

function UserNamePage(props: IUserNamePageProps) {
    const userNameCaption1: string = `
        Выберите имя пользователя. Имя будет использоваться
        для входа, а также для связи с другими людьми в мире Афины.
        Можно использовать символы a-z, 0-9 и подчеркивания.
        Минимальная длина имени — 5 символов.
    `;

    const userNameNote1: string = `
        Лучше всего использовать настоящие имя и фамилию. Пример: anna_ivanova
    `;

    const [userName, setUserName] = useState<string | null>(null);
    const handleUsernameChange = (value: TextFieldPropValue) => {
        props.onAvailableUserName(null);
        setUserNameIsOk(null);
        if (userName) {
            setUserNameCaption("Проверяем " + userName + "...");
        } else {
            setUserNameCaption("");
        }
        setUserName(value);
    };
    const [userNameCaption, setUserNameCaption] = useState<string>("");
    const [userNameIsOk, setUserNameIsOk] = useState<boolean | null>(null);
    const [debouncedUserName] = useDebounce(userName, 1000);

    useEffect(() => {
        if (userName) {
            checkName(userName, (response) => {
                if (response.success) {
                    setUserNameIsOk(true);
                    setUserNameCaption("Имя " + userName + " свободно!");
                    props.onAvailableUserName(userName);
                } else {
                    setUserNameIsOk(false);
                    if (response.error?.context === authErrorContext) {
                        setUserNameCaption(response.error?.message);
                    } else {
                        setUserNameCaption(
                            response.error?.message || errorMessageUnknown
                        );
                    }
                }
            });
        }
    }, [debouncedUserName]);

    const getStatus = () => {
        if (userNameIsOk) {
            return "success";
        } else if (userNameIsOk === false) {
            return "alert";
        } else {
            return undefined;
        }
    };

    return (
        <div>
            <TextField
                className="RegisterForm-nameField"
                onChange={handleUsernameChange}
                value={userName || props.initialUserName}
                type="text"
                placeholder="Имя пользователя"
                labelPosition="top"
                caption={userNameCaption}
                status={getStatus()}
                withClearButton
                autoFocus
            />
            <Text
                className="RegisterForm-caption"
                view="primary"
                size="s"
            >
                {userNameCaption1}
            </Text>
            <EmptySpace space={VSpace.Space16} />
            <Text
                className="RegisterForm-note"
                view="brand"
                size="s"
                fontStyle="italic"
            >
                {userNameNote1}
            </Text>
            <EmptySpace space={VSpace.Space16} />
        </div>
    );
}

type IUserNamePageProps = {
    initialUserName: string;
    onAvailableUserName: NullableStringCompletion;
};

export default UserNamePage;
