import "./AuthRegisterForm.css";

import {KeyboardEventHandler, useEffect, useState} from "react";

import FormHeader from "../../controls/formHeader/FormHeader";

import {Modal} from "@consta/uikit/Modal";
import {ProgressStepBar} from "@consta/uikit/ProgressStepBar";
import {Informer} from "@consta/uikit/Informer";

import StepButtons from "../../controls/stepButtons/StepButtons";

import PasswordPage from "./PasswordPage";
import UserNamePage from "./UserNamePage";
import EmailPage from "./EmailPage";
import {TextFieldPropValue} from "@consta/uikit/TextField";
import CodePage from "./CodePage";
import {
    confirmRegister,
    register,
} from "../../../services/api/auth/auth";
import {errorMessageUnknown} from "../../../services/utils/network/errors";
import EmptySpace, {VSpace} from "../../controls/emptySpace/EmptySpace";
import IStorage from "../../../services/storage/IStorage";

function AuthRegisterForm(props: IAuthRegisterFormProps) {
    const clean = () => {
        setUserName("");
        setPassword("");
        setEmail("");
        setActiveStepIndex(0)
        setIsNextActive(false)
        setConfirmationCode(null)
        setConfirmationUuid(null)
        resetError();
    };

    const resetError = () => {
        setIsErrorVisible(false)
        setErrorMessage("")
    } 

    const close = () => {
        clean();
        props.onClose();
    };

    const CodeLength = 6;

    const UserNameStepIndex = 0;
    const PasswordStepIndex = 1;
    const EmailStepIndex = 2;
    const CodeStepIndex = 3;

    const [activeStepIndex, setActiveStepIndex] = useState<number>(0);
    const goNext = () => {
        if (activeStepIndex === EmailStepIndex) {
            makeRegister((success) => {
                if (success) {
                    setActiveStepIndex(activeStepIndex + 1);
                }
            });
        } else if (activeStepIndex === CodeStepIndex) {
            makeConfirmRegister();
        } else {
            setActiveStepIndex(activeStepIndex + 1);
        }
    };
    const goPrev = () => {
        setActiveStepIndex(activeStepIndex - 1);
    };

    const tryGoNext = () => {
        if (isNextActive) {
            goNext();
        }
    };

    const makeRegister = (onFinish: BoolCompletion) => {
        resetError()
        setConfirmationUuid(null);
        if (userName && password && email) {
            setIsNextActive(false);
            register(userName, password, email, (response) => {
                setIsNextActive(true);
                if (response.success) {
                    if (response.confirmation_uuid) {
                        setConfirmationUuid(response.confirmation_uuid);
                        onFinish(true);
                    }
                } else {
                    setErrorMessage(response.error?.message || errorMessageUnknown)
                    setIsErrorVisible(true)
                    onFinish(false);
                }
            });
        }
    };

    const makeConfirmRegister = () => {
        resetError()
        if (confirmationUuid && confirmationCode) {
            setIsNextActive(false);
            confirmRegister(confirmationUuid, confirmationCode, (response) => {
                setIsNextActive(true);
                if (response.success) {
                    props.storage.setAccessToken(response.access_token)
                    props.storage.setRefreshToken(response.refresh_token)
                    close()
                    props.onFinish()
                } else {
                    setErrorMessage(response.error?.message || errorMessageUnknown)
                    setIsErrorVisible(true)
                }
            });
        }
    };

    const handleKeyboard: KeyboardEventHandler = (e) => {
        if (e.key === "Enter") tryGoNext();
    };

    const [userName, setUserName] = useState<string | null>(null);
    const handleUserName = (value: string | null) => {
        setUserName(value);
    };

    const [password, setPassword] = useState<string | null>(null);
    const handlePassword = (value: string | null) => {
        setPassword(value);
    };

    const [email, setEmail] = useState<string | null>(null);
    const handleEmail = (value: TextFieldPropValue) => {
        setEmail(value);
    };

    const [confirmationUuid, setConfirmationUuid] = useState<string | null>(
        null
    );

    const [confirmationCode, setConfirmationCode] = useState<string | null>(
        null
    );
    const handleConfirmationCode = (value: TextFieldPropValue) => {
        setConfirmationCode(value);
    };

    const [isNextActive, setIsNextActive] = useState<boolean>(false);
    useEffect(() => {
        let value: string | null = null;
        if (activeStepIndex === UserNameStepIndex) {
            value = userName;
        }
        if (activeStepIndex === PasswordStepIndex) {
            value = password;
        }
        if (activeStepIndex === EmailStepIndex) {
            value = email;
        }
        if (activeStepIndex === CodeStepIndex) {
            if (confirmationCode && confirmationCode.length === CodeLength) {
                value = confirmationCode;
            }
        }
        if (value) {
            setIsNextActive(true);
        } else {
            setIsNextActive(false);
        }
    }, [userName, password, email, confirmationCode, activeStepIndex]);

    useEffect(() => {
        resetError()
    }, [userName, password, email, confirmationCode]);

    const [isErrorVisible, setIsErrorVisible] = useState<boolean>(false);
    const [errorMessage, setErrorMessage] = useState<string>("");

    type Item = {
        label: string;
        point?: number;
        progress?: boolean;
        status?: "normal" | "success" | "alert" | "warning";
        lineStatus?: "normal" | "success" | "alert" | "warning";
    };

    const steps: Item[] = [
        {
            label: "Имя",
            point: 1,
            status: "normal",
        },
        {
            label: "Пароль",
            point: 2,
            status: "normal",
        },
        {
            label: "E-mail",
            point: 3,
            status: "normal",
        },
        {
            label: "Подтверждение",
            point: 4,
            status: "normal",
        },
    ];

    const userNamePage = () => {
        return (
            <UserNamePage
                initialUserName={userName || ""}
                onAvailableUserName={handleUserName}
            />
        );
    };

    const passwordPage = () => {
        return (
            <PasswordPage
                initialPassword={password || ""}
                onAvailablePassword={handlePassword}
            />
        );
    };

    const emailPage = () => {
        return (
            <EmailPage
                initialEmail={email || ""}
                onAvailableEmail={handleEmail}
            />
        );
    };

    const codePage = () => {
        return (
            <CodePage
                email={email || ""}
                onCodeReceived={handleConfirmationCode}
            />
        );
    };

    const errorView = () => {
        return (
            <div>
                <EmptySpace space={VSpace.Space16} />
                <Informer
                    status="alert"
                    view="filled"
                    title="Что-то пошло не так"
                    label={errorMessage}
                />
                <EmptySpace space={VSpace.Space16} />
            </div>
        );
    };

    return (
        <Modal
            isOpen={props.isModalOpen}
            hasOverlay
            position="top"
            onClickOutside={() => close()}
            onEsc={close}
            onKeyDown={handleKeyboard}
        >
            <div className="RegisterForm">
                <FormHeader onClose={close} text="Регистрация" />
                <div className="RegisterForm-container">
                    <ProgressStepBar
                        className="RegisterForm-steps"
                        steps={steps}
                        activeStepIndex={activeStepIndex}
                        size="s"
                    />
                    {isErrorVisible && errorView()}
                    <div className="RegisterForm-page">
                        {activeStepIndex === UserNameStepIndex &&
                            userNamePage()}
                        {activeStepIndex === PasswordStepIndex &&
                            passwordPage()}
                        {activeStepIndex === EmailStepIndex && emailPage()}
                        {activeStepIndex === CodeStepIndex && codePage()}
                    </div>
                    <StepButtons
                        stepIndex={activeStepIndex}
                        stepCount={steps.length}
                        handleNext={goNext}
                        handlePrev={goPrev}
                        isNextActive={isNextActive}
                        isPrevActive={true}
                        doneText={"Зарегистрироваться"}
                    />
                </div>
            </div>
        </Modal>
    );
}

type IAuthRegisterFormProps = {
    isModalOpen: boolean;
    onClose: VoidFunction;
    onFinish: VoidFunction;
    storage: IStorage;
};

export default AuthRegisterForm;
