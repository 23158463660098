import IProductService from "./IProductService";
import {
    ProductListResponse,
} from "../api/product/entities/entities";
import IProductApiProvider from "../api/product/provider/IProductApiProvider";


class ProductService implements IProductService {

    // IProductService

    getProductList(tariffId: number, onResult: (productListResponse: ProductListResponse) => void) {
        this._getProductList(tariffId, onResult);
    }

    // Setup

    private apiProvider: IProductApiProvider;

    constructor(apiProvider: IProductApiProvider) {
        this.apiProvider = apiProvider;
    }

    // Internal

    private _getProductList = (
        tariffId: number,
        onResult: (productListResponse: ProductListResponse) => void
    ) => {
        this.apiProvider.getProductList(tariffId, onResult);
    };
}

export default ProductService;
