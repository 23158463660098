import {useRef, useState} from "react";

import "./LinkTable.css";

import {Button} from "@consta/uikit/Button";
import {CellClickType, Table, TableColumn} from "@consta/uikit/Table";
import {IconAdd} from "@consta/icons/IconAdd";
import {IconComponent} from "@consta/icons/Icon";
import {IconKebab} from "@consta/icons/IconKebab";
import {IconTrash} from "@consta/icons/IconTrash";
import {TempLink} from "../../../entities/Link";
import {Text, TextPropView} from "@consta/uikit/Text";

import {Node} from "../../../entities/Node";
import {ContextMenu} from "@consta/uikit/ContextMenu";
import NewLinkForm from "../../forms/editLinkForm/EditLinkForm";
import {NodeType} from "../../../entities/NodeType";
import ICore from "../../../services/core/ICore";

export type LinkRow = {
    id: string;
    title: string;
};

function makeColumns(
    linkCount: number,
    getMenuRowId: () => string | undefined,
    getRef: () => React.RefObject<HTMLButtonElement>
): TableColumn<LinkRow>[] {
    return [
        {
            title: "Название узла",
            accessor: "title",
            align: "left",
            sortable: linkCount > 0,
        },
        {
            title: "",
            accessor: "id",
            align: "center",
            width: 60,
            renderCell: (row) => (
                <Button
                    ref={row.id === getMenuRowId() ? getRef() : undefined}
                    size="s"
                    view="clear"
                    label={row.id}
                    onlyIcon
                    iconLeft={IconKebab}
                />
            ),
        },
    ];
}

const emptyText = `Связи пробрасываем к узлам, на которые оказывается влияние.
Необходимо указать как минимум одну связь.
`;

type MenuItem = {
    label: string;
    imageRight?: IconComponent;
    status?: "primary" | "alert";
    onClick?: React.MouseEventHandler;
};

function LinkTable(props: ILinkTable) {
    // =========== ссылки ===========

    const columns = makeColumns(
        props.links.length,
        () => menuRowId,
        () => menuRef
    );

    const onCellClick = ({
        e,
        type,
        rowId,
        columnIdx,
        ref,
    }: {
        e: React.SyntheticEvent;
        type: CellClickType;
        columnIdx: number;
        ref: React.RefObject<HTMLDivElement>;
        rowId?: string;
    }) => {
        const actionsIndex = columns.length - 1;
        if (columnIdx === actionsIndex) {
            rowId && openMenu(rowId);
        } else {
            rowId && setEditingLinkId(rowId);
            openEditLinkModal();
        }
    };

    const linkToRow = (link: TempLink) => {
        let selectedNode = props.nodes.find((node: Node) => {
            return node.id === link.targetNodeId;
        });

        return {
            // ID узла уникален, можно использовать как ID строки в таблице
            id: selectedNode?.id || "ERROR_ID",
            title: selectedNode?.title || "ERROR",
        };
    };

    // =========== удаление ссылок ===========

    const menuRef = useRef(null);
    const [menuRowId, setMenuRowId] = useState<string>("");
    const [menuIsOpen, setMenuIsOpen] = useState<boolean>(false);

    const closeMenu = () => {
        setMenuRowId("");
        setMenuIsOpen(false);
    };

    const openMenu = (rowId: string) => {
        setMenuRowId(rowId);
        setMenuIsOpen(true);
    };

    const items: MenuItem[] = [
        {
            label: "Удалить",
            imageRight: IconTrash,
            status: "alert",
            onClick: () => {
                deleteLink(menuRowId);
                closeMenu();
            },
        },
    ];

    const deleteLink = (id: string) => {
        const result = props.links.filter((link) => link.targetNodeId !== id);
        props.onUpdate(result);
    };

    // =========== добавление ссылок ===========

    const [isAddLinkModalOpen, setIsAddLinkModalOpen] =
        useState<boolean>(false);

    const closeAddLinkModal = () => {
        setIsAddLinkModalOpen(false);
    };

    const openAddLinkModal = () => {
        setIsAddLinkModalOpen(true);
    };

    const addLink = (newLink: TempLink) => {
        const result = [...props.links, newLink];
        props.onUpdate(result);
    };

    // =========== редактирование ссылок ===========

    const [isEditLinkModalOpen, setIsEditLinkModalOpen] =
        useState<boolean>(false);

    const [editingLinkId, setEditingLinkId] = useState<string | null>();

    const closeEditLinkModal = () => {
        setIsEditLinkModalOpen(false);
    };

    const openEditLinkModal = () => {
        setIsEditLinkModalOpen(true);
    };

    const editLink = (newLink: TempLink) => {
        if (editingLinkId) {
            let result = [...props.links];
            for (let index in result) {
                if (result[index].targetNodeId == editingLinkId) {
                    result[index].targetNodeId = newLink.targetNodeId;
                    props.onUpdate(result);
                }
            }
        }
    };

    const getAvailableLinks = () => {
        return props.core.getAvailableLinks(
            props.addingNodeType,
            props.links,
            props.editingNode?.id
        );
    };

    // =========== рендеринг ===========

    return (
        <div className={props.className}>
            <div className="LinkTable-header">
                <Text size="xl" view="brand" weight="semibold">
                    Связи
                </Text>
                <Button
                    className="LinkTable-header-add"
                    size="s"
                    view="primary"
                    width="default"
                    onlyIcon
                    iconRight={IconAdd}
                    onClick={openAddLinkModal}
                    disabled={getAvailableLinks().length === 0}
                />
            </div>
            <Table
                className="LinkTable-table"
                size="m"
                borderBetweenRows
                verticalAlign="center"
                rows={props.links.map(linkToRow)}
                columns={columns}
                onCellClick={onCellClick}
                emptyRowsPlaceholder={
                    <Text
                        className="LinkTable-empty"
                        view={props.emptyTextStyle}
                        size="s"
                    >
                        {emptyText}
                    </Text>
                }
            />
            {menuIsOpen && (
                <ContextMenu
                    isOpen={menuIsOpen}
                    items={items}
                    getItemLabel={(item) => item.label}
                    getItemRightIcon={(item) => item.imageRight}
                    anchorRef={menuRef}
                    onClickOutside={closeMenu}
                />
            )}
            <NewLinkForm
                isModalOpen={isAddLinkModalOpen}
                nodes={getAvailableLinks()}
                onClose={closeAddLinkModal}
                onSelect={addLink}
            />
            <NewLinkForm
                isModalOpen={isEditLinkModalOpen}
                nodes={getAvailableLinks()}
                onClose={closeEditLinkModal}
                onSelect={editLink}
            />
        </div>
    );
}

type ILinkTable = {
    emptyTextStyle: TextPropView;
    className?: string | undefined;
    nodes: Node[];
    links: TempLink[];
    addingNodeType: NodeType;
    editingNode?: Node;
    onUpdate: (links: TempLink[]) => void;
    core: ICore;
};

export default LinkTable;
