export enum BrokenLinkType {
    Cycle = 1,
    Bowstring
}

export function brokenLinkTypeToString(type: BrokenLinkType): string {
    let result: string = "?";
    switch (type) {
        case BrokenLinkType.Cycle:
            result = "Циклическая связь";
            break;
        case BrokenLinkType.Bowstring:
            result = "Избыточная связь";
            break;
        }
        return result
}
