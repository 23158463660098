import INetworkService from "../../../network/INetworkService";
import {
    CommonResponse,
    errorResponse,
    getApiHost,
} from "../../../utils/network/common";
import { ProductListRequest, ProductListResponse, productListResponseError } from "../entities/entities";

import IProductApiProvider from "./IProductApiProvider";

class ProductApiProvider implements IProductApiProvider {

    // IProductApiProvider

    getProductList(
        tariffId: number,
        onResult: (productListResponse: ProductListResponse) => void
    ) {
        this._getProductList(tariffId, onResult);
    }

    // Setup

    private networkService: INetworkService;

    constructor(networkService: INetworkService) {
        this.networkService = networkService;
    }

    // Internal

    private _getProductList = (
        tariffId: number,
        onResult: (productListResponse: ProductListResponse) => void
    ) => {
        let requestBody: ProductListRequest = { tariff_id: tariffId };
        let bodyString = JSON.stringify(requestBody);

        let config: RequestInit = {
            method: "post",
            body: bodyString,
        };

        this.networkService
            .signedRequest<ProductListResponse>(
                getApiHost() + "/v1/product/list",
                config
            )
            .then((result) => {
                onResult(result);
            })
            .catch((err) => {
                onResult(productListResponseError);
            });
    };

}
export default ProductApiProvider;
