import "./TariffCard.css";

import {Button, ButtonPropView} from "@consta/uikit/Button";
import {Card} from "@consta/uikit/Card";
import {Text} from "@consta/uikit/Text";

import EmptySpace, {VSpace} from "../../../controls/emptySpace/EmptySpace";
import {
    TariffAction,
    TariffActionOption,
    TariffActionType,
} from "../../../../services/api/tariff/entities/entities";

function TariffCard(props: ITariffCard) {
    const renderItems = () => {
        var items: JSX.Element[] = [];

        props.items.forEach((element) => {
            items.push(
                <Text
                    className="TariffCard-item"
                    size="l"
                    view="primary"
                    weight="regular"
                    key={items.length}
                >
                    {element}
                </Text>
            );
        });
        return items;
    };

    const activate = () => {
        props.onAction && props.onAction(props.action, props.id);
    };

    const renderButton = () => {
        if (props.action) {
            switch (props.action.type) {
                case TariffActionType.Auth:
                    if (props.isCurrent) {
                        return <Button label="Используется" view="ghost" disabled />;
                    } else {
                        return <Button label={"Активировать"} view={"secondary"} onClick={activate} />;
                    }
                case TariffActionType.Prolong:
                    return <Button label={"Продлить"} view={"primary"} onClick={activate} />;
                case TariffActionType.Switch:
                    return <Button label={"Активировать"} view={"secondary"} onClick={activate} />;
            }
        }
        if (props.isCurrent) {
            return <Button label="Используется" view="ghost" disabled />;
        }
        return <Button label="Недоступно" view="ghost" disabled />;
    };

    return (
        <>
            <Card className="TariffCard" verticalSpace="l" horizontalSpace="l">
                <Text size="2xl" view="brand" weight="semibold">
                    {props.title}
                </Text>
                <EmptySpace space={VSpace.Space16} />
                <Text size="2xl" view="secondary" weight="light">
                    {props.price}
                </Text>
                <EmptySpace space={VSpace.Space24} />
                {renderItems()}
                <EmptySpace space={VSpace.Space24} />
                {renderButton()}
            </Card>
        </>
    );
}
type ITariffCard = {
    id: number;
    title: string;
    price: string;
    items: string[];
    isCurrent: boolean;
    action: TariffAction;
    onAction?: (action: TariffAction, tariffId: number) => void;
};
export default TariffCard;
