import INetworkService from "../../../network/INetworkService";
import {
    CommonResponse,
    errorResponse,
    getApiHost,
} from "../../../utils/network/common";
import { TariffListResponse, tariffListResponseError } from "../entities/entities";

import ITariffApiProvider from "./ITariffApiProvider";

class TariffApiProvider implements ITariffApiProvider {

    // ITariffApiProvider

    getTariffList(onResult: (tariffListResponse: TariffListResponse) => void) {
        this._getTariffList(onResult);
    }

    // Setup

    private networkService: INetworkService;

    constructor(networkService: INetworkService) {
        this.networkService = networkService;
    }

    // Internal

    private _getTariffList = (
        onResult: (tariffListResponse: TariffListResponse) => void
    ) => {
        let requestBody = {};
        let bodyString = JSON.stringify(requestBody);

        let config: RequestInit = {
            method: "post",
            body: bodyString,
        };

        this.networkService
            .signedRequest<TariffListResponse>(
                getApiHost() + "/v1/tariff/list",
                config
            )
            .then((result) => {
                onResult(result);
            })
            .catch((err) => {
                onResult(tariffListResponseError);
            });
    };

}
export default TariffApiProvider;
