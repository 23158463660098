import { CommonError } from "../errors/error";
import { Header } from "../types/types";
import { authError, connectionError } from "./errors";

export function getApiHost() {
    return process.env.REACT_APP_INTEGRATOR_API_HOST
}

export function makePostConfig(body: string) {
    let config: RequestInit = {
        method: "post",
        body: body,
    };
    return config
}

export type CommonResponse = {
    success: boolean;
    error?: CommonError;
};

export const successResponse: CommonResponse = {
    success: true,
};

export const errorResponse: CommonResponse = {
    success: false,
    error: connectionError,
};

export const authErrorResponse: CommonResponse = {
    success: false,
    error: authError,
};
