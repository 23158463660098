import {SphereContent, SphereItem} from "../../../../entities/Sphere";
import {CommonError} from "../../../utils/errors/error";
import {connectionError} from "../../../utils/network/errors";

export type SphereAddRequest = {
    title: string;
    content: string;
};

export type SphereAddResponse = {
    success: boolean;
    error?: CommonError;
    sphere_id?: number;
};

export type SphereDeleteRequest = {
    sphere_id: number;
};

export type SphereListResponse = {
    success: boolean;
    error?: CommonError;
    list: SphereItem[];
};

export type SphereExploreRequest = {
    sphere_id: number;
};

export type SphereExploreResponse = {
    success: boolean;
    error?: CommonError;
    sphere?: SphereContent;
};

export const sphereListResponseError: SphereListResponse = {
    success: false,
    error: connectionError,
    list: [],
};

export type SphereRenameRequest = {
    sphere_id: number;
    title: string;
};


export type SphereUpdateRequest = {
    sphere_id: number;
    content: string;
};
