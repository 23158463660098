import "./PaymentTable.css";

import {useState} from "react";

import {
    SortByProps,
    Table,
    TableColumn,
} from "@consta/uikit/Table";

import {PaymentItem} from "../../../entities/Payment";

import TableHeader from "../tableHeader/TableHeader";
import EmptySpace, {VSpace} from "../emptySpace/EmptySpace";
import TablePlaceholder from "../tablePlaceholder/TablePlaceholder";

type PaymentRow = {
    id: string;
    name: string;
    createdAt: string;
    amount: string;
};

function itemToRow(item: PaymentItem): PaymentRow {
    return {
        id: "" + item.payment_result_id,
        name: item.name,
        createdAt: item.create_time,
        amount: item.amount + " ₽"
    };
}

function makeColumns(): TableColumn<PaymentRow>[] {
    return [
        {
            title: "Дата",
            accessor: "createdAt",
            align: "left",
            sortable: false,
        },
        {
            title: "Сумма",
            accessor: "amount",
            align: "left",
            sortable: false,
        },
        {
            title: "Платеж",
            accessor: "name",
            align: "left",
            sortable: false,
        },
    ];
}

function PaymentTable(props: IPaymentTableProps) {

    const emptyContent = () => {
        return (
            <TablePlaceholder
                isLoading={props.isLoadingNow}
                placeholderText={
                    "Платежей пока нет. Перейдите на страницу с тарифами, чтобы выполнить платеж."
                }
            />
        );
    };

    const [sortSetting, setSortSetting] =
        useState<SortByProps<PaymentRow> | null>(null);

    const rows = () => {
        if (sortSetting?.sortingBy === "name") {
            if (sortSetting?.sortOrder === "asc") {
                return props.payments.map(itemToRow).sort((a, b) => {
                    if (a.name < b.name) {
                        return -1;
                    }
                    if (a.name > b.name) {
                        return 1;
                    }
                    return 0;
                });
            }
            if (sortSetting?.sortOrder === "desc") {
                return props.payments.map(itemToRow).sort((b, a) => {
                    if (a.name < b.name) {
                        return -1;
                    }
                    if (a.name > b.name) {
                        return 1;
                    }
                    return 0;
                });
            }
        }
        return props.payments.map(itemToRow);
    };

    const columns = makeColumns();

    return (
        <div className="PaymentTable">
            <TableHeader
                text={"Платежи"}
            />
            <EmptySpace space={VSpace.Space16} />
            <Table
                className="PaymentTable-table"
                borderBetweenRows
                stickyHeader
                size="m"
                verticalAlign="center"
                rows={rows()}
                columns={columns}
                emptyRowsPlaceholder={emptyContent()}
                onSortBy={setSortSetting}
            />
        </div>
    );
}

type IPaymentTableProps = {
    isLoadingNow: boolean;
    payments: PaymentItem[];
};

export default PaymentTable;
