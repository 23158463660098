import {CommonError} from "../../utils/errors/error";
import {request} from "../../utils/network/request";
import {CommonResponse, errorResponse, getApiHost, makePostConfig} from "../../utils/network/common";
import {connectionError} from "../../utils/network/errors";

export const authErrorContext = "auth";

export type CheckNameRequest = {
    name: string;
};

export function checkName(
    name: string,
    onResult: (response: CommonResponse) => void
) {
    const requestBody: CheckNameRequest = {name: name};
    const bodyString = JSON.stringify(requestBody);
    const config = makePostConfig(bodyString);
    request<CommonResponse>(getApiHost() + "/v1/auth/check-name", config)
        .then((result) => {
            onResult(result);
        })
        .catch((err) => {
            onResult(errorResponse);
        });
}

export type CheckPasswordRequest = {
    password: string;
};

export function checkPassword(
    password: string,
    onResult: (response: CommonResponse) => void
) {
    const requestBody: CheckPasswordRequest = {password: password};
    const bodyString = JSON.stringify(requestBody);
    const config = makePostConfig(bodyString);
    request<CommonResponse>(getApiHost() + "/v1/auth/check-password", config)
        .then((result) => {
            onResult(result);
        })
        .catch((err) => {
            onResult(errorResponse);
        });
}

export type CheckEmailRequest = {
    email: string;
};

export function checkRegisteringEmail(
    email: string,
    onResult: (response: CommonResponse) => void
) {
    const requestBody: CheckEmailRequest = {email: email};
    const bodyString = JSON.stringify(requestBody);
    const config = makePostConfig(bodyString);
    request<CommonResponse>(getApiHost() + "/v1/auth/check-email/register", config)
        .then((result) => {
            onResult(result);
        })
        .catch((err) => {
            onResult(errorResponse);
        });
}

export function checkRestoringEmail(
    email: string,
    onResult: (response: CommonResponse) => void
) {
    const requestBody: CheckEmailRequest = {email: email};
    const bodyString = JSON.stringify(requestBody);
    const config = makePostConfig(bodyString);
    request<CommonResponse>(getApiHost() + "/v1/auth/check-email/restore", config)
        .then((result) => {
            onResult(result);
        })
        .catch((err) => {
            onResult(errorResponse);
        });
}

export type RegisterRequest = {
    name: string;
    password: string;
    email: string;
};

export type RegisterResponse = {
    success: boolean;
    error?: CommonError;
    confirmation_uuid?: string;
};

export function register(
    name: string,
    password: string,
    email: string,
    onResult: (response: RegisterResponse) => void
) {
    const requestBody: RegisterRequest = {
        name: name,
        password: password,
        email: email,
    };
    const bodyString = JSON.stringify(requestBody);
    const config = makePostConfig(bodyString);
    request<RegisterResponse>(getApiHost() + "/v1/auth/register", config)
        .then((result) => {
            onResult(result);
        })
        .catch((err) => {
            const result: RegisterResponse = {
                success: false,
                error: connectionError,
            };
            onResult(result);
        });
}

export type ConfirmRegisterRequest = {
    confirmation_uuid: string;
    confirmation_code: string;
};

export type ConfirmRegisterResponse = {
    success: boolean;
    error?: CommonError;
    access_token?: string;
    refresh_token?: string;
};

export function confirmRegister(
    confirmationUuid: string,
    confirmationCode: string,
    onResult: (response: ConfirmRegisterResponse) => void
) {
    const requestBody: ConfirmRegisterRequest = {
        confirmation_uuid: confirmationUuid,
        confirmation_code: confirmationCode,
    };
    const bodyString = JSON.stringify(requestBody);
    const config = makePostConfig(bodyString);
    request<ConfirmRegisterResponse>(getApiHost() + "/v1/auth/register-confirm", config)
        .then((result) => {
            onResult(result);
        })
        .catch((err) => {
            const result: ConfirmRegisterResponse = {
                success: false,
                error: connectionError,
            };
            onResult(result);
        });
}

export type RestoreRequest = {
    email: string;
    password: string;
};

export type RestoreResponse = {
    success: boolean;
    error?: CommonError;
    confirmation_uuid?: string;
};

export function restore(
    email: string,
    password: string,
    onResult: (response: RestoreResponse) => void
) {
    const requestBody: RestoreRequest = {
        email: email,
        password: password
    };
    const bodyString = JSON.stringify(requestBody);
    const config = makePostConfig(bodyString);
    request<RestoreResponse>(getApiHost() + "/v1/auth/restore", config)
        .then((result) => {
            onResult(result);
        })
        .catch((err) => {
            const result: RestoreResponse = {
                success: false,
                error: connectionError,
            };
            onResult(result);
        });
}

export type ConfirmRestoreRequest = {
    confirmation_uuid: string;
    confirmation_code: string;
};

export type ConfirmRestoreResponse = {
    success: boolean;
    error?: CommonError;
    access_token?: string;
    refresh_token?: string;
};

export function confirmRestore(
    confirmationUuid: string,
    confirmationCode: string,
    onResult: (response: ConfirmRestoreResponse) => void
) {
    const requestBody: ConfirmRestoreRequest = {
        confirmation_uuid: confirmationUuid,
        confirmation_code: confirmationCode,
    };
    const bodyString = JSON.stringify(requestBody);
    const config = makePostConfig(bodyString);
    request<ConfirmRestoreResponse>(getApiHost() + "/v1/auth/restore-confirm", config)
        .then((result) => {
            onResult(result);
        })
        .catch((err) => {
            const result: ConfirmRestoreResponse = {
                success: false,
                error: connectionError,
            };
            onResult(result);
        });
}

export type EnterRequest = {
    name: string;
    password: string;
};

export type EnterResponse = {
    success: boolean;
    error?: CommonError;
    access_token?: string;
    refresh_token?: string;
};

export function enter(
    name: string,
    password: string,
    onResult: (response: EnterResponse) => void
) {
    const requestBody: EnterRequest = {
        name: name,
        password: password,
    };
    const bodyString = JSON.stringify(requestBody);
    const config = makePostConfig(bodyString);
    request<RegisterResponse>(getApiHost() + "/v1/auth/enter", config)
        .then((result) => {
            onResult(result);
        })
        .catch((err) => {
            const result: EnterResponse = {
                success: false,
                error: connectionError,
            };
            onResult(result);
        });
}

export type LeaveRequest = {
    refresh_token: string;
};

export type LeaveResponse = {
    success: boolean;
    error?: CommonError;
};

export function leave(
    refreshToken: string,
    onResult?: (response: LeaveResponse) => void
) {
    const requestBody: LeaveRequest = {
        refresh_token: refreshToken,
    };
    const bodyString = JSON.stringify(requestBody);
    const config = makePostConfig(bodyString);
    request<RegisterResponse>(getApiHost() + "/v1/auth/leave", config)
        .then((result) => {
            onResult && onResult(result);
        })
        .catch((err) => {
            const result: LeaveResponse = {
                success: false,
                error: connectionError,
            };
            onResult && onResult(result);
        });
}
