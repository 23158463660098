import { CommonResponse, errorResponse, getApiHost, makePostConfig, successResponse } from "../../../utils/network/common";
import { request } from "../../../utils/network/request";
import { RefreshRequest, RefreshResponse } from "../entities/entities";
import IAuthApiProvider from "./IAuthApiProvider";

class AuthApiProvider implements IAuthApiProvider {

    // IAuthApiProvider

    refresh(
        refreshToken: string,
        onResult: (response: CommonResponse) => void
    ) {
        this._refresh(refreshToken, onResult)
    }

    // Setup

    constructor() {}

    // Internal

    private _refresh = (
        refreshToken: string,
        onResult: (response: RefreshResponse) => void
    ) => {
        const requestBody: RefreshRequest = {
            refresh_token: refreshToken,
        };
        const bodyString = JSON.stringify(requestBody);
        const config = makePostConfig(bodyString);
        request<RefreshResponse>(getApiHost() + "/v1/auth/refresh", config)
            .then((result) => {
                onResult(result);
            })
            .catch((err) => {
                onResult(errorResponse);
            });
        }

}
export default AuthApiProvider;