import "./AuthRegisterForm.css";

import {useEffect, useState} from "react";
import {useDebounce} from "use-debounce";

import {Text} from "@consta/uikit/Text";
import {TextField, TextFieldPropStatus} from "@consta/uikit/TextField";
import EmptySpace from "../../controls/emptySpace/EmptySpace";
import {VSpace} from "../../controls/emptySpace/EmptySpace";
import { authErrorContext, checkPassword } from "../../../services/api/auth/auth";
import { errorMessageUnknown } from "../../../services/utils/network/errors";

function PasswordPage(props: IPasswordPage) {

    const [password1, setPassword1] = useState<string | null>(props.initialPassword);
    const [password1IsOk, setPassword1IsOk] = useState<boolean | null>(null);
    const [password1Caption, setPassword1Caption] = useState<string>("");
    const [password1Status, setPassword1Status] = useState<
        TextFieldPropStatus | undefined
    >(undefined);
    const [debouncedPassword1] = useDebounce(password1, 500);

    const [password2, setPassword2] = useState<string | null>(props.initialPassword);
    const [password2Caption, setPassword2Caption] = useState<string>("");
    const [password2Status, setPassword2Status] = useState<
        TextFieldPropStatus | undefined
    >(undefined);
    const [debouncedPassword2] = useDebounce(password2, 500);

    // управляет подсказкой первого пароля
    useEffect(() => {
        if (password1) {
            checkPassword(password1, (response) => {
                if (response.success) {
                    setPassword1IsOk(true)
                    setPassword1Caption("");
                    setPassword1Status(undefined);
                } else {
                    setPassword1IsOk(false)
                    setPassword1Status("alert");
                    if (response.error?.context === authErrorContext) {
                        setPassword1Caption(response.error?.message);
                    } else {
                        setPassword1Caption(
                            response.error?.message || errorMessageUnknown
                        );
                    }
                }
            });
        } else {
            setPassword1IsOk(null)
            setPassword1Caption("");
            setPassword1Status(undefined);
        }
    }, [debouncedPassword1]);

    // управляет подсказкой второго пароля
    useEffect(() => {
        let shouldShowAlert = true;
        if (!password2) {
            shouldShowAlert = false;
        } else if (password1 && password2) {
            if (password1 === password2) {
                shouldShowAlert = false;
            }
        }
        if (shouldShowAlert) {
            setPassword2Caption("Пароли не совпадают.");
            setPassword2Status("alert");
        } else {
            setPassword2Caption("");
            setPassword2Status(undefined);
        }
    }, [debouncedPassword1, debouncedPassword2]);

    // управляет доступностью кнопок «вперед/назад»
    useEffect(() => {
        let passwordIsOk = false;
        if (password1 && password2) {
            if (password1 === password2) {
                if (password1IsOk) {
                    passwordIsOk = true;
                }
            }
        }
        if (passwordIsOk) {
            props.onAvailablePassword(password1);
        } else {
            props.onAvailablePassword(null);
        }
    }, [debouncedPassword1, debouncedPassword2, password1IsOk]);

    const passwordCaption1: string = `
        Придумайте надежный пароль для входа. Убедитесь, что длина пароля не
        менее 10 символов, а также что его легко запомнить и трудно подобрать.
    `;

    const passwordCaption2: string = `
        Повторите пароль. Так мы проверяем, что нет опечаток.
    `;

    const passwordNote1: string = `
        1234567890 — плохой пароль, легко подобрать.
    `;

    const passwordNote2: string = `
        #hL9p03$R1 — плохой пароль, трудно запомнить.
    `;

    return (
        <div>
            <TextField
                className="RegisterForm-nameField"
                onChange={setPassword1}
                value={password1 ?? props.initialPassword}
                type="password"
                placeholder="Введите пароль"
                labelPosition="top"
                caption={password1Caption}
                status={password1Status}
                withClearButton
                autoFocus
            />
            <Text className="RegisterForm-caption" view="primary" size="s">
                {passwordCaption1}
            </Text>
            <EmptySpace space={VSpace.Space4} />
            <Text
                className="RegisterForm-note"
                view="brand"
                size="s"
                fontStyle="italic"
            >
                {passwordNote1}
            </Text>
            <Text
                className="RegisterForm-note"
                view="brand"
                size="s"
                fontStyle="italic"
            >
                {passwordNote2}
            </Text>
            <EmptySpace space={VSpace.Space20} />
            <TextField
                className="RegisterForm-nameField"
                onChange={setPassword2}
                value={password2 ?? props.initialPassword}
                type="password"
                placeholder="Повторите пароль"
                labelPosition="top"
                caption={password2Caption}
                status={password2Status}
                withClearButton
            />
            <Text className="RegisterForm-caption" view="primary" size="s">
                {passwordCaption2}
            </Text>
            <EmptySpace space={VSpace.Space16} />
        </div>
    );
}

type IPasswordPage = {
    initialPassword: string;
    onAvailablePassword: NullableStringCompletion;
};

export default PasswordPage;
