import {PaymentItem} from "../../../../entities/Payment";
import {CommonError} from "../../../utils/errors/error";
import {connectionError} from "../../../utils/network/errors";

export type PaymentAddRequest = {
    idempotency_key: string;
    product_id: number;
    return_url: string;
};

export type PaymentAddResponse = {
    success: boolean;
    error?: CommonError;
    confirmation_url?: string;
};

export type PaymentListResponse = {
    success: boolean;
    error?: CommonError;
    list: PaymentItem[];
};

export const paymentListResponseError: PaymentListResponse = {
    success: false,
    error: connectionError,
    list: [],
};

export const paymentAddResponseError: PaymentAddResponse = {
    success: false,
    error: connectionError,
    confirmation_url: undefined
};
