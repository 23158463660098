import {useEffect, useRef, useState} from "react";
import "./Renderer.css";
import * as d3 from "d3";
import {drawScene} from "./DrawCore";
import {Node, cloneNodes, deleteNode} from "../../../entities/Node";
import EmptySphere from "../emptySphere/EmptySphere";
import {SphereMode} from "../../../entities/Sphere";

function ProjectionRenderer({
    nodes,
    onEditNode,
    onAddNode,
    onUpdateNode
}: IProjectionRendererProps) {
    
    const container = useRef<HTMLDivElement>(null);

    const [screenWidth, setScreenWidth] = useState(0);
    const [screenHeight, setScreenHeight] = useState(0);

    useEffect(() => {
        // подписываемся на изменения размера контейнера
        const resizeObserver = new ResizeObserver((event) => {
            const containerWidth = event[0].contentBoxSize[0].inlineSize;
            const containerHeight = event[0].contentBoxSize[0].blockSize;
            if (containerWidth > 0 && containerHeight > 0) {
                setScreenWidth(containerWidth);
                setScreenHeight(containerHeight);
            }
        });
        if (container.current) {
            resizeObserver.observe(container.current);
        }

        // вкладка "проекция". Укажите узлы, которые следует добавить в проекцию

        // перестраиваем SVG согласно размерам контейнера (размеры пока не учитываются)
        const svg = d3.select("#svg");
        if (screenWidth > 0 && screenHeight > 0) {
            drawScene(
                svg,
                screenWidth,
                screenHeight,
                nodes,
                [],
                SphereMode.Projection,
                (nodeId) => {
                    // edit node
                    for (let node of nodes) {
                        if (node.id === nodeId) {
                            onUpdateNode(node.id)
                            break;
                        }
                    }
                },
                (nodeId) => {
                    // no adding
                },
                () => {
                    // no moving
                },
                () => {
                    // no moving
                }
            );
        }

        return () => {
            // очищаем SVG
            svg.selectChildren().remove();
        };
    }, [screenHeight, screenWidth, nodes]);

    const getHeight = () => {
        return screenHeight > 0 ? screenHeight : 600;
    };

    return (
        <div className="Renderer" ref={container}>
            {nodes.length > 0 && (
                <svg
                    id="svg"
                    className="Renderer-svg"
                    style={{height: `${getHeight()}px`}}
                />
            )}
            {nodes.length == 0 && (
                <div className="Renderer-empty">
                    <EmptySphere />
                </div>
            )}
        </div>
    );
}

interface IProjectionRendererProps {
    nodes: Node[];
    onEditNode: (nodeId: string) => void;
    onAddNode: (nodeId: string) => void;
    onUpdateNode: (nodeId: string) => void;
}

export default ProjectionRenderer;
