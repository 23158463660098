import "./Divider.css";

import EmptySpace, { VSpace } from "../emptySpace/EmptySpace";

function Divider() {
    return (
        <div>
            <EmptySpace space={VSpace.Space8} />
            <div className="Divider" />
            <EmptySpace space={VSpace.Space8} />
        </div>
    )
}
type IDividerProps = {
}
export default Divider;