import EmptySpace, { VSpace } from "../../controls/emptySpace/EmptySpace";
import Paragraph from "../../controls/paragraph/Paragraph";

function TermsPage() {
    
    const host = process.env.REACT_APP_EXPLORER_HOST
    
    return (
        <div>
            <Paragraph
                content={`1. ИП Дубовая Анна Александровна (далее — «Оператор») предлагает пользователю сети Интернет,
                а также юридическим лицам и индивидуальным предпринимателям (далее – Клиент) - использовать свои
                сервисы на условиях, изложенных в настоящем соглашении (далее — «Соглашение»). Соглашение вступает
                в силу с момента выражения Клиентом согласия с его условиями в порядке, предусмотренном п. 3 Соглашения.`}
            />
            <Paragraph
                content={`Оператор предлагает Клиентам доступ к широкому спектру сервисов, включая предоставление вычислительной среды и выполнение аналитических расчетов. Все существующие на соответствующий момент времени сервисы, а также любое развитие их и/или добавление новых, являются предметом настоящего Соглашения.`}
            />
            <Paragraph>
                <>
                3. Использование сервисов Оператора регулируется настоящим Соглашением, Политикой конфиденциальности (<a href={`${host}/privacy`}>{host}/privacy</a>), а также условиями использования отдельных сервисов.
                </>
            </Paragraph>
            <Paragraph
                content={`Начиная использовать какой-либо сервис/его отдельные функции либо пройдя процедуру регистрации в соответствии с настоящим Соглашением, Клиент считается принявшим условия Соглашения в полном объеме, без всяких оговорок и исключений.`}
            />
            <Paragraph>
                <>
                3. Оператор вправе предложить Клиенту изменить и/или дополнить настоящее Соглашение путем опубликования Соглашения в новой редакции на Интернет-сайте по адресу <a href={`${host}/terms-of-use`}>{host}/terms-of-use</a>). Принятием Пользователем такого предложения являются конклюдентные действия по использованию любого сервиса Оператора на новых условиях.
                </>
            </Paragraph>
            <Paragraph
                content={`4. В случае несогласия Клиента с изменениями в Соглашение, внесенными в порядке, предусмотренном пунктом 3, он обязан прекратить использование сервисов Оператора.`}
            />
            <Paragraph
                content={`5. Использование Клиентом некоторых сервисов Оператора возможно исключительно при условии успешной регистрации Клиента в целях создания уникальной учетной записи (профиля) Клиента, используемой для авторизации Клиента в сервисах Оператора.`}
            />
            <Paragraph
                content={`Для указанной регистрации Клиент обязуется предоставить достоверную и полную информацию о себе по вопросам, предлагаемым в форме регистрации, включая контактные данные (абонентский номер телефона, обслуживаемый оператором подвижной радиотелефонной связи, и/или адрес электронной почты), и поддерживать эту информацию в актуальном состоянии. Изменение Клиентом контактных данных, указанных в профиле, влечет изменение этих данных во всех используемых Клиентом сервисах Оператора. Если Клиент предоставляет неверную информацию или у Оператора есть основания полагать, что предоставленная Клиентом информация неполна или недостоверна, Оператор имеет право по своему усмотрению отказать ему в использовании своих сервисов (либо их отдельных функций).`}
            />
            <Paragraph
                content={`По итогам успешной регистрации Клиенту создается уникальная учетная запись и формируются авторизационные данные Клиента (созданные Клиентом имя пользователя (логин) и пароль, которые являются основным (применяемым по умолчанию) способом авторизации Клиента в сервисах Оператора. Правилами конкретных сервисов могут быть установлены основания и порядок использования альтернативных и (или) дополнительных авторизационных данных. Оператор вправе запретить использование определенных логинов, а также устанавливать требования к логину и паролю (длина, допустимые символы и т.д.), либо требовать использования дополнительных паролей.`}
            />
            <Paragraph
                content={`Моментом завершения регистрации Клиента является момент направления Оператором в интерфейсе браузера или мобильного приложения Клиенту сообщения об успехе регистрации.`}
            />
            <Paragraph
                content={`Количество профилей, доступных к созданию одному Клиенту, не ограничивается. Принадлежность нескольких профилей одному Клиенту определяется Оператором на основании данных, предоставленных Клиентом при регистрации или в процессе использования профиля, в том числе об абонентском номере телефона, об адресе электронной почты и иных имеющихся в распоряжении Оператора сведений, позволяющих Оператору в установить такой факт.`}
            />
            <Paragraph
                content={`Один адрес электронной почты может использоваться для создания и обслуживания не более чем одного профиля.`}
            />
            <Paragraph
                content={`Один абонентский номер может использоваться для создания и обслуживания не более чем одного профиля.`}
            />
            <Paragraph>
                <>
                Плата за регистрацию Клиента и обслуживание его учетной записи Оператором на тарифах «Гостевой» и «Базовый» не взимается (информация о тарифах Оператора доступна на Интернет-сайте по адресу <a href={`${host}/tariffs`}>{host}/tariffs</a>).
                </>
            </Paragraph>
            <Paragraph
                content={`Клиент самостоятельно несет ответственность за безопасность (устойчивость к подбору) выбранных им средств для доступа к учетной записи, а также самостоятельно обеспечивает их конфиденциальность. Клиент самостоятельно несет ответственность за все действия (а также их последствия) в рамках или с использованием сервисов Оператора под учетной записью Клиента, включая случаи добровольной передачи Клиентом данных для доступа к учетной записи Клиента третьим лицам на любых условиях (в том числе по договорам или соглашениям). При этом все действия в рамках или с использованием сервисов Оператора под учетной записью Клиента считаются произведенными самим Клиентом.`}
            />
            <Paragraph
                content={`6. Успешная регистрация Клиента может предусматривать процедуру акцепта Клиентом условий конкретных сервисов Оператора в соответствии с правилами этих сервисов. Оператор обеспечивают Клиенту возможность ознакомления с правилами соответствующих сервисов до момента завершения процедуры регистрации.`}
            />
            <Paragraph
                content={`Порядок восстановления утраченного доступа Клиента к учетной записи определяется Оператора и может различаться в зависимости от конкретных сервисов Оператора, доступ к которым предоставляется с использованием данной учетной записи.`}
            />
            <Paragraph
                content={`Удаление учетной записи (профиля) Клиента возможно при условии предварительного расторжения всех заключенных между данным Клиентом и Оператором соглашений об использовании конкретных сервисов Оператора, доступ к которым осуществляется с использованием соответствующей учетной записи.`}
            />
            <Paragraph
                content={`6.1. Персональная информация Клиента, предоставленная в процессе использования сервисов, хранится и обрабатывается Оператором в соответствии с условиями Политики конфиденциальности, условиями отдельных сервисов и настоящим Соглашением.`}
            />
            <Paragraph
                content={`7. В случае, если конкретный сервис не предусматривает регистрации Клиента, Оператор вправе сохранять данные пользователя сети Интернет в целях аутентификации его как Клиента на основании, в том числе, данных, предоставленных Клиентом ранее при использовании сервисов Оператора, таких как, в том числе, адрес электронной почты, номер телефона.`}
            />
            <Paragraph
                content={`8. Клиент обязан немедленно уведомить Оператора о любом случае несанкционированного (не разрешенного Клиентом) доступа к сервисам Оператора с использованием учетной записи Клиента и/или о любом нарушении (подозрениях о нарушении) конфиденциальности своих средств доступа к учетной записи. В целях безопасности Клиент обязан самостоятельно осуществлять безопасное завершение работы под своей учетной записью (кнопка «Выход») по окончании каждой сессии работы с сервисами Оператора. В случае, если Клиентом не завершена работа указанным способом, срок актуальности авторизации и период, по истечении которого она сбрасывается автоматически, определяется Оператором.`}
            />
            <Paragraph
                content={`9. Оператор вправе устанавливать ограничения в использовании сервисов для всех Клиентов либо для отдельных категорий Клиентов. Оператор может запретить автоматическое обращение к своим сервисам, а также прекратить прием любой информации, сгенерированной автоматически.`}
            />
            <Paragraph
                content={`10. Оператор вправе направлять Клиентам информационные сообщения, а также, с согласия Клиентов, выражаемого в интерфейсе конкретных сервисов Оператора или иным явно свидетельствующим о волеизъявлении Клиента способом, сообщения рекламного характера. Клиент вправе отказаться от получения сообщений рекламного характера путем использования соответствующего функционала того сервиса, в рамках которого или в связи с которым Клиентом были получены сообщения рекламного характера, или путем прямого обращения в службу поддержки Клиентов.`}
            />
            <Paragraph
                content={`11. Клиент самостоятельно несет ответственность за соответствие содержания размещаемого Клиентом контента в сервисах Оператора требованиям действующего законодательства, включая ответственность перед третьими лицами в случаях, когда размещение Клиентом того или иного контента или содержание контента нарушает права и законные интересы третьих лиц, в том числе личные неимущественные права авторов, иные интеллектуальные права третьих лиц, и/или посягает на принадлежащие им нематериальные блага.`}
            />
            <Paragraph
                content={`12. Клиент самостоятельно несет ответственность перед третьими лицами за свои действия, связанные с использованием сервисов Оператора, в том числе, если такие действия приведут к нарушению прав и законных интересов третьих лиц, а также за соблюдение законодательства при использовании сервисов.`}
            />
            <Paragraph
                content={`13. При использовании сервисов Оператора Клиент не вправе:`}
            />
            <Paragraph
                content={`13.1. нарушать права третьих лиц, в том числе несовершеннолетних лиц, и/или причинять им вред в любой форме;`}
            />
            <Paragraph
                content={`13.2. выдавать себя за другого человека или представителя организации и/или сообщества без достаточных на то прав, в том числе за сотрудников Оператора, за модераторов форумов, за владельца сайта, за представителей партнеров Оператора, а также применять любые другие формы и способы незаконного представительства других лиц в сети, а также вводить пользователей или Оператора в заблуждение относительно свойств и характеристик каких-либо субъектов или объектов;`}
            />
            <Paragraph
                content={`13.3. использовать чужие авторизационные данные, чужие электронные средства платежа;`}
            />
            <Paragraph
                content={`13.4. несанкционированно собирать и хранить персональные данные других лиц;`}
            />
            <Paragraph
                content={`13.5. нарушать нормальную работу веб-сайтов и сервисов Оператора;`}
            />
            <Paragraph
                content={`13.6. совершать действия, направленные на нарушение ограничений и запретов, налагаемых Соглашением;`}
            />
            <Paragraph
                content={`13.7. другим образом нарушать нормы законодательства, в том числе нормы международного права.`}
            />
            <Paragraph
                content={`14. Все объекты, доступные при помощи сервисов Оператора, в том числе элементы дизайна, текст, графические изображения, иллюстрации, видео, программы для ЭВМ, базы данных, музыка, звуки и другие объекты (далее – содержание сервисов), а также любой контент, размещенный на сервисах Оператора, являются объектами исключительных прав Оператора и других правообладателей, предоставивших Оператору соответствующие разрешения.`}
            />
            <Paragraph
                content={`15. Использование каких-либо элементов сервисов возможно только в рамках функциональности, предлагаемой тем или иным сервисом. Никакие элементы содержания сервисов Оператора, а также любой контент, размещенный на сервисах Оператора, не могут быть использованы иным образом без предварительного разрешения правообладателя.`}
            />
            <Paragraph
                content={`16. Сервисы Оператора могут содержать ссылки на другие сайты в сети Интернет (сайты третьих лиц). Указанные третьи лица и их контент не проверяются Оператором на соответствие тем или иным требованиям (достоверности, полноты, законности и т.п.). Оператора не несет ответственность за любую информацию, материалы, размещенные на сайтах третьих лиц, к которым Клиент получает доступ с использованием сервисов, в том числе за любые мнения или утверждения, выраженные на сайтах третьих лиц, рекламу и т.п., а также за доступность таких сайтов или контента и последствия их использования Клиентом.`}
            />
            <Paragraph
                content={`17. Ссылка (в любой форме) на любой сайт, продукт, услугу, любую информацию коммерческого или некоммерческого характера, размещенная в сервисах Оператора, не является одобрением или рекомендацией данных продуктов (услуг, деятельности) со стороны Оператора, за исключением случаев, когда на это прямо указывается на ресурсах Оператора.`}
            />
            <Paragraph
                content={`18. Отсутствие гарантий, ограничение ответственности`}
            />
            <Paragraph
                content={`18.1. Клиент использует сервисы Оператора на свой собственный риск. Сервисы предоставляются «как есть». Оператора не принимает на себя никакой ответственности, в том числе за соответствие сервисов целям Клиента, за исключением случаев, прямо предусмотренных законодательством РФ;`}
            />
            <Paragraph
                content={`18.2. Оператор не гарантирует, что: сервисы соответствуют/будут соответствовать требованиям и ожиданиям Клиента; сервисы будут предоставляться непрерывно, быстро, надежно и без ошибок; результаты, которые могут быть получены с использованием сервисов, будут точными и надежными и могут использоваться для каких-либо целей или в каком-либо качестве; качество какого-либо продукта, услуги, информации и пр., полученных с использованием сервисов, будет соответствовать ожиданиям Клиента;`}
            />
            <Paragraph
                content={`18.3. Оператор не несет ответственности за любые виды убытков, произошедшие вследствие использования Клиентом сервисов Оператора или отдельных частей/функций сервисов, если иное прямо не предусмотрено законодательством РФ;`}
            />
            <Paragraph
                content={`19. Настоящее Соглашение регулируется и толкуется в соответствии с законодательством Российской Федерации. Вопросы, не урегулированные настоящим Соглашением, подлежат разрешению в соответствии с законодательством Российской Федерации. Все возможные споры, вытекающие из отношений, регулируемых настоящим Соглашением, разрешаются в порядке, установленном действующим законодательством Российской Федерации, по нормам российского права.`}
            />
            <Paragraph
                content={`20. Если по тем или иным причинам одно или несколько положений настоящего Соглашения будут признаны недействительными или не имеющими юридической силы, это не оказывает влияния на действительность или применимость остальных положений Соглашения.`}
            />
            <Paragraph
                content={`21. Бездействие со стороны Оператора в случае нарушения Клиентом либо иными Клиентами положений Соглашения не лишает Оператора права предпринять соответствующие действия в защиту своих интересов позднее, а также не означает отказа Оператора от своих прав в случае совершения в последующем подобных либо сходных нарушений.`}
            />
            <EmptySpace space={VSpace.Space32} />
            <Paragraph content={`Дата публикации: 13 мая 2024 года`} />
        </div>
    );
}
export default TermsPage;
