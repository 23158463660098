export enum SphereMode {
    Source = 1,
    Projection
}

export type SphereItem = {
    sphere_id: number;
    title: string;
    create_time: string;
    update_time: string;
};

export type SphereContent = {
    sphere_id: number;
    title: string;
    content: string;
};