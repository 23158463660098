import "./AlertForm.css";

import FormHeader from "../../controls/formHeader/FormHeader";

import {Modal} from "@consta/uikit/Modal";
import EmptySpace, {VSpace} from "../../controls/emptySpace/EmptySpace";
import {Text} from "@consta/uikit/Text";

function AlertForm(props: IAlertFormProps) {

    const clean = () => {
    };

    const close = () => {
        clean();
        props.onClose();
    };

    return (
        <Modal
            isOpen={props.isModalOpen}
            hasOverlay
            position="top"
            onClickOutside={() => close()}
            onEsc={close}
        >
            <div className="AlertForm">
                <FormHeader onClose={close} text={props.title} />
                <div className="AlertForm-container">
                <EmptySpace space={VSpace.Space16} />
                <Text
                    size="l"
                    view="primary"
                    weight="light"
                >
                    {props.text}
                </Text>
                <EmptySpace space={VSpace.Space16} />
                </div>
            </div>
        </Modal>
    );
}

type IAlertFormProps = {
    isModalOpen: boolean;
    text: string; 
    title: string; 
    onClose: VoidFunction;
};

export default AlertForm;
