import IAuthService from "../auth/IAuthService";
import IStorage from "../storage/IStorage";
import { errorAuthFailed, errorDecodingFailed } from "../utils/network/errors";
import INetworkService from "./INetworkService";

class NetworkService implements INetworkService {

    // INetworkService

    signedRequest<TResponse>(
        url: string,
        config: RequestInit
    ): Promise<TResponse> {
        return this._signedRequest(url, config)
        .then((data) => data as TResponse)
    }

    // Setup

    private authService: IAuthService

    constructor(
        authService: IAuthService,
    ) {
        this.authService = authService
    }

    // Internal

    private _signedRequest = async (
        url: string,
        config: RequestInit = {}
    ) => {
        config.headers = this.authService.makeHeaders()
        return (
            await fetch(url, config)
                .then(async (response) => {
                    if (response.status == 403) {
                        return await this.authService.refreshTokens()
                        .then(async (refreshResponse) => {
                            if(refreshResponse.success) {
                                config.headers = this.authService.makeHeaders()
                                return await fetch(url, config)
                            } else {
                                throw new Error(errorAuthFailed);
                            }
                        })
                    }
                    return response
                })
                .then((response) => {
                    if (response) {
                        return response.json()
                    } else {
                        throw new Error(errorDecodingFailed);
                    }
                })
        );
    }    
}
export default NetworkService;