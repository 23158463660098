import {CommonError} from "../../../utils/errors/error";
import {connectionError} from "../../../utils/network/errors";

export type ProductItem = {
    product_id: number;
    name: string;
    description: string;
    month_price: number;
    price: number;
};

export type ProductListRequest = {
    tariff_id: number;
};

export type ProductListResponse = {
    success: boolean;
    error?: CommonError;
    list: ProductItem[];
};

export const productListResponseError: ProductListResponse = {
    success: false,
    error: connectionError,
    list: [],
};
