import "./AuthEnterForm.css";

import {KeyboardEventHandler, useEffect, useState} from "react";

import FormHeader from "../../controls/formHeader/FormHeader";

import {Modal} from "@consta/uikit/Modal";
import {Informer} from "@consta/uikit/Informer";

import {TextField} from "@consta/uikit/TextField";
import { enter } from "../../../services/api/auth/auth";
import {errorMessageUnknown} from "../../../services/utils/network/errors";
import EmptySpace, {VSpace} from "../../controls/emptySpace/EmptySpace";
import { Button } from "@consta/uikit/Button";
import IStorage from "../../../services/storage/IStorage";

function AuthEnterForm(props: IAuthEnterFormProps) {
    const clean = () => {
        setName("");
        setPassword("");
        setIsNextActive(false)
        resetError();
    };

    const resetError = () => {
        setIsErrorVisible(false)
        setErrorMessage("")
    } 

    const close = () => {
        clean();
        props.onClose();
    };

    const makeRegister = () => {
        close()
        props.onRegister()
    }

    const makeAuth = () => {
        resetError()
        if (name && password) {
            setIsNextActive(false);
            enter(name, password, (response) => {
                setIsNextActive(true);
                if (response.success) {
                    props.storage.setAccessToken(response.access_token)
                    props.storage.setRefreshToken(response.refresh_token)
                    close()
                    props.onFinish()
                } else {
                    setErrorMessage(response.error?.message || errorMessageUnknown)
                    setIsErrorVisible(true)
                }
            });
        }
    };

    const [name, setName] = useState<string | null>(null);
    const handleNameChange = (value: string | null) => {
        setName(value);
    };

    const [password, setPassword] = useState<string | null>(null);
    const handlePasswordChange = (value: string | null) => {
        setPassword(value);
    };

    const [isNextActive, setIsNextActive] = useState<boolean>(false);

    useEffect(() => {
        if (name && password) {
            setIsNextActive(true)
        } else {
            setIsNextActive(false)
        }
    }, [name, password]);

    const handleKeyboard: KeyboardEventHandler = (e) => {
        if (e.key === "Enter") tryGoNext();
    };

    const tryGoNext = () => {
        if (isNextActive) {
            makeAuth();
        }
    };

    const [isErrorVisible, setIsErrorVisible] = useState<boolean>(false);
    const [errorMessage, setErrorMessage] = useState<string>("");

    const errorView = () => {
        return (
            <div>
                <EmptySpace space={VSpace.Space16} />
                <Informer
                    status="alert"
                    view="filled"
                    title="Что-то пошло не так"
                    label={errorMessage}
                />
                <EmptySpace space={VSpace.Space16} />
            </div>
        );
    };

    return (
        <Modal
            isOpen={props.isModalOpen}
            hasOverlay
            position="top"
            onClickOutside={() => close()}
            onEsc={close}
            onKeyDown={handleKeyboard}
        >
            <div className="EnterForm">
                <FormHeader onClose={close} text="Вход" />
                <div className="EnterForm-container">
                    {isErrorVisible && errorView()}
                    <TextField
                        onChange={handleNameChange}
                        value={name}
                        type="text"
                        placeholder="Имя пользователя либо e-mail"
                        withClearButton
                        autoFocus
                    />
                    <EmptySpace space={VSpace.Space16} />
                    <TextField
                        onChange={handlePasswordChange}
                        value={password}
                        type="password"
                        placeholder="Пароль"
                        withClearButton
                    />
                    <EmptySpace space={VSpace.Space32} />
                    <div className="EnterForm-buttons">
                        <Button
                            label={"Регистрация"}
                            view="ghost"
                            onClick={makeRegister}
                        />
                        <div></div> {/* обеспечивает привязку кнопок к краям формы  */}
                        <Button
                            label={"Войти"}
                            onClick={makeAuth}
                            disabled={!isNextActive}
                        />
                    </div>


                </div>
            </div>
        </Modal>
    );
}

type IAuthEnterFormProps = {
    isModalOpen: boolean;
    onRegister: VoidFunction;
    onClose: VoidFunction;
    onFinish: VoidFunction;
    storage: IStorage;
};

export default AuthEnterForm;
