import EmptySpace, {VSpace} from "../../controls/emptySpace/EmptySpace";
import Paragraph from "../../controls/paragraph/Paragraph";

function PrivacyPage() {
    const host = process.env.REACT_APP_EXPLORER_HOST;

    return (
        <div>
            <Paragraph
                content={`Afina World серьезно относится к вопросам конфиденциальности и безопасности данных. С целью соблюдения Ваших прав и свобод в области защиты личной информации мы разработали настоящую Политику конфиденциальности. В ней объясняется, на каком основании, как и для каких целей мы обрабатываем персональные данные и ряд иных сведений.`}
            />
            <Paragraph
                content={`Перед началом использования сервисов нашей компании, предоставления нам личной информации, пожалуйста, ознакомьтесь в полном объеме с приведёнными ниже условиями.`}
            />
            <Paragraph>
                <b>
                    Использование наших сервисов, предоставление нам личной
                    информации означает безоговорочное согласие с настоящей
                    Политикой.
                </b>
            </Paragraph>
            <Paragraph content={`1. Общие положения`} />
            <Paragraph
                content={`Этот документ («Политика») определяет основные подходы ИП Дубовая Анна Александровна (далее «Оператор»,  «мы») в отношении обработки персональных данных (в понимании Закона о персональных данных) и иных пользовательских данных (вместе – «Данные»). К сведениям, определяемым в качестве персональных данных в соответствии с Законом о персональных данных, применяются правила, установленные указанным законом.`}
            />
            <Paragraph
                content={`С терминами, использованными в Политике, можно ознакомиться далее в Приложении 1.`}
            />
            <Paragraph
                content={`Каждое лицо, Данные которого обрабатывает Оператор, имеет ряд прав. Подробнее о правах и обязанностях при обработке персональных данных можно узнать далее в Приложении 2.`}
            />
            <Paragraph
                content={`К Политике и обработке нами Данных применяется законодательство Российской Федерации.`}
            />
            <Paragraph content={`2. Цели обработки Данных`} />
            <Paragraph
                content={`Мы обрабатываем Ваши Данные только в определённых целях. Мы обрабатываем только те Данные, которые необходимы для достижения этих целей, и не запрашиваем Данные, которые избыточны с точки зрения достижения конкретных целей.
                `}
            />
            <Paragraph>
                <i>Основные цели:</i>
            </Paragraph>
            <Paragraph
                content={`Заключение/исполнение договоров Вами с Оператором и/или партнёрами Оператора. Предоставление Вам наших услуг и сервисов
                В том числе: подписание договоров на бумаге; регистрация в личных кабинетах; предоставление доступа к личным кабинетам в процессе использования; отражение истории взаимодействия в базах данных Оператора и личных кабинетах наших клиентов.`}
            />
            <Paragraph>
                <i>Оказание информационных услуг</i>
            </Paragraph>
            <Paragraph
                content={`В том числе: предоставление вычислительной среды, выполнение аналитических расчетов; оказание иных информационных услуг, осуществление Вами/для Вас оплаты товаров/работ/услуг с использованием наших сервисов.`}
            />
            <Paragraph>
                <i>Информационный обмен между Вами и Оператором</i>
            </Paragraph>
            <Paragraph
                content={`В том числе: направление Вам уведомлений, запросов, анкет/опросов, предложений, рекламы. Направление иной информации, необходимой по закону и/или для оказания конкретных услуг. Обработка Ваших обращений, запросов, заявлений, анкет/опросов.`}
            />
            <Paragraph>
                <i>Исполнение требований законодательства</i>
            </Paragraph>
            <Paragraph
                content={`В ряде ситуаций обработка Данных необходима для исполнения требований законодательства, для осуществления и выполнения возложенных законодательством на Оператора функций, полномочий и обязанностей. Например, это требуется в рамках судопроизводства; идентификации клиентов, представителей, бенефициаров, выгодоприобретателей; уведомления ФНС о предоставлении электронных средств платежа, открытии счетов; при предоставлении информации о платежах в государственные информационные системы; при предоставлении любых видов отчетности, предусмотренной законодательством; прочее.`}
            />
            <Paragraph>
                <i>Работа над сервисами</i>
            </Paragraph>
            <Paragraph
                content={`В том числе: повышение привлекательности наших сервисов, разработка новых продуктов, приложений, удобство использования сервисов, сайтов, приложений, электронных средств платежа, в том числе, проведение исследований, аналитики.`}
            />
            <Paragraph>
                <i>Организация событий и мероприятий</i>
            </Paragraph>
            <Paragraph
                content={`В том числе: организация маркетинговых, обучающих и иных мероприятий, акций, направление уведомлений о таких событиях, опросов о результатах, сбор предложений и пожеланий.`}
            />
            <Paragraph>
                <i>Безопасность</i>
            </Paragraph>
            <Paragraph
                content={`В том числе: обеспечение информационной и иной безопасности — Вашей, иных клиентов, третьих лиц, Оператора. Выявление противоправных и/или запрещенных действий при использовании сервисов, выявление случаев мошенничества, хищения денежных средств, иных противоправных действий; предотвращение таких противоправных действий в дальнейшем и локализация последствий таких действий; оценка рисков сотрудничества; проверка достоверности предоставленных сведений.`}
            />
            <Paragraph>
                <i>Кадровая работа</i>
            </Paragraph>
            <Paragraph
                content={`В том числе: ведение кадровой работы и организации учёта работников Оператора; регулирование трудовых и иных, непосредственно связанных с ними отношений; привлечение и отбор кандидатов на работу в Операторе.`}
            />
            <Paragraph>
                <i>Административно-хозяйственная деятельность</i>
            </Paragraph>
            <Paragraph
                content={`В том числе: приобретение Ваших товаров/работ/услуг/прав на результаты интеллектуальной деятельности, иная административно-хозяйственная деятельность.`}
            />
            <Paragraph
                content={`3. Перечень Данных`}
            />
            <Paragraph
                content={`Мы можем обрабатывать Данные, перечисленные ниже. Это максимальный перечень*, конкретный объём данных зависит от цели обработки, ситуации, наличия обязательных требований законодательства и отражается в соответствующих документах/интерфейсах.`}
            />
            <Paragraph
                content={`Общие данные:`}
            />
            <Paragraph
                content={`e-mail; номер телефона; логин; ФИО; дата рождения; гражданство; реквизиты документа, удостоверяющего личность; данные документа, подтверждающего право иностранного гражданина или лица без гражданства на пребывание (проживание) в РФ; данные миграционной карты; адрес места жительства (регистрации) или места пребывания; ИНН; СНИЛС; данные полиса ОМС; место рождения; номер электронного средства платежа; информация об операциях; информация о действиях Клиента в сервисах Оператора; информация о получателях/отправителях, не являющихся Клиентами; информация об участии Клиента в программах лояльности, реализуемых Оператором или ее партнёрами; идентификаторы Клиента в базах данных партнёров Оператора; иная информация, предоставляемая Клиентом самостоятельно в процессе коммуникации с Оператором.`}
            />
            <Paragraph
                content={`Техническая информация:`}
            />
            <Paragraph
                content={`информация о действиях, совершаемых на наших сайтах и в мобильных приложениях; статистические, технические, функциональные типы данных (например, тип выполняемого пользователем на сервисе действия, дата и время выполнения действия, URL страницы, тип пользовательского устройства, IP-адрес и т.д.); информация, автоматически передаваемая устройством Клиента в результате технологического взаимодействия с сайтом или мобильным приложением Оператора, в т.ч. сookies**.`}
            />
            <Paragraph
                content={`* Кроме Данных работников Оператора. Перечень Данных Работников определяется законодательством и локальными актами Оператора.`}
            />
            <Paragraph
                content={`** Файлы сookies отражают Ваши действия, помогают нам улучшить наши продукты и сделать Вашу работу на нашем веб-сайте более удобной. Вы можете самостоятельно управлять cookie путем изменения настроек браузера, функционирующего на Вашем оборудовании. Изменения пользовательских настроек могут привести к недоступности отдельных компонентов сайта Оператора на Вашем устройстве.`}
            />
            <Paragraph content={`4. Данные каких лиц мы обрабатываем`} />
            <Paragraph content={`Мы можем обрабатывать Данные:`} />
            <Paragraph content={`∙ Клиентов Оператора, их представителей, бенефициаров и выгодоприобретателей;`} />
            <Paragraph content={`∙ лиц, намеревающихся приобрести услуги Оператора, услуги третьих лиц при посредничестве Оператора;`} />
            <Paragraph content={`∙ лиц, не являющихся Клиентами Оператора, данные которых обрабатываются в целях исполнения требований законодательства или договоров с лицами, предоставляющими такие данные;`} />
            <Paragraph content={`∙ наших Работников или Кандидатов;`} />
            <Paragraph content={`∙ лиц, с которыми Оператор заключил договоры гражданско-правового характера;`} />
            <Paragraph content={`∙ представителей юридических лиц, с которыми у Оператора заключены договоры;`} />
            <Paragraph content={`∙ лиц, входящих в органы управления Оператора;`} />
            <Paragraph content={`∙ лиц, не имеющих с Оператором договорных отношений, но направляющих Оператору обращения, заявления, иную корреспонденцию;`} />
            <Paragraph content={`∙ лиц, участвующих в маркетинговых мероприятиях Оператора;`} />
            <Paragraph content={`∙ иных лиц, 1) выразивших согласие на обработку Оператором их персональных данных или 2) обработка персональных данных которых необходима Оператору для выполнения возложенных законодательством Российской Федерации на Оператора функций, полномочий и обязанностей.`} />
            <Paragraph content={`5. Правовые основания обработки Данных`} />
            <Paragraph content={`Мы обрабатываем Ваши Данные если:`} />
            <Paragraph content={`∙ это предусмотрено/разрешено/допускается Законодательством, или`} />
            <Paragraph content={`∙ это предусмотрено нашим с Вами договором, или`} />
            <Paragraph content={`∙ Вы дали согласие на обработку Данных.`} />
            <Paragraph content={`6. Принципы работы с персональными данными`} />
            <Paragraph content={`∙ законности заранее определенных конкретных целей и способов обработки персональных данных;`} />
            <Paragraph content={`∙ обеспечения надлежащей защиты персональных данных;`} />
            <Paragraph content={`∙ соответствия целей обработки персональных данных целям, заранее определённым и заявленным при их сборе;`} />
            <Paragraph content={`∙ соответствия объёма, характера и способов обработки персональных данных целям обработки;`} />
            <Paragraph content={`∙ достоверности персональных данных, их достаточности для целей обработки, недопустимости обработки персональных данных, избыточных по отношению к целям, заявленным при сборе персональных данных;`} />
            <Paragraph content={`∙ недопустимости объединения баз данных, содержащих персональные данные, обработка которых осуществляется в целях, несовместимых между собой;`} />
            <Paragraph content={`∙ хранения персональных данных в форме, позволяющей определить Субъекта персональных данных, не дольше, чем этого требуют цели их обработки;`} />
            <Paragraph content={`∙ уничтожения или обезличивания персональных данных по достижении целей их обработки, если срок хранения персональных данных не установлен Законодательством, договором, стороной которого, выгодоприобретателем или поручителем по которому является субъект персональных данных;`} />
            <Paragraph content={`∙ обеспечения конфиденциальности и безопасности обрабатываемых персональных данных.`} />
            <Paragraph content={`7. Порядок и условия работы с Данными`} />
            <Paragraph>
                <i>Перечень действий. Способы обработки</i>
            </Paragraph>
            <Paragraph content={`Мы используем автоматизированную и неавтоматизированную обработку персональных данных. С соблюдением требований Законодательства Оператор вправе совершать с персональными данными любые действия по обработке, в том числе сбор, запись, систематизацию, накопление, хранение, уточнение, извлечение, использование, передачу, обезличивание, блокирование, удаление, уничтожение.`} />
            <Paragraph>
                <i>Срок обработки</i>
            </Paragraph>
            <Paragraph content={`Обработка персональных данных прекращается при достижении целей такой обработки, а также по истечении срока, предусмотренного законом, договором, или согласием субъекта персональных данных на обработку его персональных данных.`} />
            <Paragraph>
                <i>Хранение персональных данных</i>
            </Paragraph>
            <Paragraph content={`Оператор использует базы данных, расположенные на территории Российской Федерации.`} />
            <Paragraph>
                <i>Доступ к Данным</i>
            </Paragraph>
            <Paragraph content={`Как правило, Оператор обрабатывает Данные самостоятельно. При предоставлении доступов к Данным внутри Оператора мы исходим из должностных обязанностей сотрудников: наши сотрудники получают доступ только если это необходимо для выполнения рабочей задачи. Третьи лица, которые могут получить доступ к Вашим Данным, перечислены в Приложении 3. Взаимодействуя с нами в описанных там случаях, Вы даёте согласие на обработку/передачу/доступ соответствующих третьих лиц к Вашим Данным.`} />
            <Paragraph>
                <i>Повторное использование Данных</i>
            </Paragraph>
            <Paragraph content={`Вводя любые Данные в одном из сервисов Оператора, Вы даёте согласие на сохранение этих данных для возможности использования их повторно или в иных сервисах Оператора без дополнительного повторного ввода.`} />
            <Paragraph content={`8. Защита персональных данных`} />
            <Paragraph content={`Защита персональных данных — один из наших ключевых приоритетов. В рамках комплексной системы мероприятий мы обеспечиваем:`} />
            <Paragraph content={`(1) Конфиденциальность персональных данных. Мы не раскрываем третьим лицам и не распространяем персональные данные без согласия субъекта персональных данных, если иное не предусмотрено федеральным законом.`} />
            <Paragraph content={`(2) Защиту персональных данных от неправомерных действий. Мы принимаем необходимые правовые, организационные и технические меры для защиты персональных данных от неправомерного или случайного доступа к ним, уничтожения, изменения, блокирования, копирования, распространения, а также от иных неправомерных действий в отношении персональных данных.`} />
            <Paragraph content={`9. Изменения Политики`} />
            <Paragraph>
                <>Политика подлежит опубликованию на сайте <a href="https://afina.world">https://afina.world</a>. Мы вправе внести изменения в Политику. Изменения вносятся по усмотрению Оператора.</>
            </Paragraph>
            <EmptySpace space={VSpace.Space32} />
            <Paragraph content={`Приложение 1. Термины`} />
            <Paragraph content={`Законодательство — законодательство Российской Федерации.`} />
            <Paragraph content={`Закон о персональных данных — Федеральный закон 152-ФЗ от 27.07.2006 года «О персональных данных».`} />
            <Paragraph content={`Персональные данные — любая информация, относящаяся к прямо или косвенно определённому или определяемому физическому лицу (субъекту персональных данных).`} />
            <Paragraph content={`Обработка персональных данных — любое действие (операция) или совокупность действий (операций), совершаемых с использованием средств автоматизации или без использования таких средств с персональными данными, включая сбор, запись, систематизацию, накопление, хранение, уточнение (обновление, изменение), извлечение, использование, передачу (распространение, предоставление, доступ), обезличивание, блокирование, удаление, уничтожение персональных данных.`} />
            <Paragraph content={`Автоматизированная обработка персональных данных — обработка персональных данных с помощью средств вычислительной техники.`} />
            <Paragraph content={`Распространение персональных данных — действия, направленные на раскрытие персональных данных неопределенному кругу лиц.`} />
            <Paragraph content={`Предоставление персональных данных — действия, направленные на раскрытие персональных данных определённому лицу или определённому кругу лиц.`} />
            <Paragraph content={`Блокирование персональных данных — временное прекращение обработки персональных данных (за исключением случаев, если обработка необходима для уточнения персональных данных).`} />
            <Paragraph content={`Уничтожение персональных данных — действия, в результате которых становится невозможным восстановить содержание персональных данных в информационной системе персональных данных и (или) в результате которых уничтожаются материальные носители персональных данных.`} />
            <Paragraph content={`Обезличивание персональных данных — действия, в результате которых становится невозможным без использования дополнительной информации определить принадлежность персональных данных конкретному субъекту персональных данных.`} />
            <Paragraph>
                <>
                Оператор — ИП Дубовая Анна Александровна (Приморский край, Владивосток г, Нейбута ул, д.77), являющееся оператором персональных данных. С реквизитами и контактами Оператора можно ознакомиться на (<a href={`${host}/contacts`}>{host}/contacts</a>).
                </>
            </Paragraph>
            <Paragraph content={`Субъект персональных данных – физическое лицо, которое прямо или косвенно определено с помощью персональных данных.`} />
            <Paragraph content={`Кандидат — физическое лицо, претендующее на вакантную должность в Операторе, персональные данные которого приняты Оператором.`} />
            <Paragraph content={`Работник — физическое лицо, заключившее с Оператором трудовой договор/состоящее с Оператором в трудовых отношениях в настоящее время или в прошлом.`} />
            <Paragraph content={`Клиент — лицо, заключившее с Оператором договор, по которому Оператор оказывает услуги/предоставляет сервисы (в настоящее время или в прошлом), в том числе Представитель Корпоративного клиента;`} />
            <Paragraph content={`Представитель Корпоративного клиента — физическое лицо, персональные данные которого переданы Оператору и`} />
            <Paragraph content={`∙ входящее в органы управления Корпоративного клиента;`} />
            <Paragraph content={`∙ являющееся владельцем/учредителем/акционером/участником Корпоративного клиента;`} />
            <Paragraph content={`∙ действующее от имени Корпоративного клиента на основании доверенности/указанное в карточке с образцами подписей и оттиска печати Корпоративного клиента/имеющее право распоряжаться денежными средствами на банковском счете Корпоративного клиента с использованием аналогов собственноручной подписи, кодов, паролей.`} />
            <Paragraph content={`Корпоративный клиент — юридическое лицо, имеющее намерение использовать, использующее или использовавшее в прошлом услуги/сервисы Оператора.`} />
            <EmptySpace space={VSpace.Space32} />
            <Paragraph content={`Приложение 2. Права и обязанности субъектов персональных данных и Оператора`} />
            <Paragraph content={`1. Основные права и обязанности субъекта персональных данных`} />
            <Paragraph content={`Право на доступ — право субъекта персональных данных на получение информации, касающейся их обработки в порядке, форме и сроки, установленные законодательством о персональных данных.`} />
            <Paragraph content={`Право на исправление, блокирование, уничтожение данных — право требовать уточнения своих персональных данных, их блокирования или уничтожения в случае, если персональные данные:`} />
            <Paragraph content={`∙ являются неполными, устаревшими, недостоверными, незаконно полученными, или`} />
            <Paragraph content={`∙ не являются необходимыми для заявленной цели обработки, или`} />
            <Paragraph content={`∙ используются в целях, не заявленных ранее при предоставлении субъектом персональных данных согласия на обработку персональных данных.`} />
            <Paragraph content={`Право на отзыв — право отозвать свое согласие на обработку персональных данных. В соответствии с Законом о персональных данных в случае отзыва субъектом персональных данных согласия на обработку персональных данных Оператор вправе продолжить обработку персональных данных без согласия субъекта персональных данных при наличии оснований, указанных в Законе о персональных данных.`} />
            <Paragraph content={`Право на защиту — право принимать предусмотренные законом меры по защите своих прав.`} />
            <Paragraph content={`Субъект персональных данных имеет иные права в соответствии с Законодательством.`} />
            <Paragraph content={`Обязанности субъекта персональных данных определяются в соответствии с Законодательством.`} />
            <Paragraph content={`2. Основные права и обязанности Оператора`} />
            <Paragraph content={`Обязанность исполнять Закон о персональных данных — Оператор является лицом, созданным в соответствии с законодательством Российской Федерацией. Действуя в качестве оператора персональных данных, мы обязаны соблюдать Закон о персональных данных.`} />
            <Paragraph content={`Обязанность обеспечивать конфиденциальность персональных данных — Оператор обязан не раскрывать третьим лицам и не распространять персональные данные без согласия субъекта персональных данных, если иное не предусмотрено федеральным законом.`} />
            <Paragraph content={`Обязанность защищать персональные данные — Оператор обязан при обработке персональных данных принимать необходимые правовые, организационные и технические меры/обеспечивать их принятие для защиты персональных данных от неправомерного или случайного доступа к ним, уничтожения, изменения, блокирования, копирования, предоставления, распространения персональных данных, а также от иных неправомерных действий в отношении персональных данных.`} />
            <Paragraph content={`Обязанность предоставлять информацию, касающуюся обработки персональных данных — Оператор обязан в порядке, объеме и по основаниям, установленным Законодательством, предоставлять субъекту персональных данных информацию, касающуюся обработки персональных данных.`} />
            <Paragraph content={`Обязанность локализовать персональные данные граждан России — При сборе персональных данных Оператор обязан обеспечить запись, систематизацию, накопление, хранение, уточнение (обновление, изменение), извлечение персональных данных граждан Российской Федерации с использованием баз данных, находящихся на территории Российской Федерации, за исключением случаев, указанных в Законе о персональных данных.`} />
            <Paragraph content={`Оператор имеет иные обязанности в соответствии с Законодательством.`} />
            <Paragraph content={`Право ограничить доступ субъекта к персональным данным в отдельных случаях — Оператор имеет право ограничить доступ субъекта персональных данных к его персональным данным в случае, если обработка персональных данных осуществляется в соответствии с законодательством о противодействии легализации (отмыванию) доходов, полученных преступным путем, и финансированию терроризма, доступ субъекта персональных данных к его персональным данным нарушает права и законные интересы третьих лиц, а также в иных случаях, предусмотренных Законодательством.`} />
            <Paragraph content={`Право поручить обработку персональных данных другому лицу — Оператор, действуя в качестве оператора персональных данных, вправе с соблюдением Законодательства поручить обработку персональных данных третьему лицу.`} />
            <Paragraph content={`Оператор имеет иные права в соответствии с Законодательством.`} />
            <EmptySpace space={VSpace.Space32} />
            <Paragraph content={`Приложение 3. Третьи лица: участие в обработке, передаче данных`} />
            <Paragraph content={`(1) Мы вправе поручить обработку персональных данных третьим лицам в порядке, установленном Законом о персональных данных. Информация о таком привлечении, информация о партнерах предоставляется клиенту в рамках соглашений с Оператором, регулирующих условия использования соответствующего сервиса/продукта. Например, в рамках сервиса ЮMoney такие партнеры могут привлекаться для прохождения клиентами Идентификации и(или) персонализации/доставки заказанной клиентом банковской карты.`} />
            <Paragraph content={`(2) С соблюдением Законодательства Ваши Данные также могут быть переданы третьим лицам или получены от третьих лиц. К таким третьим лицам могут относиться:`} />
            <Paragraph content={`— в рамках реализации установленных Законодательством процедур/требований – государственные органы, иные уполномоченные организации/лица (например, Банк России, суды, налоговые органы, органы принудительного исполнения судебных актов, органы дознания и следствия и т.д.);`} />
            <Paragraph content={`— при использовании платёжных сервисов Оператор – кредитные и иные организации, участвующие в осуществлении переводов/иных связанных процессах. Например:`} />
            <Paragraph content={`∙ в рамках функционирования сервиса быстрых платежей платежной системы Банка России (СБП) Данные Клиента передаются АО «НСПК» (ОГРН 1147746831352), участникам СБП, а также плательщикам (получателям) по операциям, совершаемым с использованием СБП,`} />
            <Paragraph content={`∙ в целях соблюдения должного уровня безопасности онлайн-платежей, совершаемых с использованием банковских карт, Оператор может получать/передавать сведения, перечень которых устанавливается протоколами безопасности платёжных систем, банкам-эквайерам/-эмитентам, платёжным системам и/или от них;`} />
            <Paragraph content={`— при использовании клиентом сервисов и услуг, предоставляемых партнёрами Оператора, информация о клиенте может предоставляться таким лицам в объеме и в целях, необходимых для надлежащего предоставления сервисов клиенту или повышающих уровень удобства их использования (например, для предзаполнения регистрационных форм, обеспечивающего ускорение процесса прохождения процедуры регистрации в сервисах, предоставляемых партнёрами Оператора);`} />
            <Paragraph content={`— партнёры, обеспечивающие хранение данных;`} />
            <Paragraph content={`— партнёры, привлекаемые Оператором в целях выявления и предотвращения условий, способствовавших использованию сервисов Оператора в противоправных или запрещенных правила соответствующих сервисов целям;`} />
            <Paragraph content={`— Оператор может предоставить третьим лицам доступ к некоторым Данным (например, статистике) для проведения маркетинговых и других исследований, а также к иным Данным, позволяющим обеспечить передачу клиенту рекламы.`} />

            <EmptySpace space={VSpace.Space32} />
            <Paragraph content={`Дата публикации: 13 мая 2024 года`} />
        </div>
    );
}
export default PrivacyPage;
