import "./SphereTable.css";

import {useRef, useState} from "react";

import {Button} from "@consta/uikit/Button";
import {ContextMenu} from "@consta/uikit/ContextMenu";
import {IconComponent} from "@consta/icons/Icon";
import {IconKebab} from "@consta/icons/IconKebab";
import {IconTrash} from "@consta/icons/IconTrash";
import {IconEdit} from "@consta/icons/IconEdit";
import {
    CellClickType,
    SortByProps,
    Table,
    TableColumn,
} from "@consta/uikit/Table";

import {SphereItem} from "../../../entities/Sphere";

import TableHeader from "../tableHeader/TableHeader";
import EmptySpace, {VSpace} from "../emptySpace/EmptySpace";
import TablePlaceholder from "../tablePlaceholder/TablePlaceholder";

type SphereRow = {
    id: string;
    title: string;
};

function itemToRow(item: SphereItem): SphereRow {
    return {
        id: "" + item.sphere_id,
        title: item.title,
    };
}

function makeColumns(
    getMenuRowId: () => string | undefined,
    getRef: () => React.RefObject<HTMLButtonElement>
): TableColumn<SphereRow>[] {
    return [
        {
            title: "Название",
            accessor: "title",
            align: "left",
            sortable: true,
        },
        {
            title: "",
            accessor: "id",
            align: "center",
            width: 60,
            renderCell: (row) => {
                return (
                    <Button
                        ref={row.id === getMenuRowId() ? getRef() : undefined}
                        size="s"
                        view="clear"
                        label={row.id}
                        onlyIcon
                        iconLeft={IconKebab}
                    />
                );
            },
        },
    ];
}

function SphereTable(props: ISphereTableProps) {

    const createNewSphere = "Создать новую сферу";
    const importSphere = "Загрузить сферу из файла";

    const menuRef = useRef(null);
    const [menuRowId, setMenuRowId] = useState<string>("");
    const [menuIsOpen, setMenuIsOpen] = useState<boolean>(false);

    const closeMenu = () => {
        setMenuRowId("");
        setMenuIsOpen(false);
    };

    const openMenu = (rowId: string) => {
        setMenuRowId(rowId);
        setMenuIsOpen(true);
    };

    const columns = makeColumns(
        () => menuRowId,
        () => menuRef
    );

    const onCellClick = ({
        e,
        type,
        rowId,
        columnIdx,
        ref,
    }: {
        e: React.SyntheticEvent;
        type: CellClickType;
        columnIdx: number;
        ref: React.RefObject<HTMLDivElement>;
        rowId?: string;
    }) => {
        const actionsIndex = columns.length - 1;
        if (columnIdx === actionsIndex) {
            rowId && openMenu(rowId);
        } else {
            rowId && props.onEditSphere(+rowId);
        }
    };

    type MenuItem = {
        label: string;
        imageRight?: IconComponent;
        status?: "primary" | "alert";
        onClick?: React.MouseEventHandler;
    };

    const items: MenuItem[] = [
        {
            label: "Удалить",
            imageRight: IconTrash,
            status: "alert",
            onClick: () => {
                props.onDeleteSphere(+menuRowId);
                closeMenu();
            },
        },
        {
            label: "Переименовать",
            imageRight: IconEdit,
            status: "primary",
            onClick: () => {
                props.onRenameSphere(+menuRowId);
                closeMenu();
            },
        },
    ];

    const emptyContent = () => {
        return (
            <TablePlaceholder
                isLoading={props.isLoadingNow}
                placeholderText={
                    "Здесь пока ничего нет. Нажмите плюсик справа, чтобы добавить сферу."
                }
            />
        );
    };

    const [sortSetting, setSortSetting] =
        useState<SortByProps<SphereRow> | null>(null);

    const rows = () => {
        if (sortSetting?.sortingBy === "title") {
            if (sortSetting?.sortOrder === "asc") {
                return props.spheres.map(itemToRow).sort((a, b) => {
                    if (a.title < b.title) {
                        return -1;
                    }
                    if (a.title > b.title) {
                        return 1;
                    }
                    return 0;
                });
            }
            if (sortSetting?.sortOrder === "desc") {
                return props.spheres.map(itemToRow).sort((b, a) => {
                    if (a.title < b.title) {
                        return -1;
                    }
                    if (a.title > b.title) {
                        return 1;
                    }
                    return 0;
                });
            }
        }
        return props.spheres.map(itemToRow);
    };

    const onAdd = (menuItem: string) => {
        if(menuItem === createNewSphere) {
            props.onAddNewSphere()
        }
        if(menuItem === importSphere) {
            props.onImportSphere()
        }
    };

    return (
        <div className="SphereTable">
            <TableHeader
                text={"Сферы"}
                onMenuSelect={onAdd}
                menuItems={[createNewSphere, importSphere]}
            />
            <EmptySpace space={VSpace.Space16} />
            <Table
                className="SphereTable-table"
                borderBetweenRows
                stickyHeader
                size="m"
                verticalAlign="center"
                rows={rows()}
                columns={columns}
                onCellClick={onCellClick}
                emptyRowsPlaceholder={emptyContent()}
                onSortBy={setSortSetting}
            />
            {menuIsOpen && (
                <ContextMenu
                    isOpen={menuIsOpen}
                    items={items}
                    getItemLabel={(item) => item.label}
                    getItemRightIcon={(item) => item.imageRight}
                    anchorRef={menuRef}
                    onClickOutside={closeMenu}
                />
            )}
        </div>
    );
}

type ISphereTableProps = {
    isLoadingNow: boolean;
    spheres: SphereItem[];
    onDeleteSphere: (sphereId: number) => void;
    onEditSphere: (sphereId: number) => void;
    onRenameSphere: (sphereId: number) => void;
    onAddNewSphere: () => void;
    onImportSphere: () => void;
};

export default SphereTable;
