import "./TableHeader.css";

import { useRef, useState } from "react";

import {Button} from "@consta/uikit/Button";
import {IconAdd} from "@consta/icons/IconAdd";

import {Text} from "@consta/uikit/Text";
import {ContextMenu} from "@consta/uikit/ContextMenu";

function TableHeader(props: ITableHeaderProps) {

    const [popoverIsOpen, setPopoverIsOpen] = useState<boolean>(false);
    const ref = useRef(null);
    
    const onItemClick = (item: string) => {
        setPopoverIsOpen(false)
        props.onMenuSelect && props.onMenuSelect(item)
    };

    const onButtonClick = () => {
        if (props.menuItems) {
            setPopoverIsOpen(true)
        } else {
            props.onAdd && props.onAdd()
        }
    }
    return (
        <div className="TableHeader">
            <Text
                className="TableHeader-text"
                size="xl"
                view="brand"
                weight="semibold"
            >
                {props.text}
            </Text>
            {props.menuItems && (
                <Button
                    ref={ref}
                    size="s"
                    view="ghost"
                    width="default"
                    onlyIcon
                    iconRight={IconAdd}
                    onClick={onButtonClick}
                />
            )}
            {props.menuItems && (<ContextMenu
                anchorRef={ref}
                isOpen={popoverIsOpen}
                items={props.menuItems}
                getItemLabel={(item) => item}
                onClickOutside={() => setPopoverIsOpen(false)}
                onEsc={() => setPopoverIsOpen(false)}
                onItemClick={onItemClick}
            />)}
        </div>
    );
}

type ITableHeaderProps = {
    text: string;
    menuItems?: string[];
    onAdd?: VoidFunction;
    onMenuSelect?: (item: string) => void;    
};

export default TableHeader;
