import INetworkService from "../../../network/INetworkService";
import {
    CommonResponse,
    errorResponse,
    getApiHost,
} from "../../../utils/network/common";
import {
    PaymentAddRequest,
    PaymentAddResponse,
    PaymentListResponse,
    paymentListResponseError,
    paymentAddResponseError,
} from "../entities/entities";
import IPaymentApiProvider from "./IPaymentApiProvider";

class PaymentApiProvider implements IPaymentApiProvider {
    // IPaymentApiProvider

    addPayment(
        productId: number,
        onResult: (response: PaymentAddResponse) => void
    ) {
        this._addPayment(productId, onResult);
    }

    getPaymentList(
        onResult: (paymentListResponse: PaymentListResponse) => void
    ) {
        this._getPaymentList(onResult);
    }

    // Setup

    private networkService: INetworkService;

    constructor(networkService: INetworkService) {
        this.networkService = networkService;
    }

    // Internal

    private _addPayment = (
        productId: number,
        onResult: (paymentAddResponse: PaymentAddResponse) => void
    ) => {
        let returnUrl = process.env.REACT_APP_EXPLORER_HOST + "/payments"
        let requestBody: PaymentAddRequest = {
            idempotency_key: new Date().toISOString(),
            product_id: productId,
            return_url: returnUrl,
        };
        let bodyString = JSON.stringify(requestBody);

        let config: RequestInit = {
            method: "post",
            body: bodyString,
        };

        this.networkService
            .signedRequest<PaymentAddResponse>(
                getApiHost() + "/v1/payment/add",
                config
            )
            .then((result) => {
                onResult(result);
            })
            .catch((err) => {
                onResult(paymentAddResponseError);
            });
    };

    private _getPaymentList = (
        onResult: (paymentListResponse: PaymentListResponse) => void
    ) => {
        let requestBody = {};
        let bodyString = JSON.stringify(requestBody);

        let config: RequestInit = {
            method: "post",
            body: bodyString,
        };

        this.networkService
            .signedRequest<PaymentListResponse>(
                getApiHost() + "/v1/payment/list",
                config
            )
            .then((result) => {
                onResult(result);
            })
            .catch((err) => {
                onResult(paymentListResponseError);
            });
    };
}
export default PaymentApiProvider;
