import "./EditNodeForm.css";

import {useEffect, useRef, useState} from "react";

import {Button} from "@consta/uikit/Button";
import {IconTrash} from "@consta/icons/IconTrash";
import {Modal} from "@consta/uikit/Modal";
import {Select} from "@consta/uikit/Select";
import {TextField, TextFieldPropValue} from "@consta/uikit/TextField";

import {NodeType} from "../../../entities/NodeType";
import {NewNode} from "../../../entities/Node";
import LinkTable from "../../controls/linkTable/LinkTable";
import {TempLink} from "../../../entities/Link";
import FormHeader from "../../controls/formHeader/FormHeader";
import IStorage from "../../../services/storage/IStorage";
import ICore, { NodeTypeItem } from "../../../services/core/ICore";
import NodeTypeSelect from "../../controls/nodeTypeSelect/NodeTypeSelect";

type QualitySelection = {
    id: number;
    label: string;
    value: number;
};


function EditNodeForm(props: INewNodeFormProps) {
    

    const editingNode = props.storage.nodes.find(
        (node) => node.id === props.editingNodeId
    );
 
    const qualities: QualitySelection[] = props.core.qualities.map(
        (value: number) => {
            return {
                id: value,
                label: `${value}`,
                value: value,
            };
        }
    );

    const defaultQuality = qualities[0];

    const [title, setTitle] = useState<string>("");
    const handleTitle = (value: TextFieldPropValue) => {
        setTitle(value || "");
    };

    const [nodeType, setNodeType] = useState<NodeType | undefined>();
    const onNodeTypeSelect = (value: NodeType) => {
        if(value !== NodeType.Group) {
            setQualitySelection(defaultQuality)
        }
        setNodeType(value)
    }

    const [qualitySelection, setQualitySelection] = useState(defaultQuality);
    const handleQualitySelection = (value: QualitySelection | null) =>
        setQualitySelection(value || defaultQuality);

    const [links, setLinks] = useState<TempLink[]>([]);

   const availableNodeTypes = () => props.core
        .getAvailableNodeTypes(links)
        .map((item) => item.type);

    const save = () => {
        // TODO: проверки через ядро должны быть
        if (title.length === 0) {
            return;
        }

        var newNodeLinks = [...links];

        nodeType &&
            props.onAddNode({
                title: title,
                type: nodeType,
                quality: qualitySelection.value,
                links: newNodeLinks,
            });
        close();
    };

    const onDelete = () => {
        if (props.editingNodeId) {
            props.onDeleteNode(props.editingNodeId);
        }
        close();
    };

    const close = () => {
        clean();
        props.onClose();
    };

    const clean = () => {
        setTitle("");
        setNodeType(undefined);
        setQualitySelection(defaultQuality);
        setLinks([]);
    };

    const onLinksUpdate = (updatedLinks: TempLink[]) => {
        setLinks(updatedLinks);
    };

    const inputRef = useRef<HTMLInputElement>(null);
    useEffect(() => {
        inputRef.current?.focus();
    }, [props.isModalOpen]);

    useEffect(() => {
        if (editingNode) {
            setTitle(editingNode.title || "");
            setNodeType(editingNode.type);
            setLinks(editingNode.links);
            let quality = editingNode.quality;
            if (quality) {
                setQualitySelection({
                    id: quality,
                    label: `${quality}`,
                    value: quality!,
                });
            }
        } else {
            setNodeType(props.defaultType);
            if (props.linkNodeId) {
                setLinks([{targetNodeId: props.linkNodeId}]);
            } else {
                onLinksUpdate([]);
            }
        }
    }, [props.isModalOpen]);

    let shouldShowQualitySelection = false
    if (nodeType && nodeType === NodeType.Domain) {
        shouldShowQualitySelection = true
        if (editingNode) {
            if (!editingNode.is_leaf) {
                shouldShowQualitySelection = false
            }
        }
    }

    return (
        <Modal
            isOpen={props.isModalOpen}
            hasOverlay
            position="top"
            onClickOutside={() => close()}
            onEsc={() => {
                close();
            }}
        >
            <div className="NewNodeForm">
                <FormHeader onClose={close} text="Параметры узла" />
                <div className="NewNodeForm-content">
                    <TextField
                        inputRef={inputRef}
                        value={title}
                        type="text"
                        placeholder="Укажите название"
                        onChange={handleTitle}
                    />
                    {nodeType &&  (
                        <NodeTypeSelect
                            selected={nodeType}
                            onSelect={onNodeTypeSelect}
                            items={availableNodeTypes()}
                        />
                    )}
                    {shouldShowQualitySelection && (
                        <Select
                            items={qualities}
                            value={qualitySelection}
                            onChange={handleQualitySelection}
                            caption="Коэффициент удовлетворенности доменом"
                        />
                    )}
                    {nodeType && nodeType !== NodeType.Key && (
                        <LinkTable
                            emptyTextStyle={"ghost"}
                            links={links}
                            nodes={props.storage.nodes}
                            onUpdate={onLinksUpdate}
                            editingNode={editingNode}
                            addingNodeType={nodeType}
                            core={props.core}
                        />
                    )}
                </div>
                <div
                    className={
                        props.editingNodeId
                            ? "NewNodeForm-editing-footer"
                            : "NewNodeForm-footer"
                    }
                >
                    {props.editingNodeId && (
                        <Button
                            iconLeft={IconTrash}
                            size="m"
                            view="ghost"
                            label="Удалить узел"
                            width="default"
                            onClick={onDelete}
                        />
                    )}
                    {nodeType && (
                        <Button
                            size="m"
                            view="primary"
                            label="Готово"
                            width="default"
                            onClick={save}
                            disabled={
                                !props.core.isNewNodeCorrect(
                                    title,
                                    nodeType,
                                    links
                                )
                            }
                        />
                    )}
                </div>
            </div>
        </Modal>
    );
}

type INewNodeFormProps = {
    isModalOpen: boolean;
    onAddNode: (node: NewNode) => void;
    onClose: VoidFunction;
    onDeleteNode: (nodeId: string) => void;
    storage: IStorage;
    core: ICore;
    editingNodeId?: string;
    linkNodeId?: string;
    defaultType: NodeType;
};

export default EditNodeForm;
