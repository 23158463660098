import * as d3 from "d3";
import {Node, getScaleFactor, maxQuality, nodeImpactString} from "../../../entities/Node";
import {BrokenLink} from "../../../entities/BrokenLink";

import {NodeType} from "../../../entities/NodeType";
import { SphereMode } from "../../../entities/Sphere";
import { brokenLinkTypeToString } from "../../../entities/BrokenLinkType";

const lineColor = "#72a2c0";
const disabledColor = "#eeeeee";
const longLineColor = "#aaaaaa";
    
const keyColor = "#192e5b";
const domainColor = "#1d65a6";
const groupColor = "#00743f";
const taskColor = "#f2a104";

let errorColor = "#ff0000"
let clearColor = "#ffffff00"

export function drawScene(
    svg: d3.Selection<d3.BaseType, unknown, HTMLElement, any>,
    screenWidth: number,
    screenHeight: number,
    nodes: Node[],
    brokenLinks: BrokenLink[],
    sphereMode: SphereMode,
    onEditNode: (nodeId: string) => void,
    onAddNode: (nodeId: string) => void,
    onMoveNodeAfter: (capturedNodeId: string, afterNodeId: string) => void,
    onMoveNodeBefore: (capturedNodeId: string, beforeNodeId: string) => void
) {

//    console.log("-> ================================================")
//    console.log("Размеры экрана: ", screenWidth, screenHeight)
//    console.log("Узлы: ", nodes)

   // console.log("Распределяем узлы по уровням отрисовки...")

    let nodeTypeLevelGroups: { [type: number] : Node[] | undefined; } = {};
    for (let node of nodes) {
        let group = nodeTypeLevelGroups[node.draw_level] || []
        group.push(node)
        nodeTypeLevelGroups[node.draw_level] = group
    }
   // console.log("Группы узлов по уровням: ", nodeTypeLevelGroups)

    let maxLevel = 0
    let maxGroupLen = 0
    for (const draw_level in nodeTypeLevelGroups) {
        if (nodeTypeLevelGroups.hasOwnProperty(draw_level)) {
           // console.log("Уровень: ", draw_level)
            let group = nodeTypeLevelGroups[draw_level];
            if (group) {
                if (group.length > maxGroupLen) {
                    maxGroupLen = group.length
                }
                for (let node of group) {
                    if (node.draw_level > maxLevel) {
                        maxLevel = node.draw_level
                    }
                   // console.log(node)
                }
            }
        }
    }

    // переносим «бесхозные» узлы вниз
    if (nodeTypeLevelGroups[0]) {
        maxLevel = maxLevel + 1
        nodeTypeLevelGroups[maxLevel] = nodeTypeLevelGroups[0]
        nodeTypeLevelGroups[0] = undefined
    }

    const marginX = 20
    const marginY = 20
    
    const nodeWidth = 180
    const nodeHeight = 80

    const paddingGap = 16;

    const cornerRadius = 8
    const strokeWidth = 2

    
    const slotBetweenX = 20
    const slotBetweenY = 20

    const groupBetweenY = 60

    let slotWidth = nodeWidth + slotBetweenX
    let slotHeight = nodeHeight + slotBetweenY

    let startX = marginX
    let startY = marginY

    let insertRectWidth = 6
    
    // определяем фреймы узлов

    const inLineCount = Math.floor((screenWidth + slotBetweenX) / slotWidth)

    let slots: Slot[] = []
    let groupY = startY
    for (let draw_level in nodeTypeLevelGroups) {
        if (nodeTypeLevelGroups.hasOwnProperty(draw_level)) {
            let group = nodeTypeLevelGroups[draw_level];
            if (group) {

                const inGroupCount = group.length
                const lineCount = Math.ceil(inGroupCount / inLineCount)
                const lastInLineCount = inGroupCount - (lineCount - 1) * inLineCount

                group.forEach((node, index) => {
                    
                    const levelInGroup = Math.ceil((index + 1) / inLineCount) - 1
                    const indexInLevel = index % inLineCount

                    let xGap = 0
                    if (levelInGroup === lineCount - 1) {
                        xGap = (screenWidth - lastInLineCount * slotWidth) / 2
                    } else {
                        xGap = (screenWidth - inLineCount * slotWidth) / 2
                    }
    
                    let frame = {
                            x: startX + xGap + slotWidth * indexInLevel,
                            y: groupY + levelInGroup * slotHeight,
                            width: nodeWidth,
                            height: nodeHeight
                    }
                    slots.push({node_id: node.id, group_id: draw_level, line_id: levelInGroup, frame: frame})
                });
                groupY = groupY + slotHeight * lineCount + groupBetweenY


            }
        }
    }
    
    
    let fieldHeight = groupY + marginY

    svg
    .style("width", screenWidth)
    .style("height", fieldHeight);

    var highlightLayer = svg.append('g');
    var mainLayer = svg.append('g');
    var shadowLayer = svg.append('g');
    var tooltipLayer = svg.append('g');
    
    // монтируем тень для перетаскивания
    
    var shadow = shadowLayer.append("rect")
    .attr("pointer-events", "none")
    .attr("x", -nodeWidth)
    .attr("y", -nodeHeight)  
    .attr("width", nodeWidth)
    .attr("height", nodeHeight)
    .attr("fill", "#ffffffaa")
    .attr("rx", cornerRadius);

    let drugStartX: number | null = null
    let drugStartY: number | null = null

    const outPoint = {x: -nodeWidth, y: -nodeHeight}
    var insertLineTop: Point = outPoint

    var insertRect = mainLayer.append("rect")
    .attr("pointer-events", "none")
    .style("stroke", "#ffffffaa")
    .style("stroke-width",3)
    .attr("x", -nodeWidth)
    .attr("y", -nodeHeight)  
    .attr("width", insertRectWidth)
    .attr("height", nodeHeight)
    .attr("fill", lineColor)
    .attr("rx", 2);

    // var insertLine = mainLayer.append('line')
    // .style("stroke", "#ffffff")
    // .style("stroke-width", 7)
    // .attr('stroke-linecap', 'round')
    
    var capturedSlot: Slot | null = null
    var nearestSlot: Slot | null = null
    var isLeftInsert: boolean = false
    var isRightInsert: boolean = false

    function onDrag(event: d3.D3DragEvent<SVGCircleElement, never, never>) {
        
        const dragGap = 4
        if (drugStartX && drugStartY) {
            if (Math.abs(drugStartX - event.x) > dragGap || Math.abs(drugStartY - event.y) > dragGap) {
                drugStartX = null
                drugStartY = null
                
                // @ts-ignore
                let rect = d3.select(this)
                const x = rect.attr("x")
                const y = rect.attr("y")
                
                shadow
                .attr("x", x)
                .attr("y", y)
                capturedSlot = detectSlot(Number(x), Number(y))
                if (capturedSlot) {
                    const capturedNode = nodes.find(element => element.id === capturedSlot!.node_id)
                    if (capturedNode) {
                        insertRect
                        .attr("fill", getNodeBackColor(capturedNode))
                    }
                }
            }
        }
        
        const shadowX = Number(shadow.attr("x")) + event.dx
        const shadowY = Number(shadow.attr("y")) + event.dy

        shadow
        .attr("x", shadowX)
        .attr("y", shadowY)
        
        const shadowCenterX = shadowX + Number(shadow.attr("width")) / 2
        const shadowCenterY = shadowY + Number(shadow.attr("height")) / 2
    
        const insertResult = detectNearestSlot(shadowCenterX, shadowCenterY)
        nearestSlot = insertResult.nearestSlot
        isRightInsert = insertResult.isRightInsert
        isLeftInsert = insertResult.isLeftInsert
        if (nearestSlot) {
            // отображаем индикатор вставки
            if (isRightInsert) {
                insertLineTop = {
                    x: nearestSlot.frame.x + nearestSlot.frame.width + slotBetweenX / 2 - insertRectWidth / 2,
                    y: nearestSlot.frame.y
                }
            }
            if (isLeftInsert) {
                insertLineTop = {
                    x: nearestSlot.frame.x - slotBetweenX / 2 - insertRectWidth / 2,
                    y: nearestSlot.frame.y
                }
            }
        } else {
            // прячем индикатор вставки за пределы видимой области
            insertLineTop = outPoint
        }

        insertRect
        .attr("x", insertLineTop.x)
        .attr("y", insertLineTop.y)

    }
    function onDragStart(event: d3.D3DragEvent<SVGCircleElement, never, never>) {

        drugStartX = event.x
        drugStartY = event.y

    }
    function onDragEnd(event: d3.D3DragEvent<SVGCircleElement, never, never>) {
        drugStartX = null
        drugStartY = null

        shadow
        .attr("x", -nodeWidth)
        .attr("y", -nodeHeight)
        
        insertLineTop = outPoint

        insertRect
        .attr("x", insertLineTop.x)
        .attr("y", insertLineTop.y)
        

        if (capturedSlot && nearestSlot) {
            if (isRightInsert) {
                onMoveNodeAfter(capturedSlot.node_id, nearestSlot.node_id)
            }
            if (isLeftInsert) {
                onMoveNodeBefore(capturedSlot.node_id, nearestSlot.node_id)
            }
        }

        capturedSlot = null
        nearestSlot = null
    }
          
    let drag = d3.drag()
    drag.on('drag', onDrag)
    drag.on('start', onDragStart)
    drag.on('end', onDragEnd)

    const detectSlot = (x: number, y: number) => {
        
        for (let slot of slots) {
            if (slot.frame.x > x) continue;
            if (slot.frame.x + slot.frame.width < x) continue;
            if (slot.frame.y > y) continue;
            if (slot.frame.y + slot.frame.height < y) continue;
            return slot
        }
        return null
    }

    const detectNearestSlot = (shadowCenterX: number, shadowCenterY: number) => {
        const groupSlots = slots.filter(element => element.group_id === capturedSlot?.group_id)
        for (let slot of groupSlots) {
            if (slot.node_id === capturedSlot?.node_id) continue
            let frame = slot.frame
            if (frame.x > shadowCenterX) continue;
            if (frame.x + frame.width < shadowCenterX) continue;
            if (frame.y > shadowCenterY) continue;
            if (frame.y + frame.height < shadowCenterY) continue;
            var isLeftInsert = false
            var isRightInsert = false
            if (Math.abs(frame.x - shadowCenterX) < Math.abs(frame.x + frame.width - shadowCenterX)) {
                isLeftInsert = true
            } else {
                isRightInsert = true
            }
            const result: InsertResult = {nearestSlot: slot, isLeftInsert: isLeftInsert, isRightInsert: isRightInsert}
            return result
        }
        const result: InsertResult = {nearestSlot: null, isLeftInsert: false, isRightInsert: false}
        return result
}

    // рисуем подложку

    highlightLayer.append("rect")
    .attr("x", 0)
    .attr("y", 0)
    .attr("width", screenWidth)
    .attr("height", fieldHeight)
    .attr("fill", "lightGrey");

    // рисуем соединительные линии

    for (let node of nodes) {
        let srcPoint = getFrameTop(node.id, slots)
        const nodeColor = getNodeBackColor(node)
        for (let link of node.links) {
            let targetPoint = getFrameBottom(link.targetNodeId, slots)
            if (srcPoint && targetPoint) {
                
                highlightLayer.append('line')
                .style("stroke", "lightgrey")
                .style("stroke-width", 2)
                .attr("x1", srcPoint.x)
                .attr("y1", srcPoint.y)
                .attr("x2", targetPoint.x)
                .attr("y2", targetPoint.y);
                
                let lineColor = nodeColor + "66"

                let errorText = "Связь сформирована некорректно"
                for (let nodeItem of nodes) {
                    if (nodeItem.id === link.targetNodeId) {
                        if (node.type === NodeType.Task && nodeItem.is_leaf == false) {
                            errorText = "Задачи можно привязывать\nтолько к направлениям-листьям"
                            lineColor = errorColor
                        }
                        if (node.type === NodeType.Group && nodeItem.type === NodeType.Domain && nodeItem.is_leaf == false) {
                            errorText = "Направления можно привязывать\nтолько к доменам-листьям"
                            lineColor = errorColor
                        }
                        break
                    }
                }

                let line = highlightLayer.append('line')
                .style("stroke", lineColor)
                .style("stroke-width", 2)
                .attr("x1", srcPoint.x)
                .attr("y1", srcPoint.y)
                .attr("x2", targetPoint.x)
                .attr("y2", targetPoint.y);
                if (lineColor === errorColor) {
                    line.style("stroke-dasharray", ("10,3"))

                    const activeArea = 20
                    highlightLayer.append('line')
                    .style("stroke", clearColor)
                    .style("stroke-width", activeArea)
                    .attr("x1", srcPoint.x)
                    .attr("y1", srcPoint.y)
                    .attr("x2", targetPoint.x)
                    .attr("y2", targetPoint.y)
                    .on("mouseover", function (d, i) {
                        // d3.select(this).attr(
                        //     "style",
                        //     `stroke:${errorColor};stroke-width:${activeArea};`
                        // );

                        addMultilineTooltip(tooltipLayer, d.offsetX, d.offsetY, 30, errorText, errorColor)

                    })
                    .on("mouseout", function (d, i) {
                        // d3.select(this).attr(
                        //     "style",
                        //     `stroke:${clearColor};stroke-width:${activeArea};`
                        // );
                        clearTooltips()
                    })
                    .on("click", (event) => {
                        onEditNode(node.id)
                    });



                }

            }
            
            
        }
    }

    for (let item of brokenLinks) {
        let srcPoint = getFrameBottom(item.node_id, slots)
        let targetPoint = getFrameTop(item.target_node_id, slots)
        let title = brokenLinkTypeToString(item.type)
        if (srcPoint && targetPoint) {

            let line = highlightLayer.append('line')
            .style("stroke", errorColor)
            .style("stroke-width", 2)
            .attr("x1", srcPoint.x)
            .attr("y1", srcPoint.y)
            .attr("x2", targetPoint.x)
            .attr("y2", targetPoint.y);
            line.style("stroke-dasharray", ("10,3"))

            const activeArea = 20
            highlightLayer.append('line')
            .style("stroke", clearColor)
            .style("stroke-width", activeArea)
            .attr("x1", srcPoint.x)
            .attr("y1", srcPoint.y)
            .attr("x2", targetPoint.x)
            .attr("y2", targetPoint.y)
            .on("mouseover", function (d, i) {
                // d3.select(this).attr(
                //     "style",
                //     `stroke:${errorColor};stroke-width:${activeArea};`
                // );

                addTooltip(tooltipLayer, d.offsetX, d.offsetY, 30, title)

            })
            .on("mouseout", function (d, i) {
                // d3.select(this).attr(
                //     "style",
                //     `stroke:${clearColor};stroke-width:${activeArea};`
                // );
                clearTooltips()
            })
            .on("click", (event) => {
                onEditNode(item.node_id)
            });


        }
    }



    // рисуем узлы

    for (const draw_level in nodeTypeLevelGroups) {
        if (nodeTypeLevelGroups.hasOwnProperty(draw_level)) {
            let group = nodeTypeLevelGroups[draw_level];
            group && group.forEach((node, index) => {
                
                var nodeColor = getNodeBackColor(node)
                if (node.is_cycled) {
                    nodeColor = errorColor
                }
                        const alphaString = getAlpha(node, nodes);
                const textColor = parseInt(alphaString, 16) >= 127 ? "#ffffff" : nodeColor;
        
                const hasWeight = node.impact > 0;
                const hidden = node.hidden && sphereMode === SphereMode.Projection
        

                let frame = getFrame(node.id, slots)!

                // подложка

                mainLayer.append("rect")
                .attr("x", frame.x)
                .attr("y", frame.y)  
                .attr("width", frame.width)
                .attr("height", frame.height)
                .attr("fill", "white")
                .attr("rx", cornerRadius);
    
                // оттенок
                if (hasWeight && !hidden) {
                    mainLayer.append("rect")
                    .attr("x", frame.x)
                    .attr("y", frame.y)
                    .attr("width", frame.width)
                    .attr("height", frame.height)
                    .attr("fill", nodeColor + alphaString)
                    .attr("stroke", nodeColor)
                    .attr("stroke-width", strokeWidth)
                    .attr("rx", cornerRadius);
                } else {
                    mainLayer.append("rect")
                    .attr("x", frame.x)
                    .attr("y", frame.y)
                    .attr("width", frame.width)
                    .attr("height", frame.height)
                    .attr("fill", disabledColor)
                    .attr("stroke", nodeColor)
                    .attr("stroke-width", strokeWidth)
                    .attr("rx", cornerRadius);
                }
                var lines: Line[]
                if (!hidden) {
                    lines = drawText(
                        mainLayer,
                        node,
                        frame,
                        textColor
                    );
                }
    
                // ловим клики
                mainLayer.append("rect")
                .attr("x", frame.x)
                .attr("y", frame.y)
                .attr("width", frame.width)
                .attr("height", frame.height)
                .attr("fill", "transparent")
                .attr("rx", cornerRadius)
                .on("mousedown", function (d, i) {
                    d3.select(this).attr("style", `fill:${"#ffffffaa"}`);
                })
                .on("click", (event) => {
                    if (event.defaultPrevented) return; // dragged
                    onEditNode(node.id)
                })
                .on("mouseover", function (d, i) {
                    d3.select(this).attr(
                        "style",
                        `stroke:${nodeColor};stroke-width:3;`
                    );
                    if (!capturedSlot) {
                        // подсветка влияния узла
                        drawWeight(node, mainLayer, frame, textColor, nodeWidth, nodeHeight, true)
                        // подсветка исходящих
                        highlightLinks(highlightLayer, node.id, slots, nodes)
                        // подсветка входящих
                        for (let otherNode of nodes) {
                            for (let link of otherNode.links) {
                                if (link.targetNodeId === node.id) {
                                    highlightLink(highlightLayer, otherNode.id, link.targetNodeId, slots, otherNode)
                                }
                            }
                        }
                    }
                    if (node.is_cycled) {
                        addTooltip(tooltipLayer, frame.x + frame.width / 2, frame.y, 30, "Обнаружено зацикливание связей")
                        return
                    }
                    if (lines && lines.length > 0) {
                        let lastLine = lines[lines.length - 1]
                        if (lastLine.words.length > 0) {
                            let lastWord = lastLine.words[lastLine.words.length - 1]
                            if (lastWord.text === dots) {
                                addMultilineTooltip(tooltipLayer, frame.x + frame.width / 2, frame.y + frame.height, -40, node.title, longLineColor)
                                return
                            }
                        }
                    }
                })
                .on("mouseout", function (d, i) {
                    d3.select(this).attr("style", "stroke-width:2");
                    clearHighlightLinks()
                    clearTooltips()
                })
                // @ts-ignore
                .call(drag)


                // влияние

                drawWeight(node, mainLayer, frame, textColor, nodeWidth, nodeHeight, false)

                // ловим точки добавления

                if (node.type !== NodeType.Task) {
                    const addingW = 16;
                    const addingH = 16;

                    // нижняя
                    mainLayer.append("rect")
                        .attr("x", frame.x + nodeWidth / 2 - addingW / 2)
                        .attr("y", frame.y + nodeHeight - addingH / 2)
                        .attr("width", addingW)
                        .attr("height", addingH)
                        .attr("fill", "transparent")
                        .attr("stroke-width", 2)
                        .attr("rx", 8)
                        .on("click", () => onAddNode(node.id))
                        .on("mouseover", function (d, i) {
                            d3.select(this).attr(
                                "style",
                                `fill: ${lineColor};stroke:white;`
                            );
                        })
                        .on("mouseout", function (d, i) {
                            d3.select(this).attr("style", "fill: clear;");
                        });
                }


            });
                
        }
    }

    // рисуем точки на стрелках


    function drawCircle(center: Point, color: string) {
        mainLayer
        .append('circle')
        .attr("pointer-events", "none")
        .attr('cx', center.x)
        .attr('cy', center.y)
        .attr('r', 5)
        .style('fill', "#ffffffaa");

        mainLayer
        .append('circle')
        .attr("pointer-events", "none")
        .attr('cx', center.x)
        .attr('cy', center.y)
        .attr('r', 3)
        .style('fill', color);
}

    for (let node of nodes) {
        let srcPoint = getFrameTop(node.id, slots)
        const nodeColor = getNodeBackColor(node)
        for (let link of node.links) {
            let targetPoint = getFrameBottom(link.targetNodeId, slots)
            if (srcPoint && targetPoint) {
                
                drawCircle(srcPoint, nodeColor)
                drawCircle(targetPoint, nodeColor)

            }
            
            
        }
    }



}

function getFrame(nodeId: string, slots: Slot[]) : Rect | undefined {
    return slots.find((element, index, array) => element.node_id == nodeId)?.frame
}

function getFrameTop(nodeId: string, slots: Slot[]) : Point | undefined {
    let frame = getFrame(nodeId, slots)
    if (frame) {
        return { x: frame.x + frame.width / 2, y: frame.y }
    }
    return undefined
}

function getFrameBottom(nodeId: string, slots: Slot[]) : Point | undefined {
    let frame = getFrame(nodeId, slots)
    if (frame) {
        return { x: frame.x + frame.width / 2, y: frame.y + frame.height }
    }
    return undefined
}

var tooltips: string[] = []
const TOOLTIP = "tooltip"
let tooltipNumber = 0

function clearTooltips() {
    for (let tooltip of tooltips) {
        d3.select("#" + tooltip).remove();
    }
    tooltips = []
}

function makeTooltipId() {
    tooltipNumber = tooltipNumber + 1
    let tooltipId = TOOLTIP + tooltipNumber
    tooltips.push(tooltipId)
    return tooltipId
}


var highLights: string[] = []
const HIGLIGHT = "highlight"

function clearHighlightLinks() {
    for (let highlight of highLights) {
        d3.select("#" + highlight).remove();
    }
    highLights = []
}

let highlightNumber = 0
function highlightLinks(
    layer: d3.Selection<SVGGElement, unknown, HTMLElement, any>,
    nodeId: string,
    slots: Slot[],
    nodes: Node[]
) {
    for (let node of nodes) {
        if (node.id === nodeId) {
            let srcPoint = getFrameTop(node.id, slots)
            const nodeColor = getNodeBackColor(node)
            for (let link of node.links) {
                let targetPoint = getFrameBottom(link.targetNodeId, slots)
                if (srcPoint && targetPoint) {
                    highlightNumber = highlightNumber + 1
                    let highlightId = HIGLIGHT + highlightNumber
                    highLights.push(highlightId)

                    layer.append('line')
                    .attr("id", highlightId)
                    .style("stroke", "#ffffffaa")
                    .style("stroke-width", 6)
                    .attr("x1", srcPoint.x)
                    .attr("y1", srcPoint.y)
                    .attr("x2", targetPoint.x)
                    .attr("y2", targetPoint.y);
        
                    highlightNumber = highlightNumber + 1
                    highlightId = "highlight" + highlightNumber
                    highLights.push(highlightId)

                    layer.append('line')
                    .attr("id", highlightId)
                    .style("stroke", keyColor)
                    .style("stroke-width", 3)
                    .attr("x1", srcPoint.x)
                    .attr("y1", srcPoint.y)
                    .attr("x2", targetPoint.x)
                    .attr("y2", targetPoint.y);
            
                }
            }
        }
    }
}


function highlightLink(
    layer: d3.Selection<SVGGElement, unknown, HTMLElement, any>,
    srcId: string,
    targetId: string,
    slots: Slot[],
    node: Node,
) {
    let srcPoint = getFrameTop(srcId, slots)
    let targetPoint = getFrameBottom(targetId, slots)
    if (srcPoint && targetPoint) {
        const nodeColor = getNodeBackColor(node)
        highlightNumber = highlightNumber + 1
        let highlightId = "highlight" + highlightNumber
        highLights.push(highlightId)

        layer.append('line')
        .attr("id", highlightId)
        .style("stroke", "#ffffffaa")
        .style("stroke-width", 6)
        .attr("x1", srcPoint.x)
        .attr("y1", srcPoint.y)
        .attr("x2", targetPoint.x)
        .attr("y2", targetPoint.y);
        
        highlightNumber = highlightNumber + 1
        highlightId = "highlight" + highlightNumber
        highLights.push(highlightId)

        layer.append('line')
        .attr("id", highlightId)
        .style("stroke", keyColor)
        .style("stroke-width", 3)
        .attr("x1", srcPoint.x)
        .attr("y1", srcPoint.y)
        .attr("x2", targetPoint.x)
        .attr("y2", targetPoint.y);

    }
}


function getAlpha(current: Node, nodes: Node[]): string {
    let groupWeights = nodes
        .filter((node) => node.type === current.type)
        .map((node) => node.impact)
        .sort((a: number, b: number) => b - a);

    let maxWeight;
    if (groupWeights.length > 0) {
        maxWeight = groupWeights[0];
    } else {
        maxWeight = 100;
    }
    let weight = current.impact;
    if (weight > maxWeight) {
        weight = maxWeight;
    }
    weight = Math.round((weight / maxWeight) * 255);
    let result = weight.toString(16);
    if (result.length < 2) {
        result = "0" + result;
    }
    return result;
}

let canvas = document.createElement('canvas');
let context = canvas.getContext('2d');
let dots = "..." 

function makeThreeDots(font: Font): Word {
    function getWidth(text: string) {
        if (context != null) {
            let fontLine = font.weight + ' ' + font.size + ' ' + font.family;
            context.font = fontLine;
            return context.measureText(text).width;
        }
        return -1
    }
    let dotsWord: Word = { text: dots, width: getWidth(dots)}
    return dotsWord
}

function makeWords(title: string, font: Font): Word[] {

    function getWidth(text: string) {
        if (context != null) {
            let fontLine = font.weight + ' ' + font.size + ' ' + font.family;
            context.font = fontLine;
            return context.measureText(text).width;
        }
        return -1
    }
    
    let splitSymbol = " "
    let splitWord: Word = { text: splitSymbol, width: getWidth(splitSymbol)}
    let words = title.split(splitSymbol);
    let result: Word[] = []
    words.map((text: string) => {
        result.push({ text: text, width: getWidth(text)})
        result.push(splitWord)
    })
    return result
}

function makeLines(maxWidth: number, maxLinesCount: number, words: Word[]): Line[] {
    let lines: Line[] = []
    let lineBuffer: Word[] = []
    let lineBufferWidth: number = 0
    let dotsWord = makeThreeDots(textFont)
    if (maxWidth <= dotsWord.width) {
        return [ {words: [dotsWord], width: dotsWord.width} ]
    }

    function flashBuffer() {
        // удаляем пробел в начале
        if (lineBuffer.length > 0) {
            if (lineBuffer[0].text === ' ') {
                lineBuffer.shift()
            }
        } 
        // удаляем пробел в конце
        if (lineBuffer.length > 0) {
            if (lineBuffer[lineBuffer.length - 1].text === ' ') {
                lineBuffer.pop()
            }
        } 
        if (lineBuffer.length > 0) {
            lines.push({words: lineBuffer, width: lineBufferWidth})
        }
        lineBuffer = []
        lineBufferWidth = 0
    }

    words.forEach(word => {
        // случай, когда новое слово влезает в линию
        if (lineBufferWidth + word.width <= maxWidth) {
            lineBufferWidth += word.width
            lineBuffer.push(word)
            return
        }
        // случай одиночного слишком длинного слова
        if (lineBuffer.length == 0) {
            lineBufferWidth += word.width
            lineBuffer.push(word)
            flashBuffer()
            return
        }
        // случай, когда новое слово не влезает в линию
        flashBuffer()
        lineBufferWidth += word.width
        lineBuffer.push(word)
    });
    // формируем последнюю линию
    if (lineBuffer.length > 0) {
        flashBuffer()
    }

    // заменяем в последней линии последние слова троеточием
    if (lines.length > 3) {
        let lastLine = lines[2]
        do {
            let lastElement = lastLine.words.pop()
            if (lastElement) {
                lastLine.width -= lastElement.width
            }
        } while (lastLine.width + dotsWord.width > maxWidth);
        lastLine.words.push(dotsWord)
        lastLine.width += dotsWord.width
    }
    lines = lines.slice(0,3)

    // TODO: если линии выходят за пределы, заменяем выступающую часть слова троеточием
    // в пересчете на отладочный символ (одинаковая ширина-высота): ▢

    return lines
}

function drawTextLine(
    layer: d3.Selection<SVGGElement, unknown, HTMLElement, any>,
    x: number,
    y: number,
    maxWidth: number,
    font: Font,
    textColor: string,
    line: Line
) {
    let wordsCount = line.words.length;
    let xGap: number = 0
    let xCenteringOffset = (maxWidth - line.width) / 2
    for (let i = 0; i < wordsCount; i++) {
        let word = line.words[i]
        layer.append("text")
        .attr("pointer-events", "none")
        .text(word.text || "")
        .attr("x", x + xGap + xCenteringOffset)
        .attr("y", y)
        .attr("font-weight", font.weight)
        .attr("font-family", font.family)
        .attr("font-size", font.size)
        .attr("text-anchor", "left")
        .attr("dominant-baseline", "text-before-edge")
        .attr("fill", textColor);
        xGap += word.width
    }
}

let fontSizeN: number = 18
let textFont: Font = { family: "Arial", size: `${fontSizeN}px`, weight: "bold"}

function drawText(
    layer: d3.Selection<SVGGElement, unknown, HTMLElement, any>,
    node: Node,
    frame: Rect,
    textColor: string
) : Line[] {
    let xOffset = 4
    let maxWidth = frame.width - xOffset * 2

    let text = node.title
    let words  = makeWords(text, textFont)
    let lines = makeLines(maxWidth, 3, words)
    console.log(lines)

    let textGap0 = 0;
    let textGap1 = 0;
    let textGap2 = 0;
    const len = lines.length;
    if (len > 2) {
        textGap0 = 8;
        textGap1 = 10 + fontSizeN;
        textGap2 = 12 + fontSizeN * 2;
    }
    if (len === 2) {
        textGap0 = 16;
        textGap1 = 20 + fontSizeN;
    }
    if (len === 1) {
        textGap0 = 28;
    }

    if (lines[0]) {
        drawTextLine(layer, frame.x + xOffset, frame.y + textGap0, maxWidth, textFont, textColor, lines[0])
    }
    
    if (lines[1]) {
        drawTextLine(layer, frame.x + xOffset, frame.y + textGap1, maxWidth, textFont, textColor, lines[1])
    }
    
    if (lines[2]) {
        drawTextLine(layer, frame.x + xOffset, frame.y + textGap2, maxWidth, textFont, textColor, lines[2])
    }
    return lines
}

type Slot = {
    node_id: string;
    group_id: string;
    line_id: number;
    frame: Rect
};

type InsertResult = {
    nearestSlot: Slot | null;
    isLeftInsert: boolean;
    isRightInsert: boolean;
};

type Rect = {
    x: number;
    y: number;
    width: number;
    height: number;
};

type Point = {
    x: number;
    y: number;
};

type Word = {
    text: string;
    width: number;
};

type Line = {
    words: Word[];
    width: number
};

type Font = {
    family: string
    size: string;
    weight: string;
}

function getNodeBackColor(node: Node): string {
    let nodeColor = lineColor;
    if (node.type === NodeType.Key) {
        nodeColor = keyColor;
    } else if (node.type === NodeType.Domain) {
        nodeColor = domainColor;
    } else if (node.type === NodeType.Group) {
        nodeColor = groupColor;
    } else {
        nodeColor = taskColor;
    }
    // if (node.links.length === 0 && node.type !== NodeType.Key) {
    //     return disabledColor
    // }
    return nodeColor
}

function drawWeight(
    node: Node,
    layer: d3.Selection<SVGGElement, unknown, HTMLElement, any>,
    frame: Rect,
    textColor: string,
    nodeWidth: number,
    nodeHeight: number,
    isHighlightable: boolean
    ) {
    if ((node.is_leaf || isHighlightable || node.type === NodeType.Key) && node.impact) {
        const weightW = 40;
        const weightH = 20;


        let item1 = layer.append("rect")
        .attr("pointer-events", "none")
        .attr("x", frame.x - 12)
        .attr("y", frame.y - 6)
        .attr("width", weightW)
        .attr("height", weightH)
        .attr("rx", 4)
        .attr("stroke", lineColor)
        .attr("stroke-width", 2)
        .attr("fill", "#ffffffdd");
        if (isHighlightable) {
            highlightNumber = highlightNumber + 1
            let highlightId = HIGLIGHT + highlightNumber
            highLights.push(highlightId)
                item1.attr("id", highlightId)
        }

        let item2 = layer.append("text")
        .attr("pointer-events", "none")
        .text(nodeImpactString(node.impact))
        .attr("x", frame.x + weightW / 2 - 12)
        .attr("y", frame.y + weightH / 2 - 5)
        .attr("font-family", "sans-serif")
        .attr("text-anchor", "middle")
        .attr("alignment-baseline", "middle")
        .attr("fill", "rgba(60,60,60,1)");
        if (isHighlightable) {
            highlightNumber = highlightNumber + 1
            let highlightId = HIGLIGHT + highlightNumber
            highLights.push(highlightId)
                item2.attr("id", highlightId)
        }


        let quality = node.complex_quality_factor ? Math.round(node.complex_quality_factor * maxQuality) : node.quality
        const scaleFactor = getScaleFactor(node.impact, quality);
        if (scaleFactor) {
            let item3 = layer.append("text")
                .attr("pointer-events", "none")
                .text(`${scaleFactor}x`)
                .attr("x", frame.x + nodeWidth - 10)
                .attr("y", frame.y + nodeHeight - 12)
                .attr("font-family", "sans-serif")
                .attr("text-anchor", "end")
                .attr("font-size", "0.8em")
                .attr("fill", textColor);
            if (isHighlightable) {
                highlightNumber = highlightNumber + 1
                let highlightId = HIGLIGHT + highlightNumber
                highLights.push(highlightId)
                    item3.attr("id", highlightId)
            }
        }
    }
}

function addTooltip(
    layer: d3.Selection<SVGGElement, unknown, HTMLElement, any>,
    x: number,
    y: number,
    yOffset: number,
    text: string
) {
    
    let tooltip = layer.append("rect")
    .attr("pointer-events", "none")
    .attr("id", makeTooltipId())
    .attr("fill", clearColor)
    .attr("rx", 8)

    let textItem = layer.append("text")
    .attr("pointer-events", "none")
    .attr("id", makeTooltipId())
    .text(text)
    .attr("x", x)
    .attr("y", y - yOffset)
    .attr("text-anchor", "middle")
    .attr("alignment-baseline", "top")
    .attr("font-weight", "bold")
    .attr("font-family", "sans-serif")
    //.attr("font-size", "0.8em")
    .attr("fill", clearColor);

    const textNode = textItem.node()
    if (textNode) {
        const margin = 12
        const box = textNode.getBBox()
        tooltip
        .attr("x", box.x - margin)
        .attr("y", box.y - margin)  
        .attr("width", box.width + margin * 2)
        .attr("height",box.height + margin * 2)
    } 

    tooltip.transition()
    .duration(250)
    .style("fill", errorColor);

    textItem.transition()
    .duration(250)
    .style("fill", "white");

}


function addMultilineTooltip(
    layer: d3.Selection<SVGGElement, unknown, HTMLElement, any>,
    x: number,
    y: number,
    yOffset: number,
    text: string,
    color: string
) {
    const margin = 16
    const betweenY = 4

    // формируем объект подложки
    let tooltip = layer.append("rect")
    .attr("alignment-baseline", "top")
    .attr("pointer-events", "none")
    .attr("id", makeTooltipId())
    .attr("fill", clearColor)
    .attr("rx", 8)

    // разбиваем текст на строки
    const lines = text.split("\n")
    var allHeight = 0
    var maxWidth = 0

    let textItems: d3.Selection<SVGTextElement, unknown, HTMLElement, any>[] = []

    lines.forEach((line, index) => {
        let textItem = layer.append("text")
        .attr("pointer-events", "none")
        .attr("id", makeTooltipId())
        .text(line)
        .attr("x", x)
        .attr("y", y + allHeight - yOffset)
        .attr("text-anchor", "middle")
        .attr("font-weight", "bold")
        .attr("font-family", "sans-serif")
        //.attr("font-size", "0.8em")
        .attr("fill", clearColor);
        textItems.push(textItem)

        const textNode = textItem.node()
        if (textNode) {
            const box = textNode.getBBox()
            allHeight = allHeight + box.height + betweenY
            if (maxWidth < box.width) {
                maxWidth = box.width
            }
        }
    })

    tooltip
    .attr("x", x - margin - maxWidth / 2)
    .attr("y", y - margin - 16 - yOffset) // 16 - поправка на высоту шрифта  
    .attr("width", maxWidth + margin * 2)
    .attr("height", allHeight + margin * 2)

    tooltip.transition()
    .duration(250)
    .style("fill", color);

    for(let item of textItems) {
        item.transition()
        .duration(250)
        .style("fill", "white");
    }


}
